import React from "react";
import "./KAMdashCard.scss";
// import CircularProgressBar from "./common/CircularProgressBar";

function KAMTileCard({ title, cardData, completed, total }) {
  const dotColor = (value) => {
    switch (value) {
      case "Pending":
        return "#8989e3";
      case "OverDue":
        return "#DC4E46";
      case "Escalated":
        return "#EB8D11";

      default:
        return "black";
    }
  };
  return (
    <div styleName="main-container" style={{ padding: "0" }}>
      <div styleName="title-container">
        <div styleName="title">{title}</div>
        {total && (
          <div>
            {completed || "-"} / {total}
          </div>
        )}
      </div>
      <div styleName="card-container">
        {cardData?.map((item, index) => {
          return (
            <div key={index} styleName="card-content">
              <div styleName="card-item">
                <div
                  styleName="card-dot"
                  style={{
                    backgroundColor: dotColor(item?.description),
                  }}
                />
                <div
                  styleName="card-total"
                  style={{
                    color: dotColor(item?.description),
                  }}>
                  {item.total}
                </div>
              </div>
              <span styleName="card-title">{item?.description} </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default KAMTileCard;
