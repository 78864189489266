import React, { Component } from "react";
import "./ToggleButton.scss";
export default class ToggleButton extends Component {
  handleChange = (event) => {
    event?.stopPropagation();
    if (this.props.onChange) {
      this.props.onChange(!this.props.selected);
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.props.showCheckBox ? (
          <label styleName="container">
            <input
              type="checkbox"
              checked={this.props.selected}
              onClick={(e) => this.handleChange(e)}
              // onChange={() => this.handleChange()}
              disabled={this.props.disabled}
            />
            <span
              styleName={
                this.props.disabled ? "checkmark-disabled" : "checkmark"
              }></span>
          </label>
        ) : (
          <label styleName={"switch"}>
            <input
              type="checkbox"
              checked={this.props.selected}
              onClick={() => this.handleChange()}
              disabled={this.props.disabled}
            />
            <span styleName={"slider"}></span>
          </label>
        )}
      </React.Fragment>
    );
  }
}
