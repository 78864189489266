import React from "react";
import { Consumer } from "./Provider";
import {
  computeHierarchy,
  getResourceParentTree,
  getResourceTree,
} from "../api/resource";
import { getResourceList } from "../api/dashboard";
import ResourceTree from "../components/ResourceTree";
import ErrorPage from "../components/societyCard/ErrorPage";
import Loader from "../components/common/Loader";
import Toast from "../components/common/Toasts/Toast";

export default class ResourceTreeContainer extends React.Component {
  render() {
    return (
      <Consumer>
        {(props) => (
          <WithState
            profile={props.profile}
            loading={props.loading}
            update={props.update}
            resourceTree={props.resourceTree}
            resourceHierarchy={props.resourceHierarchy}
            resourceHierarchyOption={props.resourceHierarchyOption}
            capabilities={props.capabilities}
            parentHierarchy={props?.parentHierarchy}
            disableSearch={this.props?.disableSearch}
            disableParentHierarchy={this.props?.disableParentHierarchy}
            showInProfile={this.props?.showInProfile}
          />
        )}
      </Consumer>
    );
  }
}

class WithState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      resourceSearchResults: null,
      resourceHierarchy: this.props.resourceHierarchy
        ? this.props.resourceHierarchy
        : "",
    };
    this.toastRef = React.createRef();
  }
  showError = (error) => {
    this.toastRef?.current?.addToast({
      type: "error",
      message: error ? error : "Something Went Wrong ",
    });
  };
  showToast = (toast) => {
    this.toastRef?.current?.addToast({
      type: toast.type,
      message: toast.message ? toast.message : "Something Went Wrong ",
    });
  };
  showLoader = () => {
    this.setState({ loading: true });
  };
  hideLoader = () => {
    this.setState({ loading: false });
  };
  getResourceTree = async (id) => {
    const result = await getResourceTree(id);
    if (result.status === 1) {
      this.props.update({ resourceTree: result.data });
    }
  };
  getResourceList = async () => {
    const result = await getResourceList(this.state.searchText);
    if (result.status === 1) {
      this.setState({ resourceSearchResults: result.data });
    }
  };
  filterTreeById = (val) => {
    this.setState({ searchText: val.label });
    this.getResourceTree(val.value);
  };

  searchResource = (val) =>
    this.setState({ searchText: val }, () => {
      if (this.state.searchText === "") {
        this.getResourceTree();
      } else if (this.state.searchText && this.state.searchText.length > 2)
        this.getResourceList();
    });
  handleHierarchy = async (val) => {
    await this.setState({ resourceHierarchy: val });
    this.getResourceTree();
  };
  getResourceParentTreeFn = async () => {
    const result = await getResourceParentTree(
      this.props?.profile?.resource?.id
    );
    if (result?.status === 1) {
      const parentHierarchyArray = result?.data || [];
      parentHierarchyArray.pop();
      this.props?.update({ parentHierarchy: parentHierarchyArray });
    }
  };
  handleComputeHierarchy = async () => {
    this.showLoader();
    const result = await computeHierarchy(this.props?.profile?.resource?.id);
    this.hideLoader();
    if (result.status === 1) {
      this.showToast({
        type: "success",
        message: result.message ? result.message : "Successfully Recomputed ",
      });
      this.getResourceTree(this.props?.profile?.resource?.id);
    } else {
      this.showError(result.message);
    }
  };
  componentDidMount() {
    if (this.props?.showInProfile) {
      this.getResourceTree(this.props?.profile?.resource?.id);
    } else {
      this.getResourceTree();
    }
    this.getResourceParentTreeFn();
  }
  render() {
    const accessPermission =
      (this.props.capabilities?.includes("resource_tree") &&
        this.props.capabilities?.includes("dashboard_access")) ||
      this.props.showInProfile;
    let { resourceSearchResults } = this.state;
    return !accessPermission ? (
      this.props.loading ? (
        <Loader />
      ) : (
        <ErrorPage />
      )
    ) : (
      <div>
        {this.state.loading && <Loader />}
        <ResourceTree
          searchResource={this.searchResource}
          filterTreeById={this.filterTreeById}
          resourceSearchResults={resourceSearchResults}
          searchText={this.state.searchText}
          resourceTree={this.props.resourceTree}
          resourceHierarchyOption={this.props.resourceHierarchyOption}
          handleHierarchy={this.handleHierarchy}
          resourceHierarchy={this.state.resourceHierarchy}
          getResourceTree={this.getResourceTree}
          disableSearch={this.props?.disableSearch}
          disableParentHierarchy={this.props?.disableParentHierarchy}
          parentHierarchy={this.props?.parentHierarchy}
          handleComputeHierarchy={this.handleComputeHierarchy}
        />
        <Toast ref={this.toastRef} />
      </div>
    );
  }
}
