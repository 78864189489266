import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, ModalPanel } from "./components/common/modal/ModalRoot";
import { Provider } from "./containers/Provider";
import loadable from "@loadable/component";
import PrivateRoutes from "./components/routes/PrivateRoutes";
const PublicRoutes = loadable(() => import("./components/routes/PublicRoutes"));
import { ToastProvider } from "./components/common/Toasts/ToastProvider";
import CallPanel from "./components/slashRtc/CallPanel";
import { Formio } from "@formio/react";
import FormioContrib from "@formio/contrib";

Formio.use(FormioContrib);
function App(props) {
  let loggedIn = localStorage.getItem("accessToken");

  const pathName = props.history.location.pathname;

  const privatePage =
    pathName.includes("/society-payout-approvals") ||
    pathName.includes("/social-tickets") ||
    pathName.includes("/site-validation");
  return (
    <Provider>
      <ToastProvider>
        <Modal>
          {loggedIn ? (
            <React.Fragment>
              {!privatePage && <CallPanel {...props} />}
              <PrivateRoutes {...props} />
            </React.Fragment>
          ) : (
            <PublicRoutes {...props} />
          )}
          <ModalPanel />
        </Modal>
      </ToastProvider>
    </Provider>
  );
}

export default withRouter(App);
