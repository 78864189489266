import React, { Component } from "react";
import loadable from "@loadable/component";
const DropDownBase = loadable(() => import("../core/DropDownBase"));
const SelectMultipleOption = loadable(() => import("./SelectMultipleOption"));
const Input = loadable(() => import("../Input"));
import Observer from "../Observer";
import "./DropDown.scss";
import ToggleButton from "../../societyCard/ToggleButton";

export default class DropDownWithMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.selected ? true : false,
      value: "",
    };
  }
  renderTopBoX = () => {
    if (this.props.sendEntireValue) {
      const arr = this.props.options
        ?.filter(
          (val) =>
            this.props.selected?.findIndex(
              (data) => data.value === val.value
            ) !== -1
        )
        ?.map((val) => val.label);
      if (arr.length === 0) {
        return "";
      }
      return arr.join(", ");
    } else {
      let arr = this.props.options
        ?.filter((val) => this.props.selected.includes(val.value))
        ?.map((val) => val.label);
      if (this.props.customUI) {
        const allValue = this.props.options?.map((val) => val.value);
        const selectedValue = this.props.selected;

        const valuesNotInOptions = selectedValue.filter(
          (item) => !allValue.includes(item)
        );
        arr = [...arr, ...valuesNotInOptions];
      }
      if (arr.length === 0) {
        return "";
      }
      return arr.join(", ");
    }
  };
  handleChange = (val) => {
    this.setState({ value: val });
    if (this.props.onSearch) {
      this.props.onSearch(val);
    }
  };
  handleToggle = (val, props) => {
    if (val) {
      props.handleSelect(this.props.options?.map((val) => val.value));
    } else {
      props.handleSelect([]);
    }
  };
  render() {
    return (
      <DropDownBase
        onSelect={this.props.onSelect}
        selected={this.props.selected}
        open={true}
        multiSelect={true}
        render={(props) => (
          <div styleName={"base"}>
            <div
              styleName={
                this.props.disabled
                  ? "disabled"
                  : this.props.error
                  ? "selectedError"
                  : "selected"
              }
              style={{
                height: this.props.height,
                border: this.props.border,
                borderRadius: this.props.borderRadius,
              }}
              onClick={this.props.disabled ? null : props.handleDropDown}
              id={this.props.id || this.props.placeholder}>
              {this.props.placeholder && (
                <span
                  styleName={
                    this.props.selected && this.props.selected?.length > 0
                      ? "placeholder-active"
                      : "placeHolder"
                  }
                  style={{
                    ...this.props.placeholderStyle,
                  }}
                  title={this.props.placeholder}>
                  {this.props.placeholder}
                </span>
              )}
              {this.props.selected && this.props.selected?.length > 0 && (
                <span styleName="selcted-text">{this.renderTopBoX()}</span>
              )}
              {props.dropDownVisible && (
                <div
                  styleName={
                    props.distanceToBottom < 200 && props.distanceToTop > 200
                      ? "dropDownTop"
                      : "dropDown"
                  }
                  style={{ top: this.props.height }}>
                  {this.props.showSearch && (
                    <div styleName={"input-holder"}>
                      <Input
                        placeholder={"Search"}
                        value={
                          this.props.disabled
                            ? this.props.value
                            : this.state.value
                        }
                        disabled={this.props.disabled}
                        autoFocus={true}
                        // error={this.props.error}
                        onChange={(val) => this.handleChange(val)}
                        id={this.props.id + "input"}
                      />
                    </div>
                  )}
                  {this.props.showSelectAll && (
                    <div styleName="toggle-container">
                      <ToggleButton
                        showCheckBox={true}
                        selected={
                          this.props.selected?.length ===
                          this.props.options?.length
                        }
                        onChange={(val) => this.handleToggle(val, props)}
                      />
                      <span styleName="toggle-label">Select All</span>
                    </div>
                  )}
                  <div
                    styleName={"multiOption"}
                    style={
                      this.props.customUI
                        ? { maxHeight: "calc(200px - 60px)", overflow: "auto" }
                        : {}
                    }>
                    <SelectMultipleOption
                      onSelect={(val) => {
                        props.handleSelect(val);
                      }}
                      selected={this.props.selected}
                      options={this.props.options?.filter(
                        (val) => !this.props.hideValue?.includes(val.value)
                      )}
                      showDetails={this.props.showDetails}
                      filter={this.props.filter}
                      searchValue={this.state.value}
                      sendEntireValue={this.props.sendEntireValue}
                      disableUnSelect={this.props.disableUnSelect}
                    />
                  </div>
                  {this.props.customUI && <div>{this.props.customUI}</div>}
                  {this.props.observer && (
                    <Observer inView={this.props.paginateOptions}>
                      <div>Loading...</div>
                    </Observer>
                  )}
                </div>
              )}
            </div>
            <div
              styleName={"downArrow "}
              onClick={this.props.disabled ? null : props.handleDropDown}>
              {this.props.showIcon && (
                <div
                  styleName={
                    this.props.disabled
                      ? "down-arrow-disable"
                      : this.props.selected &&
                        this.props.selected?.length > 0 &&
                        this.props.onClear
                      ? "cancel-icon"
                      : "downarrow"
                  }
                  onClick={
                    this.props.disabled
                      ? null
                      : this.props.selected && this.props.onClear
                      ? (e) => {
                          e.stopPropagation();
                          this.props.onClear();
                        }
                      : props.handleDropDown
                  }></div>
              )}
            </div>
            {this.props.error && (
              <div styleName={"error-message"}>{this.props.error}</div>
            )}
          </div>
        )}
      />
    );
  }
}
DropDownWithMultiSelect.defaultProps = {
  showIcon: true,
  hideValue: [],
};
