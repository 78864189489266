import React, { useState } from "react";
import "../IntegrationEditor.scss";
import { FormElement, Form } from "../common/core/Form";
import Input from "../common/Input";
import Button from "../common/Button";
import SingleSelectCheckBox from "../common/SingleSelectCheckBox";
import { objectToArray } from "../../utils/utils";

const CometChatWhatsApp = ({
  history,
  activeFLage,
  integrationData,
  updateIntegrationApi,
}) => {
  const initialValues = {
    ...integrationData,
    active: activeFLage,
  };
  const [showConfig, setShowConfig] = useState(true);
  const updatedActiveSupportTypea = (props, flag, val) => {
    const tempArray = props.activeSupportedTypes;
    const filteredArray =
      flag === "true"
        ? tempArray.push(val)
        : tempArray?.filter((selVal) => selVal !== val);
    props.updateForm({
      activeSupportedTypes: flag === "true" ? tempArray : filteredArray,
    });
  };
  const updatedActionSupportTypes = (props, flag, val) => {
    const tempObj = props.action;
    props.updateForm({
      action: { ...tempObj, [val?.value]: flag ? flag : false },
    });
  };

  const updateEndPointsConfig = (props, key, val) => {
    const tempObj = { ...props.endPoints };
    tempObj[key] = val;
    props.updateForm({ endPoints: tempObj });
  };
  const updateCredentials = (props, key, val) => {
    const tempObj = { ...props.credentials };
    tempObj[key] = val;
    props.updateForm({ credentials: tempObj });
  };

  const updateWidgetIds = (props, val) => {
    props.updateForm({ widgetIds: val?.split(",") });
  };

  const handleSubmit = (val) => {
    updateIntegrationApi(val);
  };
  return (
    <React.Fragment>
      <Form
        onSubmit={(val) => {
          handleSubmit(val);
        }}
        initialValues={initialValues}
        canUpdateInitialValues={true}>
        <FormElement>
          {(props) => (
            <div styleName="int-base">
              <div
                styleName="info-header"
                onClick={() => {
                  setShowConfig(!showConfig);
                }}>
                Configuration
                <div styleName={showConfig ? "arrow-up" : "down-arrow"} />
              </div>
              <div styleName="flex-col">
                {showConfig && (
                  <div styleName="config">
                    <div styleName="accordian-div">
                      <div styleName="accordian-text">Basic</div>
                      <div styleName="inpt-holder">
                        <div styleName="col">
                          <Input
                            placeholder="Create group URL"
                            value={props.baseUrl}
                            onChange={(val) =>
                              props.updateForm({ baseUrl: val })
                            }
                            border="1px solid #e3e3e3d9"
                          />
                        </div>
                        <div styleName="col">
                          <Input
                            placeholder="Website URL"
                            value={props.websiteUrl}
                            onChange={(val) =>
                              props.updateForm({ websiteUrl: val })
                            }
                            border="1px solid #e3e3e3d9"
                          />
                        </div>
                        <div styleName="col">
                          <Input
                            placeholder="Image URL"
                            value={props.image}
                            onChange={(val) => props.updateForm({ image: val })}
                            border="1px solid #e3e3e3d9"
                          />
                        </div>
                      </div>
                    </div>
                    {props.credentials &&
                      Object.keys(props.credentials)?.length > 0 && (
                        <div styleName="accordian-div">
                          <div styleName="accordian-text">Credentials</div>
                          <div styleName="inpt-holder">
                            {Object.keys(props.credentials)?.map((key, i) => {
                              return (
                                <div styleName="col" key={i}>
                                  <Input
                                    placeholder={key}
                                    value={props.credentials[key]}
                                    onChange={(val) =>
                                      updateCredentials(props, key, val)
                                    }
                                    border="1px solid #e3e3e3d9"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {props.widgetIds && (
                      <div styleName="accordian-div">
                        <div styleName="accordian-text">WidgetIds</div>
                        <div styleName="inpt-holder">
                          <div styleName="col">
                            <Input
                              type="text"
                              placeholder={"widgetIds"}
                              value={props.widgetIds?.join(",")}
                              onChange={(val) => updateWidgetIds(props, val)}
                              border="1px solid #e3e3e3d9"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {props.endPoints &&
                      Object.keys(props.endPoints)?.length > 0 && (
                        <div styleName="accordian-div">
                          <div styleName="accordian-text">End Points</div>
                          <div styleName="inpt-holder">
                            {Object.keys(props.endPoints)?.map((key, i) => {
                              return (
                                <div styleName="col" key={i}>
                                  <Input
                                    placeholder={key}
                                    value={props.endPoints[key]}
                                    onChange={(val) =>
                                      updateEndPointsConfig(props, key, val)
                                    }
                                    border="1px solid #e3e3e3d9"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {props?.action && (
                      <div styleName="accordian-div">
                        <div styleName="accordian-text">Apis Status</div>
                        <div styleName="other">
                          <div styleName="flex-wrap">
                            {objectToArray(props.action)?.map((val, _i) => (
                              <div styleName="ele" key={_i}>
                                <SingleSelectCheckBox
                                  options={[
                                    { label: val?.value, value: "true" },
                                  ]}
                                  selected={val?.label ? "true" : false}
                                  onSelect={(flag) => {
                                    updatedActionSupportTypes(props, flag, val);
                                  }}
                                  fontSize="14px"
                                  tabIndex="0"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {props.supportedTypes?.length > 0 && (
                      <div styleName="accordian-div">
                        <div styleName="accordian-text">Supported Types</div>
                        <div styleName="other">
                          <div styleName="flex-wrap">
                            {props.supportedTypes?.map((val, _i) => (
                              <div styleName="ele" key={_i}>
                                <SingleSelectCheckBox
                                  options={[{ label: val, value: "true" }]}
                                  selected={
                                    props.activeSupportedTypes?.includes(val)
                                      ? "true"
                                      : false
                                  }
                                  onSelect={(flag) => {
                                    updatedActiveSupportTypea(props, flag, val);
                                  }}
                                  fontSize="14px"
                                  tabIndex="0"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div styleName="footer-button">
                <Button
                  type="secondary"
                  label="Go Back"
                  onClick={() => history.goBack()}
                />
                <Button
                  margin="0 10px 0 10px"
                  label="Save"
                  onClick={() => props.onSubmit()}
                />
              </div>
            </div>
          )}
        </FormElement>
      </Form>
    </React.Fragment>
  );
};

export default CometChatWhatsApp;
