import React from "react";
import "./MeetingPanel.scss";
import MeetingCard from "./MeetingCard";
import { MEETING_CARD_COLOR } from "../utils/constant";

function MeetingPanel({ meetingData }) {
  return (
    <>
      <div styleName="title-container">
        <div styleName="title">Meetings</div>
      </div>
      {meetingData?.map((item, index) => {
        return (
          <div styleName="meeting-container" key={index}>
            <div styleName="meeting-title">{item?.sectionTitle}</div>
            {item?.content?.length > 0 ? (
              <div styleName="meeting-card-container">
                {item?.content?.map((meetingDetails, index) => {
                  return (
                    <MeetingCard
                      key={index}
                      meetingDetails={meetingDetails}
                      bgColor={MEETING_CARD_COLOR[index]}
                    />
                  );
                })}
              </div>
            ) : (
              <div styleName="no-meeting">No meeting available.</div>
            )}
          </div>
        );
      })}
    </>
  );
}

export default MeetingPanel;
