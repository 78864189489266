import React from "react";
import "./MeetingCard.scss";
import { momentFormat } from "../utils/utils";
function MeetingCard({ meetingDetails, bgColor }) {
  return (
    <div
      styleName="card-container"
      style={{ borderTop: `6px solid ${bgColor}` }}>
      <div styleName="card-description">
        <div>{meetingDetails?.title}</div>
        {meetingDetails?.googleMeet && <div styleName="video-icon" />}
        {!meetingDetails?.googleMeet && <div styleName="meeting-icon" />}
      </div>

      <div styleName="card-time">
        <div styleName="timer-icon" />
        <div>
          {momentFormat(meetingDetails?.startDate, "hh:mm a")} -{" "}
          {momentFormat(meetingDetails?.endDate, "hh:mm a")}
        </div>
      </div>
    </div>
  );
}

export default MeetingCard;
