import React, { useState } from "react";
import NotificationCard from "./NotificationCard";
// import DropDownBase from "./common/core/DropDownBase";
import Observer from "./common/Observer";
import "./Notifications.scss";
import NotificationFilter from "./NotificationFilter";
// import Button from "./common/Button";
import { clearNotification } from "../api/notifications";
import { NA } from "../utils/constant";
// import moment from "moment";

function Notifications({
  count,
  notifications,
  page,
  lazyLoad,
  read,
  readNotifications,
  loading,
  filterNotifications,
  closeModal,
  showToast,
  universalLabels,
}) {
  // const [tab, setTab] = useState("");
  const handleLazyLoad = () => {
    if (lazyLoad) {
      lazyLoad();
    }
  };
  const clearNotificationCall = async () => {
    const payload = { messageType: "push" };
    const result = await clearNotification(payload);
    if (result.status === 1) {
      showToast({
        type: "success",
        message: "Notifications getting cleared",
      });
    } else {
      showToast({
        type: "error",
        message: result.errorDescription
          ? result.errorDescription
          : "Something Went Wrong ",
      });
    }
    closeModal();
  };
  const [tab, setTab] = useState([]);
  const [sort, setSort] = useState(false);
  const labels = universalLabels || {};
  // const tagOption = [
  //   { label: "All", value: "" },
  //   { label: "Tasks", value: "TASKS" },
  //   { label: "Meetings", value: "MEETINGS" },
  //   { label: "Jobs", value: "JOBS" },
  //   { label: "Leads", value: "LEADS" },
  //   { label: "Tickets", value: "TICKETS" },
  //   { label: "Approvals", value: "APPROVALS" },
  // ];
  return (
    <div styleName="base">
      {loading && (
        <div styleName="loader-container">
          <div styleName="loader" />
        </div>
      )}
      <div styleName="header-container">
        <div styleName="header-text">
          {"Notifications"}
          <span styleName="count">{count} new</span>
        </div>
        <div styleName="notif-parent">
          {(labels?.CLEAR_NOTIFICATIONS || "CLEAR_NOTIFICATIONS") !== NA && (
            <div>
              <div
                styleName="clear-notif"
                onClick={() => clearNotificationCall()}>
                Clear All
              </div>
            </div>
          )}
          <NotificationFilter
            getNotifications={(tab, sort) => {
              filterNotifications(tab?.toString(), sort);
              setTab(tab);
              setSort(sort);
            }}
            tabValue={tab}
            sortValue={sort}
          />
        </div>
      </div>
      <div styleName={"content"}>
        {/* <div styleName="tag-container">
          {tagOption?.map((val, i) => (
            <div
              styleName={tab === val.value ? "tag-active" : "tag"}
              key={i}
              onClick={() => setTab(val.value)}>
              {val.label}
            </div>
          ))}
        </div> */}
        {notifications &&
          notifications?.map((val, i) => (
            <React.Fragment key={i}>
              <NotificationCard
                data={val}
                onRead={read}
                readNotifications={readNotifications}
              />
            </React.Fragment>
          ))}
        {notifications &&
          notifications.length > 0 &&
          notifications.length > page * 10 - 1 &&
          notifications.length % 10 === 0 && (
            <Observer inView={handleLazyLoad}>
              <div style={{ height: 20 }}> </div>
            </Observer>
          )}
      </div>
    </div>
  );
}

export default Notifications;
