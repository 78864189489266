import React, { useEffect } from "react";
import { Consumer } from "./Provider";
import "../components/HomePage.scss";
import KAMdashboard from "../components/KAMdashboard";
import {
  kamDashboardDetailApi,
  kamDashboardMeetingApi,
} from "../api/dashboard";
import MyWorkSkeleton from "../components/common/skeleton/MyWorkSkeleton";

export default function KAMdashboardContainer() {
  return (
    <Consumer>
      {(props) => {
        return (
          <KAMdashboardContainerWithState
            profile={props?.profile}
            update={props.update}
            agentDetails={props.agentDetails}
            agentMeetingDetails={props.agentMeetingDetails}
            skeletonLoader={props?.skeletonLoader}
          />
        );
      }}
    </Consumer>
  );
}

function KAMdashboardContainerWithState({
  profile,
  update,
  agentDetails,
  agentMeetingDetails,
  skeletonLoader = true,
}) {
  const kamDashboardDetailFn = async (id) => {
    const result = await kamDashboardDetailApi(id);
    if (result?.status === 1) {
      hideSkeletonLoader();
      update({ agentDetails: result?.data?.card });
    }
  };
  const kamDashboardMeetingDetailFn = async (id) => {
    const result = await kamDashboardMeetingApi(id);
    if (result?.status === 1) {
      update({ agentMeetingDetails: result?.data?.meetingCard });
      hideSkeletonLoader();
    }
  };
  const showSkeletonLoader = async () => {
    await update({ skeletonLoader: true });
  };
  const hideSkeletonLoader = async () => {
    await update({ skeletonLoader: false });
  };
  useEffect(() => {
    if (profile?.resource?.id) {
      showSkeletonLoader();
      kamDashboardDetailFn(profile?.resource?.id);
      kamDashboardMeetingDetailFn(profile?.resource?.id);
    }
  }, [profile]);
  return skeletonLoader ? (
    <MyWorkSkeleton />
  ) : agentDetails ? (
    <KAMdashboard
      userName={profile?.resource?.partyName}
      agentDetails={agentDetails}
      agentMeetingDetails={agentMeetingDetails}
    />
  ) : (
    <React.Fragment />
  );
}
