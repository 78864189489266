import React from "react";
import "./KAMdashCard.scss";
import { BAR_COLOR } from "../utils/constant";

function KAMBarCard({ title, barData }) {
  const totalValue = barData?.reduce((acc, curr) => {
    return curr.total + acc;
  }, 0);
  const percent = (data) => {
    const c = data?.total,
      t = totalValue || 1;
    return Math.round((+c * 100) / +t);
  };
  return (
    <div styleName="main-container">
      <div styleName="title">{title}</div>
      <div styleName="bar-container">
        {barData?.map((item, index) => {
          return (
            <div key={index}>
              <div styleName="bar-details">
                <span>{item?.description}</span>
                <span>{item?.total}</span>
              </div>
              <div styleName="progress-container">
                <div
                  styleName="progress-bar"
                  style={{
                    width: `${percent(item)}%`,
                    background: BAR_COLOR[index],
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default KAMBarCard;
