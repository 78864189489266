import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./Loader.scss";
const loaderRoot = document.getElementById("loader-root");

export default class Loader extends Component {
  render() {
    return ReactDOM.createPortal(
      <div styleName={"loader"}>
        <div styleName={"base"}></div>
      </div>,
      loaderRoot
    );
  }
}
