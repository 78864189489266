import React from "react";
import loadable from "@loadable/component";
const DropDownBase = loadable(() => import("../common/core/DropDownBase"));
const Input = loadable(() => import("./Input"));
const ToggleButton = loadable(() => import("../societyCard/ToggleButton"));
import Observer from "./Observer";
import "./DropDown.scss";
import DropDown from "./DropDown";
export default class DropDownWithSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.selected ? true : false,
      value: "",
      toggle: props.toggle || false,
    };
  }
  getInitials = (name) => {
    const array = name.split(" ").slice(0, 3);
    return array
      ?.map((val) => val.split("")[0])
      ?.toString()
      ?.split(",")
      ?.join("");
  };
  handleChange = (val) => {
    this.setState({ value: val });
    if (this.props.onSearch) {
      this.props.onSearch(val);
    }
  };
  handleDropDownSelect = (val) => {
    this.setState({ value: "" });
    if (this.props.onSelect) {
      this.props.onSelect(val);
    }
  };
  handleToggle = (val) => {
    this.setState({ toggle: val });
    this.props.handleToggle(val);
  };
  onKeyUp = (key, props) => {
    if (key === "Enter") {
      this.props.disabled ? null : props.handleDropDown();
    }
  };
  optionNumberRenderer = () => {
    let number = this.props?.options?.length || 0;

    if (this.props?.options?.length > 0 && this.props?.options?.length !== 1) {
      if (this.props.selected) {
        this.props?.options?.filter((option) => {
          if (option.value === this.props.selected) {
            number = this.props?.options?.length - 1;
          }
        });
      } else {
        number = this.props?.options?.length;
      }
    } else {
      number = this.props?.options?.length;
    }

    return number;
  };
  render() {
    let selectedKeys = this.props.single
      ? this.props.hideValue
        ? [...this.props.hideValue, this.props.selected]
        : [this.props.selected]
      : this.props.selected &&
        this.props.selected.length > 0 &&
        this.props.selected?.map((data) => {
          return data.value;
        });
    let selectedValue = this.props.single
      ? this.props?.options?.find((sel) => {
          return sel.value == this.props.selected;
        })
      : "";
    // const tagcolor = {
    //   sales: "#ffa000",
    //   agents: "#ff435e",
    //   operations: "#1976d2",
    // };
    return (
      <DropDownBase
        onSelect={this.handleDropDownSelect}
        selected={this.props.selected}
        handleBlur={() => this.props.onBlur && this.props.onBlur()}
        handleFocuse={(val) => this.setState({ active: val })}
        isDropDownVisible={this.props.isDropDownVisible}
        renderStyles={this.props.renderStyles}
        open={this.props.open}
        render={(props) => (
          <div styleName={"base"}>
            <div
              styleName={
                this.props.disabled
                  ? "disabled"
                  : this.props.error
                  ? "selected-error"
                  : "selected"
              }
              style={{
                height: this.props.height,
                border: this.props.border,
                borderRadius: this.props.borderRadius,
                background: this.props.background,
                width: this.props?.width,
                fontSize: this.props?.fontSize,
                ...this.props.boxStyles,
              }}
              onClick={this.props.disabled ? null : props.handleDropDown}
              id={this.props.id || this.props.placeholder}
              tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
              onKeyUp={(event) => this.onKeyUp(event.key, props)}>
              {this.props.placeholder && (
                <div
                  styleName={
                    this.state.active ||
                    this.props.selected ||
                    this.props.disabled
                      ? "placeholder-active"
                      : "placeholder"
                  }
                  style={{
                    display: this.props.hidePlaceholder ? "none" : "",
                  }}>
                  <div styleName={"placeholder-text"}>
                    {this.props.placeholder}
                    {this.props.required && (
                      <span styleName="required"> *</span>
                    )}
                  </div>
                </div>
              )}

              {this.props.single ? (
                <div styleName="ellipse">
                  {selectedValue && selectedValue.label}
                </div>
              ) : (
                this.props.selected &&
                this.props.selected?.map((val, i) => {
                  return (
                    <span key={i} style={{ ...this.props.headerStyle }}>
                      {i > 0 ? "," : ""} {val.label}
                    </span>
                  );
                })
              )}
            </div>
            {props.dropDownVisible && (
              <div
                styleName={
                  props.distanceToBottom < 200 && props.distanceToTop > 200
                    ? "dropDown-top"
                    : this.props.onSearch
                    ? "dropdown-with-search"
                    : "dropDown"
                }
                style={{
                  top: this.props.height,
                  width: this.props.listWidth,
                  maxHeight: this.props.showCheckbox ? "300px" : "",
                  overflow: this.props.showSearch ? "hidden" : "auto",
                  zIndex: this.props.zIndex,
                }}>
                {this.props.showSearch && (
                  <div styleName={"input-holder"}>
                    <Input
                      placeholder={"Search"}
                      value={
                        this.props.disabled
                          ? this.props.value
                          : this.state.value
                      }
                      disabled={this.props.disabled}
                      autoFocus={true}
                      onFocus={this.props.onFocus}
                      // error={this.props.error}
                      onChange={(val) => this.handleChange(val)}
                      id={this.props.placeholder + "input"}
                    />
                  </div>
                )}
                {this.props.showDependentSearch && (
                  <div styleName={"input-holder gap10"}>
                    <div styleName="input30">
                      <DropDown
                        options={this.props.dependentOption || []}
                        placeholder="Search By"
                        onSelect={this.props.onDependentSelect}
                        selected={this.props.dependentValue}
                      />
                    </div>
                    <div styleName="input70">
                      <Input
                        placeholder={"Search"}
                        value={
                          this.props.disabled
                            ? this.props.value
                            : this.state.value
                        }
                        disabled={this.props.disabled}
                        autoFocus={true}
                        onFocus={this.props.onFocus}
                        // error={this.props.error}
                        onChange={(val) => this.handleChange(val)}
                        id={this.props.placeholder + "input"}
                      />
                    </div>
                  </div>
                )}
                {this.props.showCheckbox && (
                  <div
                    styleName="toggle-container"
                    style={{
                      justifyContent: "space-between",
                    }}>
                    <p
                      style={{
                        fontSize: "10px",
                        fontWeight: 500,
                      }}>
                      Showing {this.optionNumberRenderer()}
                      {!this.state.toggle
                        ? " team members"
                        : " of all the resources"}
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ToggleButton
                        selected={this.state.toggle}
                        onChange={this.handleToggle}
                        showCheckBox={!this.props.showSlider}
                      />
                      <span styleName="toggle-label">Show all</span>
                    </div>
                  </div>
                )}
                <div styleName="dropdown-overflow">
                  {this.props.filter ? (
                    this.props?.options?.map((val, i) => {
                      if (this.props.hideValue) {
                        if (
                          !this.props.hideValue?.includes(val.value) &&
                          (val.label
                            ?.toLowerCase()
                            ?.includes(this.state.value.toLowerCase()) ||
                            val.labelValue
                              ?.toLowerCase()
                              ?.includes(this.state.value.toLowerCase()))
                        )
                          return (
                            <div
                              key={i}
                              styleName={"option"}
                              onClick={() => {
                                props.handleSelect(val);
                              }}
                              tabIndex={
                                this.props.tabIndex ? this.props.tabIndex : "0"
                              }
                              onKeyUp={(event) =>
                                event.key === "Enter" && props.handleSelect(val)
                              }>
                              {val.displayLabel ? val.displayLabel : val.label}
                            </div>
                          );
                      } else {
                        if (
                          val.label
                            ?.toLowerCase()
                            ?.includes(this.state.value.toLowerCase()) ||
                          val.labelValue
                            ?.toLowerCase()
                            ?.includes(this.state.value.toLowerCase())
                        ) {
                          return (
                            <div
                              key={i}
                              styleName={"option"}
                              onClick={() => {
                                props.handleSelect(val);
                              }}
                              tabIndex={
                                this.props.tabIndex ? this.props.tabIndex : "0"
                              }
                              onKeyUp={(event) =>
                                event.key === "Enter" && props.handleSelect(val)
                              }>
                              {val.displayLabel ? val.displayLabel : val.label}
                            </div>
                          );
                        }
                      }
                    })
                  ) : this.props?.options?.length > 0 ? (
                    this.props?.options?.map((val, i) => {
                      if (this.props.selected) {
                        if (!selectedKeys?.includes(val.value))
                          return (
                            <div
                              key={i}
                              styleName={"option-two"}
                              onClick={() => {
                                props.handleSelect(val);
                              }}
                              tabIndex={
                                this.props.tabIndex ? this.props.tabIndex : "0"
                              }
                              onKeyUp={(event) =>
                                event.key === "Enter" && props.handleSelect(val)
                              }>
                              {val.displayLabel ? val.displayLabel : val.label}
                            </div>
                          );
                      } else {
                        if (!this.props.hideValue?.includes(val.value))
                          return (
                            <div
                              key={i}
                              styleName={"option"}
                              onClick={() => {
                                props.handleSelect(val);
                              }}
                              tabIndex={
                                this.props.tabIndex ? this.props.tabIndex : "0"
                              }
                              onKeyUp={(event) =>
                                event.key === "Enter" && props.handleSelect(val)
                              }>
                              {val.displayLabel ? val.displayLabel : val.label}
                            </div>
                          );
                      }
                    })
                  ) : (
                    <React.Fragment>
                      {this.props.displayNotFound ? (
                        <div
                          onClick={() => {
                            this.setState({ active: false }, () =>
                              props.handleDropDown()
                            );
                          }}>
                          {this.props.displayNotFound}
                        </div>
                      ) : (
                        <div styleName="options-not-found">
                          No options Found
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {this.props.observer && (
                    <Observer inView={this.props.paginateOptions}>
                      <div styleName="loading-container">Loading...</div>
                    </Observer>
                  )}
                  {this.props.loading && (
                    <div styleName="loading">loading...</div>
                  )}
                  {this.props.footerButton && (
                    <div styleName={"dropdown-footer-btn"}>
                      {this.props?.footerButton}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              styleName={
                this.props.disabled
                  ? "down-arrow-disable"
                  : this.props.selected && this.props.onClear
                  ? "cancel-icon"
                  : "downarrow"
              }
              onClick={
                this.props.disabled
                  ? null
                  : this.props.selected && this.props.onClear
                  ? () => {
                      this.setState({ value: "" }, this.props.onClear());
                    }
                  : props.handleDropDown
              }>
              {/* {this.props.showIcon && <img src={down} alt="arrow"></img>} */}
            </div>
            {this.props.error && !this.props.onSearch && (
              <div styleName={"error"}>{this.props.error}</div>
            )}
            {this.props.error && this.props.errorMessages && (
              <div styleName="error">{this.props.errorMessages}</div>
            )}
          </div>
        )}
      />
    );
  }
}
DropDownWithSearch.defaultProps = {
  showIcon: true,
};
