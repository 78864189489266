import React, { useEffect, useState } from "react";
import {
  deleteAgentDetailsApi,
  getAgentDetailsApi,
  updateAgentDetailsApi,
  addAgentDetailsApi,
  uploadBulkAgentDetailsApi,
} from "../../api/integrations";
import "../IntegrationEditor.scss";
import Button from "../common/Button";
import FileUpload from "../common/FileUpload";
import Loader from "../common/Loader";

function AgentDetailTable({ providerId, columnName, showToast }) {
  const [agentDetails, setAgentDetails] = useState([]);
  const [agentData, setAgentData] = useState({});
  const [editMode, setEditMode] = useState();
  const [addAgentDetails, setAddAgentDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const agentDetailsFn = async (id) => {
    setLoading(true);
    const result = await getAgentDetailsApi(id);
    setLoading(false);
    if (result.status === 1) {
      setAgentDetails(result.data);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgentData({ ...agentData, [name]: value });
  };

  const handleUpdate = async (id, resourceEmail, providerId) => {
    const body = {
      resourceEmail,
      id,
      providerId,
      ...agentData,
    };
    setLoading(true);
    const result = await updateAgentDetailsApi(body);
    setLoading(false);
    if (result.status === 1) {
      agentDetailsFn(providerId);
      setEditMode();
      showToast({
        type: "success",
        message: result.message ? result.message : "Updated Successfully",
      });
    } else {
      showToast({
        type: "error",
        message: result.data ? result.data : "Update Failed",
      });
    }
  };

  const handleAddAgent = async () => {
    const body = { ...agentData, providerId };
    setLoading(true);
    const result = await addAgentDetailsApi(body);
    setLoading(false);
    if (result.status === 1) {
      agentDetailsFn(providerId);
      setAddAgentDetails(false);
      showToast({
        type: "success",
        message: "Added Successfully",
      });
    } else {
      showToast({
        type: "error",
        message: result.message ? result.message : "Failed to Add",
      });
    }
  };
  const handleFileUpload = async (val) => {
    const body = { file: val[0] };
    setLoading(true);
    const result = await uploadBulkAgentDetailsApi(body, providerId);
    setLoading(false);
    if (result.status === 1) {
      showToast({
        type: "success",
        message: `Uploaded Successfully, Upserted ${result.data.upserted} and Skipped ${result.data.skipped}`,
      });
      agentDetailsFn(providerId);
    } else {
      showToast({
        type: "error",
        message: "Failed Upload",
      });
    }
  };

  useEffect(() => {
    agentDetailsFn(providerId);
  }, [providerId]);

  return (
    <div styleName="table-content">
      {loading && <Loader />}
      <div styleName="add-agent-button">
        <Button
          type="secondary"
          label="Add Agent"
          onClick={() => setAddAgentDetails(true)}
        />

        <div
          style={{
            width: "30%",
            height: "30px",
          }}>
          <FileUpload
            onlyExcel={true}
            onlyButton={true}
            label="Bulk Upload"
            onChange={(val) => handleFileUpload(val)}
          />
        </div>
      </div>
      <div
        onClick={(e) => {
          if (e.target.title === "Delete") {
            const newAgentDetails = agentDetails.filter((item) => {
              return item.id !== e.target.id;
            });
            setAgentDetails(newAgentDetails);
            deleteAgentDetailsApi(e.target.id);
          } else if (e.target.title === "Edit") {
            setAgentData({});
            setEditMode(e.target.id);
          }
        }}>
        <table styleName="table-container">
          <thead>
            <tr>
              {columnName?.map((item) => {
                return <th key={item}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {addAgentDetails && (
              <tr>
                <td>
                  <input
                    styleName="input-border"
                    name="username"
                    value={agentData?.username}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <input
                    styleName="input-border"
                    name="resourceEmail"
                    value={agentData?.resourceEmail}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <input
                    styleName="input-border"
                    name="token"
                    value={agentData?.token}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <div styleName="actions">
                    <div
                      styleName="save-icon"
                      onClick={handleAddAgent}
                      title="Save"
                    />
                    <div
                      styleName="cancel-icon"
                      onClick={() => setAddAgentDetails(false)}
                      title="Cancel"
                    />
                  </div>
                </td>
              </tr>
            )}
            {agentDetails?.map((item) => {
              return (
                <tr key={item.id}>
                  {editMode === item.id ? (
                    <>
                      <td>
                        <input
                          styleName="input-border"
                          name="username"
                          value={agentData?.username || item?.username}
                          onChange={(e) => handleChange(e)}
                        />
                      </td>
                      <td>
                        <input
                          styleName="input-border"
                          name="resourceEmail"
                          value={
                            agentData?.resourceEmail || item?.resourceEmail
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </td>
                      <td>
                        <input
                          styleName="input-border"
                          name="token"
                          value={agentData?.token || item?.token}
                          onChange={(e) => handleChange(e)}
                        />
                      </td>
                      <td>
                        <div styleName="actions">
                          <div
                            styleName="save-icon"
                            onClick={() =>
                              handleUpdate(
                                item?.id,
                                item?.resourceEmail,
                                item?.providerId
                              )
                            }
                            title="Save"
                          />
                          <div
                            styleName="cancel-icon"
                            onClick={() => setEditMode()}
                            title="Cancel"
                          />
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{item?.username}</td>
                      <td>{item?.resourceEmail}</td>
                      <td>{item?.token}</td>
                      <td>
                        <div styleName="actions">
                          <div
                            styleName="edit-icon"
                            id={item.id}
                            title="Edit"
                          />
                          <div
                            styleName="delete-icon"
                            id={item.id}
                            title="Delete"
                          />
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AgentDetailTable;
