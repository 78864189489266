import * as api from "./api";

export async function getNotificationCount() {
  const result = await api.get(`notifications/app/count?messageType=push`);
  return result;
}

export async function getNotifications(page, eventType, read) {
  const result = await api.get(
    `notifications/app?messageType=push&pageNum=${page}&pageSize=10&eventType=${eventType}&read=${read}`
  );
  return result;
}

export async function readNotification(data) {
  const result = await api.post(
    `notifications/app/${data.id}?messageType=push`
  );
  return result;
}

export async function clearNotification(data) {
  const result = await api.mediaPost("notifications/clearNotifications", data);
  return result;
}
