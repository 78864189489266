import React, { useEffect } from "react";
import { Consumer } from "./Provider";
import KAMdashboardContainer from "./KAMdashboardContainer";

function MyDashboardContainer(props) {
  return (
    <Consumer>
      {(consumerProps) => {
        return (
          <MyDashboardContainerWithState
            universalLabels={consumerProps.universalLabels || {}}
            history={props?.history}
          />
        );
      }}
    </Consumer>
  );
}

function MyDashboardContainerWithState({ universalLabels, history }) {
  const isSocial =
    universalLabels?.SOCIAL_TICKETS && universalLabels?.SOCIAL_TICKETS !== "NA"
      ? true
      : false;

  useEffect(() => {
    if (isSocial) {
      history.push("/social-dashboard");
    }
  }, [isSocial]);

  return <KAMdashboardContainer />;
}

export default MyDashboardContainer;
