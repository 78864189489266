import React from "react";
import UserProfile from "../components/UserProfile";
import {
  endBreakApi,
  getStatus,
  getUserProfile,
  postStatus,
} from "../api/profile";
import { Consumer } from "./Provider";
import { ModalControl } from "../components/common/modal/ModalRoot";
import ProfileDetailModal from "../components/ProfileModal/ProfileDetailModal";
import ModalPanel from "../components/common/modal/ModalPanel";
import {
  checkBusinessValue,
  currentTimeEpoch,
  momentFormat,
} from "../utils/utils";
import PunchInPopUp from "../components/PunchInPopUp";
import UpdateStatus from "../components/UpdateStatus/UpdateStatus";
import ResourceTreeContainer from "./ResourceTreeContainer";
import Loader from "../components/common/Loader";
import { handleChromeRuntimeFn } from "../utils/useScript";

export default class ProfileContainer extends React.Component {
  render() {
    return (
      <Consumer>
        {(props) => (
          <ModalControl>
            {(control) => (
              <WithState
                profile={props.profile}
                update={props.update}
                userName={props.userName}
                history={this.props.history}
                openModal={control.openModal}
                closeModal={control.closeModal}
                bussinessUnitSettings={props.bussinessUnitSettings}
                handlePunchInOut={this.props.handlePunchInOut}
                showToast={this.props.showToast}
                attendancePopUp={props.attendancePopUp}
                resourceTimelineId={props.resourceTimelineId}
                currentStatus={props.currentStatus}
                label={this.props.label}
                textColor={this.props.textColor}
                workLogModal={this.props.workLogModal}
                workLogAutoOpen={this.props.workLogAutoOpen}
              />
            )}
          </ModalControl>
        )}
      </Consumer>
    );
  }
}

class WithState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }
  showLoader = () => {
    this.setState({ loader: true });
  };
  hideLoader = () => {
    this.setState({ loader: false });
  };
  getUserProfile = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const hasLeadId = searchParams.has("leadId");
    this.props.update({ loading: true });
    const result = await getUserProfile();
    if (result.status === 1 && result.data) {
      handleChromeRuntimeFn(result?.data?.resource?.id, result?.data?.tenant);
    }
    if (result && result.data && result.data.id) {
      let defaultRoute =
        result.data &&
        result.data?.uiCapabilities?.filter((val) => val.isDefault);
      if (result?.data?.resource?.isDashboardLocked && !hasLeadId) {
        this.props.update({ defaultLocation: "/dashboard-lock" });
        this.props.history.push("/dashboard-lock");
      } else {
        this.getDefaultLocation(defaultRoute && defaultRoute[0]?.name);
      }
      let capabilities = [];
      result.data?.uiCapabilities.map((val) => {
        capabilities.push(val.name);
      });
      this.props.update(
        {
          userId: result?.data?.id,
          userName: result?.data?.resource?.resourceName,
          profile: result?.data,
          loading: false,
          resourceHierarchy: result?.data?.resource?.hierarchy,
          capabilities: capabilities,
          attendancePopUp: result?.data?.attendance?.showPopUp,
        },
        () => this.getStatusFn()
      );
      if (result.data.attendance?.showPopUp) {
        this.props.openModal(this.punchIn());
      }
      localStorage.setItem("userId", result.data.resource.id);

      localStorage.setItem(
        "isLoan",
        checkBusinessValue(this.props.bussinessUnitSettings)
      );
    }
  };
  getDefaultLocation = (defaultRoute) => {
    switch (defaultRoute) {
      case "hood_sales_lead":
        this.props.update({ defaultLocation: "/" });
        break;
      case "hood_service_lead":
        this.props.update({ defaultLocation: "/lead-service" });
        break;
      case "sales_audit":
        this.props.update({ defaultLocation: "/audit" });
        break;
      case "loan_sales_lead":
        this.props.update({ defaultLocation: "/sales-loan" });
        break;
      case "loan_service_lead":
        this.props.update({ defaultLocation: "/service-loan" });
        break;
      case "inventory":
        this.props.update({ defaultLocation: "/inventory" });
        break;
      case "service_features":
        this.props.update({ defaultLocation: "/service-feature-management" });
        break;
      case "resource_management":
        this.props.update({ defaultLocation: "/resource" });
        break;
      case "resource_tree":
        this.props.update({ defaultLocation: "/resource-tree" });
        break;
      case "data_import":
        this.props.update({ defaultLocation: "/lead-import" });
        break;
      case "role_management":
        this.props.update({ defaultLocation: "/job-role" });
        break;
      case "lov_management":
        this.props.update({ defaultLocation: "/lov-setup" });
        break;
      case "additional_attributes":
        this.props.update({ defaultLocation: "/manage-attribute" });
        break;
      case "notifications":
        this.props.update({ defaultLocation: "/notification-management" });
        break;
      case "starship_reports":
        this.props.update({ defaultLocation: "/reports" });
        break;
      case "document_management":
        this.props.update({ defaultLocation: "/document-management" });
        break;
      case "call_log":
        this.props.update({ defaultLocation: "/call-history" });
        break;
      case "ticket_management":
        this.props.update({ defaultLocation: "/tickets" });
        break;
      default:
        this.props.update({ defaultLocation: "/" });
    }
  };

  postStatusFn = async (data) => {
    const result = await postStatus(data);
    if (result.status == 1) {
      this.props.update({
        currentStatus: result?.data?.status,
        resourceTimelineId: result?.data?.id,
      });
      this.props.showToast({
        type: "success",
        message: result?.message
          ? result.message
          : "Updated Status Successfully",
      });
      this.props.closeModal();
    } else {
      this.props.showToast({
        type: "error",
        message: result?.message ? result.message : "Something Went Wrong ",
      });
    }
  };
  getStatusFn = async () => {
    const endDate = momentFormat(currentTimeEpoch(), `yyyy-MM-DD hh:mm:ss a`);
    const result = await getStatus(this.props?.profile?.resource?.id, endDate);
    if (result.status === 1) {
      this.props.update({
        currentStatus: result?.data?.status,
        resourceTimelineId: result?.data?.resourceTimelineId,
      });
    }
  };
  endBreakFn = async () => {
    const endDate = momentFormat(currentTimeEpoch(), `YYYY-MM-DDTHH:mm:ss.SSS`);
    const body = {
      endDate: endDate,
    };
    const result = await endBreakApi(this.props.resourceTimelineId, body);
    if (result.status === 1) {
      this.props.showToast({
        type: "success",
        message: result?.message ? result.message : "Ended Status Successfully",
      });
      this.props.closeModal();
    } else {
      this.props.showToast({
        type: "error",
        message: result?.message ? result.message : "Something Went Wrong ",
      });
    }
  };

  componentDidMount() {
    if (!this.props.userName) {
      this.getUserProfile();
    } else {
      this.getStatusFn();
    }
    if (this.props.attendancePopUp) this.props.openModal(this.punchIn());
  }
  goToProfile = () => {
    return (
      <ModalPanel width={"500px"}>
        <ProfileDetailModal
          profile={this.props.profile}
          closeModal={this.props.closeModal}
          update={this.props.update}
          hierarchyView={() => this.props.openModal(this.hierarchyView())}
        />
      </ModalPanel>
    );
  };
  punchIn = () => {
    return (
      <ModalPanel width={"650px"} borderRadius={"8px"} disablePanelClose="true">
        <PunchInPopUp
          closeModal={this.props.closeModal}
          userName={this.props.userName}
          punchInAction={this.props.handlePunchInOut}
        />
      </ModalPanel>
    );
  };
  updateStatus = () => {
    return (
      <ModalPanel width={"550px"} borderRadius="8px">
        <UpdateStatus
          closeModal={this.props.closeModal}
          profile={this.props.profile}
          postStatus={this.postStatusFn}
        />
      </ModalPanel>
    );
  };
  hierarchyView = () => {
    return (
      <ModalPanel width={"600px"}>
        <ResourceTreeContainer
          disableSearch={true}
          disableParentHierarchy={true}
          showInProfile={true}
        />
      </ModalPanel>
    );
  };
  render() {
    return (
      <React.Fragment>
        <UserProfile
          name={this.props.userName}
          goToProfile={() => this.props.openModal(this.goToProfile())}
          updateStatus={() => this.props.openModal(this.updateStatus())}
          endBreak={this.endBreakFn}
          currentStatus={this.props.currentStatus}
          universalLabelsDetail={this.props.universalLabelsDetail}
          showLoader={this.showLoader}
          hideLoader={this.hideLoader}
          showToast={this.props.showToast}
          openModal={this.props.openModal}
          closeModal={this.props.closeModal}
          label={this.props.label}
          profile={this.props.profile}
          textColor={this.props.textColor}
          workLogModal={this.props.workLogModal}
          workLogAutoOpen={this.props.workLogAutoOpen}
        />
        {this.state.loader && <Loader />}
      </React.Fragment>
    );
  }
}
