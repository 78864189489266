/*eslint no-undef: 0*/
import { getCookie } from "../utils/cookie";
import { decryD } from "../utils/utils";

const url = window?.location?.hostname?.includes("localhost")
  ? "https://crm.nobrokerhood.com" + "/api/v1"
  : window.location?.origin + "/api/v1";
const hoodUrl = "https://www.nobrokerhood.com/api/v1";
const ACCESS_TOKEN = "accessToken";
const TENANT = "tenant";
const API_URL_ROOT = url;
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
export const logout = () => {
  try {
    localStorage.removeItem("accessToken");
    window.location = "/";
  } catch (e) {
    console.log(e);
  }
};
export async function get(url) {
  //  setTimeout(async () => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    const result = await fetch(`${API_URL_ROOT}/${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        "content-type": "application/json",
        "X-Request-Id": create_UUID(),

        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
      credentials: "same-origin",
    });
    if (result.status === 401) {
      logout();
    } else {
      const resultJson = await result.json();

      if (resultJson.entity) {
        const data = decryD(resultJson);
        //console.log(data);
        return data;
      }
      return resultJson;
    }
  }
}
export async function csvGet(url) {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    const result = await fetch(`${API_URL_ROOT}/${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        "content-type": "application/json",
        "X-Request-Id": create_UUID(),

        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
      credentials: "same-origin",
    });
    const resultJson = await result.text();
    return resultJson;
  }
}
export async function getSocialTicket(url) {
  let headerObj = {};
  // if (pageId) {
  //   headerObj["X-Page-ID"] = pageId;
  // }
  const result = await fetch(
    `https://univw-social.nobroker.in/crm-api/${url}`,
    {
      headers: {
        ...headerObj,
      },
    }
  );
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}

export async function hoodGet(url) {
  //  setTimeout(async () => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    const result = await fetch(`${hoodUrl}/${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        "content-type": "application/json",
        "X-Request-Id": create_UUID(),
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
      credentials: "same-origin",
    });
    if (result.status === 401) {
      logout();
    } else {
      const resultJson = await result.json();

      if (resultJson.entity) {
        const data = decryD(resultJson);
        //console.log(data);
        return data;
      }
      return resultJson;
    }
  }
}
// function timeout(ms, promise) {
//   return new Promise(function (resolve, reject) {
//     setTimeout(function () {
//       reject(new Error("timeout"));
//     }, ms);
//     promise.then(resolve, reject);
//   });
// }
export async function getWithTimeout(url) {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    const controller = new AbortController();
    var signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), 5000);
    const result = await fetch(`${API_URL_ROOT}/${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        "content-type": "application/json",
        "X-Request-Id": create_UUID(),
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
      credentials: "same-origin",
      signal: signal,
    });

    const resultJson = await result.json();
    clearTimeout(timeoutId);
    if (resultJson.entity) {
      const data = decryD(resultJson);
      //console.log(data);
      return data;
    }
    return resultJson;
  }
}
export async function coreGet(url) {
  //  setTimeout(async () => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    const result = await fetch(`${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
      credentials: "same-origin",
    });
    const resultJson = await result.json();

    return resultJson;
  }
}

export async function post(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const formData = new FormData();

  for (const name in postData) {
    formData.append(name, postData[name]);
  }
  const result = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      // Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}

export async function documentPost(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: postData,
    headers: {
      // Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function imagePost(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const formData = new FormData();

  for (const name in postData) {
    formData.append(name, postData[name]);
  }
  const result = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function mediaPost(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function mediaPostSocialTicket(path, postData) {
  const url = `https://univw-social.nobroker.in/crm-api/${path}`;

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (result?.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function formPostSocialTicket(path, formData, pageId) {
  const url = `https://univw-social.nobroker.in/crm-api/${path}`;
  let headerObj = {};
  if (pageId) {
    headerObj["X-Page-ID"] = pageId;
  }

  const result = await fetch(url, {
    method: "POST",
    body: formData,
  });
  if (result?.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function deleteSocialTicket(path, postData) {
  const url = `https://univw-social.nobroker.in/crm-api/${path}`;
  const result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(postData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (result?.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function hoodPost(path, postData) {
  const url = `${hoodUrl}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function hoodPut(path, putData) {
  const url = `${hoodUrl}/${path}`;

  const result = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(putData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function notePost(path, postData) {
  // const url = `${API_URL_ROOT}/${path}?noteText=Test in url param&accountId=8a20809970a4c7450170a4c8ca590001&leadId=8a20809970a4c7450170a4c8ca520000&ownerId=101&longitude=&latitude=`;
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function put(path, putData) {
  const url = `${API_URL_ROOT}/${path}`;

  const result = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(putData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function patch(path, putData) {
  const url = `${API_URL_ROOT}/${path}`;

  const result = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify(putData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}
export async function coreDelete(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}
export async function unAuthorizedPost(path, postData) {
  const tenant = getCookie(TENANT);
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": tenant,
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function unAuthorizedFormDataPost(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: postData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "LOAN",
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}

export async function unAuthorizedFormDataPostInteriors(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: postData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "interiors",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}
export async function unAuthorizedFormDataPut(path, putData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(putData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "LOAN",
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
export async function unAuthorizedDelete(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "LOAN",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedDeleteHood(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "HOOD",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedDeleteInteriors(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "interiors",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedGet(path) {
  const tenant = getCookie(TENANT);
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": tenant,
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedConfGet(path) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedGetLoan(path) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "LOAN",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}
export async function unAuthorizedGetInteriors(path) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-Tenant-ID": "interiors",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function multiPartformPost(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const formData = new FormData();

  for (const name in postData) {
    formData.append(name, postData[name]);
  }
  const result = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "multipart/form-data",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function formDataPost(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: postData,
    headers: {
      // Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  if (result.status === 401) {
    logout();
  } else {
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}

export async function formDataPut(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "PUT",
    body: postData,
    headers: {
      // Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  return resultJson;
}
export function makeGCPAudioBlobRequest(url, slashRtcKey) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject();
      }
    };
    xhr.onerror = function () {
      reject();
    };

    xhr.responseType = "blob";
    xhr.setRequestHeader("Authorization", "Bearer " + slashRtcKey);
    xhr.send();
  });
}

export async function remove(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const formData = new FormData();

  for (const name in postData) {
    formData.append(name, postData[name]);
  }
  const result = await fetch(url, {
    method: "DELETE",
    body: formData,
    headers: {
      // Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      //"Content-Type": "application/x-www-form-urlencoded"
      //"Content-Type": "application/json"
      // "Content-Type": "multipart/form-data"
    },
  });
  const resultJson = await result.json();
  return resultJson;
}
export async function putUpload(url, putData, token) {
  var xhr = new XMLHttpRequest();
  xhr.open("PUT", url, true);
  xhr.setRequestHeader("Content-type", "application/octet-stream");
  xhr.setRequestHeader("Authorization", "Bearer " + token);
  xhr.onload = (response) => {
    console.log("on-load", response);
  };
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        console.log("Status OK");
      } else {
        console.log("Status not 200");
      }
    }
  };
  xhr.onerror = function (response) {
    console.log("Response error", response);
  };
  xhr.upload.onprogress = function (evt) {
    // For uploads
    if (evt.lengthComputable) {
      var percentComplete = parseInt((evt.loaded / evt.total) * 100);
      console.log("progress", percentComplete);
    }
  };
  xhr.send(putData);
}
export async function corePost(url, postData) {
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}
export async function corePut(url, postData) {
  try {
    const result = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(postData),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        "Content-Type": "application/json",

        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
    });
    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  } catch (err) {
    //  throw new Error(err);
    return {};
  }
}
export async function plainDelete(url, postData) {
  const result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-Request-Id": create_UUID(),
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedPostKYC(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      "Content-Type": "application/json",
      "X-NB-Authorization": true,
      // "X-Tenant-ID": "interiors",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAutorizedGetKyc(path) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "content-type": "application/json",
      "X-Request-Id": create_UUID(),
      "X-NB-Authorization": true,
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function unAuthorizedFormDataPostKYC(path, postData) {
  const url = `${API_URL_ROOT}/${path}`;
  const result = await fetch(url, {
    method: "POST",
    body: postData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "X-Request-Id": create_UUID(),
      // "Content-Type": "application/json",
      "X-NB-Authorization": true,
      // "X-Tenant-ID": "interiors",
    },
  });
  const resultJson = await result.json();
  if (resultJson.entity) {
    const data = decryD(resultJson);
    return data;
  }
  return resultJson;
}

export async function customGet(path, url) {
  //  setTimeout(async () => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    const result = await fetch(`${path}/${url}`, {
      headers: {
        "content-type": "application/json",
        "X-Request-Id": create_UUID(),
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
      credentials: "include",
    });
    if (result.status === 401) {
      logout();
    } else {
      const resultJson = await result.json();

      if (resultJson.entity) {
        const data = decryD(resultJson);
        //console.log(data);
        return data;
      }
      return resultJson;
    }
  }
}
export async function customGet2(path, url) {
  if (JSON.parse(localStorage.getItem("SocialAccessToken"))) {
    const result = await fetch(`${path}/${url}`, {
      headers: {
        "content-type": "application/json",
        "X-Request-Id": create_UUID(),
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("SocialAccessToken")),
      },
      credentials: "include",
    });

    const resultJson = await result.json();
    if (resultJson.entity) {
      const data = decryD(resultJson);
      return data;
    }
    return resultJson;
  }
}
