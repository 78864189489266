import React from "react";
import "./PunchInPopUp.scss";
import Button from "./common/Button";
function PunchInPopUp({ userName, punchInAction, closeModal }) {
  return (
    <div styleName="main-container">
      <div styleName="text-container">
        <span styleName="title">Hi there, {userName}!</span>
        <span styleName="sub-title">Greeting for the day,</span>
        <span styleName="sub-title">
          Please <b>Punch-In</b> to begin your Work.
        </span>
      </div>
      <div styleName="footer">
        <Button
          type="secondary"
          label="Ignore"
          onClick={closeModal}
          styles={{
            fontSize: "14px",
            fontWeight: "600",
            color: "grey",
            backgroundColor: "#ECEEF0",
          }}
        />
        <Button
          type="secondary"
          label="Punch In"
          onClick={() => {
            punchInAction("punch-in", closeModal, true);
          }}
          styles={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#0CB88F",
            borderColor: "#0CB88F",
          }}
        />
      </div>
    </div>
  );
}

export default PunchInPopUp;
