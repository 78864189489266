import React, { Component } from "react";
import "./SingleSelectCheckBox.scss";

export default class SingleSelectCheckBox extends Component {
  handleSelection = (val) => {
    let isSelected =
      this.props.selected && this.props.selected.includes(val.value)
        ? true
        : false;
    if (this.props.onSelect) {
      this.props.onSelect(isSelected ? null : val.value);
    }
  };
  render() {
    return (
      <div>
        <div styleName={"single-base"}>
          {this.props.options &&
            this.props.options.map((val, i) => {
              return (
                <div
                  styleName={"single-row"}
                  key={i}
                  style={{ width: this.props.rowWidth }}>
                  <div
                    styleName={
                      this.props.selected &&
                      this.props.selected.length > 0 &&
                      this.props.selected.includes(val.value)
                        ? "button-active"
                        : "button"
                    }
                    onClick={() => {
                      !this.props.disabled && this.handleSelection(val);
                    }}
                    tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
                    onKeyUp={(e) =>
                      e.key === "Enter" &&
                      !this.props.disabled &&
                      this.handleSelection(val)
                    }></div>
                  <div
                    styleName={"label"}
                    style={{
                      color: this.props.color,
                      fontSize: this.props.fontSize,
                    }}>
                    {val.label}
                  </div>
                </div>
              );
            })}
        </div>
        {this.props.error && <div styleName={"error"}>{this.props.error}</div>}
      </div>
    );
  }
}
