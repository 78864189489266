import React, { Component } from "react";
import { objectToArray } from "../utils/utils";
import Button from "./common/Button";
import DropDown from "./common/DropDown";
import Input from "./common/Input";
import Textarea from "./common/Textarea";
import "./Form.scss";
export default class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      notes: "",
      error: false,
      createSecondarySales: "true",
      remark: "",
      customAmount: this.props.customAmount ? this.props.customAmount : "",
      discountAmount: this.props.customAmount
        ? Math.floor(this.props.customAmount * 0.01)
        : "",
      rangeError: false,
      discountError: false,
    };
  }
  handleSubmit = () => {
    if (this.props.onSubmit) {
      if (this.props?.generatePaymentLinkByDiscount) {
        if (+this.state.discountAmount > +this.state.customAmount * 0.01) {
          this.setState({ discountError: true });
          return;
        }
      }

      if (this.props.showRemark && this.props.showInput) {
        if (!this.state.customAmount || !this.state.remark) {
          this.setState({ error: true });
        } else if (
          +this.props?.data?.totalAmountPaid + +this.state?.customAmount >
          this.props?.data?.netTotal
        ) {
          this.setState({ rangeError: true });
        } else {
          this.props.onSubmit(
            this.state.remark,
            this.state.customAmount,
            this.state.discountAmount
          );
        }
      } else if (this.props.showRemark) {
        if (!this.state.remark) {
          this.setState({ error: true });
        } else {
          this.props.onSubmit(this.state.remark);
        }
      } else {
        this.props.onSubmit();
      }
    }
  };

  handleSubmitWithNote = () => {
    if (!this.state.reason || !this.state.notes) {
      this.setState({ error: true });
    } else {
      if (this.props.onSubmit) {
        if (this.props.winback) {
          this.props.onSubmit(
            this.state.notes,
            this.props.sendReasonValue
              ? this.state.reason
              : this.props.notesType[this.state.reason],
            this.state.createSecondarySales
          );
        } else {
          this.props.onSubmit(
            this.state.notes,
            this.props.sendReasonValue
              ? this.state.reason
              : this.props.notesType[this.state.reason]
          );
        }
      }
    }
  };
  handleClose = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };
  validateField = (value, validator) => {
    return validator(value);
  };
  render() {
    let notesType = objectToArray(this.props.notesType);
    let booleanOption = [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ];
    return (
      <div styleName={"contact-base"}>
        {this.props?.headerLabel && (
          <div styleName={"header"}>
            <div styleName={"header-text"}>{this.props?.headerLabel}</div>
          </div>
        )}
        <div styleName="container">
          <div
            styleName={"row"}
            style={
              this.props.showNote
                ? {
                    fontSize: "16px",
                    fontWeight: "500",
                  }
                : {}
            }>
            {this.props.label
              ? this.props.label
              : "Are you sure you want to delete ?"}
          </div>
          {this.props.showInput && (
            <div
              styleName="row"
              style={{
                display: this.props.generatePaymentLinkByDiscount && "flex",
                gap: this.props.generatePaymentLinkByDiscount && "10px",
              }}>
              <div
                styleName={
                  this.props.generatePaymentLinkByDiscount ? "col48" : "row"
                }>
                <Input
                  placeholder={"Custom Amount *"}
                  type="Number"
                  value={this.state.customAmount}
                  onChange={(val) => {
                    this.setState({
                      customAmount: val,
                      discountAmount: Math.floor(val * 0.01),
                    });
                  }}
                  error={
                    this.state.rangeError
                      ? "Custom amount should be less than or equal to the remaining amount"
                      : this.state.error && !this.state.customAmount
                      ? "This field is required"
                      : null
                  }
                />
              </div>
              {this.props.generatePaymentLinkByDiscount && (
                <div styleName="col48">
                  <Input
                    placeholder={"Discount (Max 1%) *"}
                    type="Number"
                    value={this.state.discountAmount}
                    onChange={(val) => {
                      const isDiscountAmount =
                        +val <= +this.state.customAmount * 0.01;

                      this.setState({
                        discountAmount: val,
                        discountError: isDiscountAmount ? false : true,
                      });
                    }}
                    error={
                      this.state.discountError
                        ? "Discount should be less than or equal to the 1% of amount"
                        : this.state.error && !this.state.discountAmount
                        ? "This field is required"
                        : null
                    }
                  />
                </div>
              )}
            </div>
          )}
          {this.props.showRemark && (
            <React.Fragment>
              <div style={{ margin: "20px 0px" }}>
                <Textarea
                  placeholder={this.props.textAreaPlaceholder ?? "Remark"}
                  value={this.state.remark}
                  onChange={(val) => {
                    this.setState({ remark: val });
                  }}
                  error={
                    this.state.error && !this.state.remark
                      ? "This field is required"
                      : null
                  }
                />
              </div>
            </React.Fragment>
          )}
          {this.props.showNote && (
            <React.Fragment>
              <div style={{ margin: "20px 0px" }}>
                <DropDown
                  options={notesType}
                  placeholder="Select Reason"
                  selected={this.state.reason}
                  onSelect={(val) => {
                    this.setState({ reason: val.value });
                  }}
                  error={
                    this.state.error && !this.state.reason
                      ? "Please Select reason"
                      : null
                  }
                  filter={true}
                  showSearch={true}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Textarea
                  placeholder={"Add a note"}
                  value={this.state.notes}
                  onChange={(val) => {
                    this.setState({ notes: val });
                  }}
                  error={
                    this.state.error && !this.state.notes
                      ? "Please Enter note"
                      : null
                  }
                />
              </div>
              {this.props.winback && (
                <DropDown
                  options={booleanOption}
                  placeholder="Eligible for winback ?"
                  selected={this.state.createSecondarySales}
                  onSelect={(val) => {
                    this.setState({ createSecondarySales: val.value });
                  }}
                  comment={true}
                  showIcon={true}
                  active={false}
                />
              )}
            </React.Fragment>
          )}
        </div>
        {this.props.generatePaymentLinkByDiscount &&
          +this.state.discountAmount > 0 && (
            <div styleName="note-text">
              * Payment methods will be limited to DEBIT and CREDIT cards only
            </div>
          )}
        <div
          styleName={"footer"}
          style={{ margin: this.props.removeMarginFromFooter ? "0px" : "" }}>
          <Button
            type="primary"
            label="Cancel"
            onClick={() => this.handleClose()}
          />
          {this.props.hideSubmit ? (
            ""
          ) : (
            <div styleName={"button"}>
              <Button
                color={
                  this.props.submitButtonColor
                    ? this.props.submitButtonColor
                    : "#ff435e"
                }
                label={this.props.buttonLabel || "Proceed"}
                onClick={
                  this.props.showNote
                    ? this.handleSubmitWithNote
                    : this.handleSubmit
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
