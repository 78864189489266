import React, { useRef, useState } from "react";
import { changePassword } from "../../api/profile";
import { editResource } from "../../api/resource";
// import { createCookie, getCookie } from "../../utils/cookie";
import { checkEmptyObject, validateEmpty } from "../../utils/validations";
import Button from "../common/Button";
import CheckBox from "../common/CheckBox";
import { Form, FormElement } from "../common/core/Form";
import Input from "../common/Input";
import Loader from "../common/Loader";
import Toast from "../common/Toasts/Toast";
// import ToggleButton from "../societyCard/ToggleButton";
import "./ProfileDetailModal.scss";
export default function ProfileDetailModal(props) {
  //state
  const [errors, seterrors] = useState({});
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [flag, setflag] = useState(false);
  const [loading, setloading] = useState(false);
  const [isRemote, setIsRemote] = useState(
    props?.profile?.resource?.defaultCallingMethod || ""
  );
  const toastRef = useRef();
  //error messages
  let errorMessages = {
    oldPassword: "Please enter old password",
    password: "Please enter new password",
  };

  //functions
  const validateFunction = (val) => {
    switch (val) {
      default:
        return validateEmpty;
    }
  };
  const validateField = (value, validator) => {
    return validator(value);
  };

  const validate = (val) => {
    Object.keys(errorMessages)?.map((key) => {
      if (!validateField(val[key], validateFunction(key))) {
        const error = Object.assign(errors, { [key]: true });
        seterrors(error);
      } else {
        const error = Object.assign(errors, { [key]: false });
        delete error[key];
        seterrors(error);
      }
    });
    setflag(!flag);
  };
  const showError = (error) => {
    toastRef.current.addToast({
      type: "error",
      message: error ? error : "Something Went Wrong ",
    });
  };
  const showToast = (toast) => {
    toastRef.current.addToast({
      type: toast.type,
      message: toast.message ? toast.message : "Something Went Wrong ",
    });
  };
  const handleSubmit = async (val) => {
    validate(val);
    if (checkEmptyObject(errors)) {
      let data = {
        oldPassword: val.oldPassword,
        password: val.password,
      };
      setloading(true);
      const result = await changePassword(data);
      setloading(false);
      if (result.status === 1) {
        showToast({
          type: "success",
          message: result.message
            ? result.message
            : "Password changed successfully",
        });
        setPasswordFlag(false);
      } else {
        showError(result.errorDescription);
      }
    }
  };
  const handleCallingMethod = async (val) => {
    let propsObj = props?.profile;
    let data = {
      id: propsObj.resource?.id,
      defaultCallingMethod: val,
      primaryPhoneNumber: propsObj?.resource?.primaryPhoneNumber,
    };
    setloading(true);
    const result = await editResource(data);
    setloading(false);
    if (result.status === 1) {
      const existingProfileData = props.profile;
      existingProfileData.resource = result.data;
      props.update({ profile: existingProfileData });
      showToast({
        type: "success",
        message: result.message
          ? result.message
          : "Successfully updated resource details",
      });
      props.closeModal();
    } else {
      showToast({
        type: "error",
        message: result.errorDescription
          ? result.errorDescription
          : "Something went wrong",
      });
    }
  };

  const initials =
    props.profile?.resource?.partyName &&
    props.profile?.resource?.partyName.split("")[0];
  const remoteOption = [
    {
      label: `Remote`,
      value: "REMOTE",
    },
  ];
  const desktopOption = [
    {
      label: `Desktop`,
      value: "DESKTOP",
    },
  ];
  const handleSetState = (val) => {
    if (val === "DESKTOP") {
      setIsRemote("DESKTOP");
    } else {
      setIsRemote("REMOTE");
    }
  };
  return (
    <div styleName={"base"}>
      {loading && <Loader />}
      <div styleName="cross-icon" onClick={props.closeModal} />
      {passwordFlag ? (
        <Form
          onSubmit={(val) => {
            handleSubmit(val);
          }}>
          <FormElement>
            {(state) => (
              <div styleName="form-container">
                <div styleName="row">
                  <Input
                    type="password"
                    placeholder={"Old Password"}
                    value={state.oldPassword}
                    onChange={(val) => {
                      state.updateForm({
                        oldPassword: val,
                      });
                    }}
                    error={
                      errors.oldPassword ? errorMessages.oldPassword : null
                    }
                  />
                </div>
                <div styleName="row">
                  <Input
                    type="password"
                    placeholder={"New Password"}
                    value={state.password}
                    onChange={(val) => {
                      state.updateForm({
                        password: val,
                      });
                    }}
                    error={errors.password ? errorMessages.password : null}
                  />
                </div>
                <div styleName="button-container">
                  <Button
                    width="100%"
                    label="Change password"
                    onClick={(val) => state.onSubmit(val)}
                  />
                </div>
                <div styleName="button-container">
                  <Button
                    width="100%"
                    type="secondary"
                    label={"Cancel"}
                    onClick={() => setPasswordFlag(false)}
                    color={`#1CB88F`}
                  />
                </div>
              </div>
            )}
          </FormElement>
        </Form>
      ) : (
        <React.Fragment>
          <div styleName="first-box">
            <div styleName="icon-box">
              <div styleName={"initials"}>{initials}</div>
              <div styleName="label" title="Name">
                <span styleName="key">
                  {props.profile?.resource?.partyName}
                </span>
              </div>
            </div>
            <div styleName="name-email">
              <div styleName="label email-icon" title="Email">
                <span styleName="key">
                  {props.profile?.resource?.primaryEmailAddress}
                </span>
              </div>
              <div styleName="label phone-icon" title="Phone">
                <span styleName="key">
                  {props.profile?.resource?.primaryPhoneNumber}
                </span>
              </div>
              <div styleName="label role-icon" title="Job role">
                <span styleName="key">
                  {props.profile?.resource?.resourceJobRole?.roleName}
                </span>
              </div>
              <div styleName="label manager-icon" title="Reporting Manager">
                <span styleName="key">
                  {props.profile?.managerResource?.primaryEmailAddress}
                </span>
              </div>
              <div styleName="checkbox-header">Initiate call from</div>
              <div styleName="checkbox-container">
                <div styleName="two-checkboxes">
                  <CheckBox
                    onSelect={(val) => {
                      handleSetState(val);
                      handleCallingMethod(val);
                    }}
                    selected={isRemote === "DESKTOP" ? "DESKTOP" : ""}
                    options={desktopOption}
                  />
                  <CheckBox
                    onSelect={(val) => {
                      handleSetState(val);
                      handleCallingMethod(val);
                    }}
                    selected={isRemote === "REMOTE" ? "REMOTE" : ""}
                    options={remoteOption}
                  />
                </div>
              </div>
            </div>
          </div>
          <div styleName="line"></div>
          <div style={{ display: "flex", columnGap: "10px" }}>
            <Button
              width="100%"
              type="secondary"
              label="View Hierarchy"
              onClick={props?.hierarchyView}
            />
            <Button
              width="100%"
              label="Change Password"
              onClick={() => setPasswordFlag(true)}
            />
          </div>
        </React.Fragment>
      )}
      <Toast ref={toastRef} />
    </div>
  );
}
