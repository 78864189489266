import React from "react";
import "./KAMdashCard.scss";
import CircularProgressBar from "./common/CircularProgressBar";
import { PIE_COLOR } from "../utils/constant";

function KAMPieCard({ title, pieData }) {
  const pieTotalValue = pieData?.reduce((acc, curr) => {
    return curr.total + acc;
  }, 0);
  return (
    <div styleName="main-container">
      <div styleName="title">{title}</div>
      <div style={{ display: "flex", columnGap: "50px", alignItems: "center" }}>
        <div
          style={{
            width: "55%",
          }}>
          <CircularProgressBar
            strokeWidth="1"
            sqSize="12"
            innerText={`Total ${title}`}
            color={"#00C791"}
            pieData={pieData}
            pieColor={PIE_COLOR}
            pieTotalValue={pieTotalValue}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
          }}>
          {pieData?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  columnGap: "10px",
                }}>
                <div
                  styleName="pie-color"
                  style={{ backgroundColor: PIE_COLOR[index] }}
                />
                <div styleName="pie-label">
                  {item?.description} ({item?.total})
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default KAMPieCard;
