import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import App from "./App";
import { sendErrorToFaultWatch } from "./utils/utils";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://e5a9b09cf61b45e2ae6047a9036a03da@o85028.ingest.sentry.io/4504558325202944",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      sendErrorToFaultWatch(event, hint);
      const ignoreBeforeErrors = [
        "InvalidCharacterError: The string contains invalid characters.",
        "null is not an object",
        "Loading chunk",
        "Java exception was raised during method invocation",
        "forEach is not a function",
        "Unexpected number in JSON at position",
        "Can't find variable: google", // 2679148289
        `InvalidCharacterError: Failed to execute 'btoa' on 'Window': 
        The string to be encoded contains characters outside of the Latin1 range`, // 2679150586
        "Cannot read property 'getBoundingClientRect' of null", // 2656800484
        "Cannot read properties of null (reading 'getBoundingClientRect')", // 2664957203
        "SecurityError: Failed to execute 'pushState' on 'History'",
        "'google' is not defined", // 2684449543
        "Maximum call stack size exceeded", // 2684345205
        "Cannot read properties of null (reading 'zoom')", // 2679386216
        "SecurityError: The operation is insecure", // 2680839633
        "Cannot read properties of null (reading 'body')", // 2679276651
        "Cannot read properties of null (reading 'body')", // 2679276651
        "Maximum update depth exceeded", // 2679634082
        "not a LatLng or LatLngLiteral with finite coordinates: in property lat: not a number", // 2679738761
        "ChunkLoadError",
        "ChunkLoadError: Loading chunk",
        "Cannot read property 'postMessage' of null",
        "googleMapLoadCallBack is not a function",
        "Java object is gone", // 2692636406
        "r.indexOf is not a function", // 2696367718
        "Cannot read property 'body' of null",
        "e.database is not a function",
        "Cannot read property 'zoom' of null",
        "Loading CSS chunk",
        "Converting circular structure to JSON",
        "Failed to execute 'pushState' on 'History",
        "not a LatLngBounds or LatLngBoundsLiteral: unknown property",
        "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
        "String contains an invalid character",
        "Object doesn't support property or method 'database",
        "Failed to construct 'Worker': Maximum worker number has been reached", // UC browser known issue
        "can't access dead object",
        "Failed to execute 'setItem' on 'Storage'",
        "Failed to execute 'drawImage' on 'CanvasRenderingContext2D'",
        "Failed to register a ServiceWorker for scope",
      ];
      for (let i = 0; i <= ignoreBeforeErrors.length; i++) {
        if (hint.originalException?.message?.includes(ignoreBeforeErrors[i])) {
          return false;
        }
      }
      return event;
    },
    ignoreErrors: [
      "missing ) after argument list",
      "Network Error",
      "JSON Parse error: Invalid number",
      "google is not defined",
      "ResizeObserver loop limit exceeded",
      "InvalidCharacterError: Failed to execute 'btoa' on 'Window'",
      "Cannot read property 'getReadModeConfig' of undefined",
      "QuotaExceededError: The quota has been exceeded",
      "Illegal invocation",
      "VAD_ON_APPBACK is not defined",
      "Cannot read properties of undefined (reading 'closeActivity')",
      "Java exception was raised during method invocation",
      "this.scriptTagHolder is null",
      "/Cannot read properties of null (reading $)/",
      "InvalidCharacterError: The string contains invalid characters",
      "No error message",
      "JSON.stringify cannot serialize cyclic structures.",
      "$ is not defined",
      "A mutation operation was attempted on a database that did not allow mutations",
      "Failed to register a ServiceWorker for scope",
      "QuotaExceededError: Failed to PutOrAdd in database because not enough space for domain",
    ],
  });
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
