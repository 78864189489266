import loadable from "@loadable/component";
import React from "react";
import { Route, Switch } from "react-router-dom";
const MainContainer = loadable(() => import("../../containers/MainContainer"));
import { Consumer } from "../../containers/Provider";
import IntegrationEditor from "../IntegrationEditor";
import { ModalControl } from "../common/modal/ModalRoot.js";
import MyDashboardContainer from "../../containers/MyDashboardContainer.js";
import SocialCommonHeader from "../SocialCRM/SocialCommonHeader.js";
const Dashboard = loadable(() => import("../Dashboard"));
const PageFrame = loadable(() => import("../page/PageFrame"));
const Header = loadable(() => import("../Header"));
const TemplatesContainer = loadable(() =>
  import("../../containers/TemplatesContainer")
);
const TemplateEditor = loadable(() => import("../TemplateEditor"));
const QuotationContainer = loadable(() =>
  import("../../containers/QuotationsContainer")
);
const GeolocationContainer = loadable(() =>
  import("../../containers/GeolocationContainer.js")
);
const CallingDashboardContainer = loadable(() =>
  import("../../containers/CallingDashboardContainer")
);
const DashboardContainer = loadable(() =>
  import("../../containers/DashboardContainer")
);
const Navigator = loadable(() => import("../Navigator"));
const ReasourceTreeContainer = loadable(() =>
  import("../../containers/ResourceTreeContainer")
);
const PartnerContainer = loadable(() =>
  import("../../containers/PartnerContainer")
);

const ResourceContainer = loadable(() =>
  import("../../containers/ResourceContainer")
);
const LeadImportContainer = loadable(() =>
  import("../../containers/LeadImportContainer")
);
const JobRoleContainer = loadable(() =>
  import("../../containers/JobRoleContainer")
);
const ManageAttributeContainer = loadable(() =>
  import("../../containers/ManageAttributeContainer")
);
const LovContainer = loadable(() => import("../../containers/LovContainer"));
const SocietyLocation = loadable(() => import("../SocietyLocation"));
const PusherNotifications = loadable(() =>
  import("../pusher/PusherNotifications")
);
const NotificationManagementContainer = loadable(() =>
  import("../../containers/NotificationManagementContainer")
);
const KibanaContainer = loadable(() =>
  import("../../containers/KibanaContainer")
);
const ReportManagementContainer = loadable(() =>
  import("../../containers/ReportManagementContainer")
);
const ManageDocumentsContainer = loadable(() =>
  import("../../containers/ManageDocumentsContainer")
);
const ServiceDashboardContainer = loadable(() =>
  import("../../containers/ServiceDashboardContainer")
);
const ServiceFeatureContainer = loadable(() =>
  import("../../containers/ServiceFeatureContainer")
);
const CallLoginAdminContainer = loadable(() =>
  import("../../containers/CallLoginAdminContainer")
);
const InventoryContainer = loadable(() =>
  import("../../containers/InventoryContainer")
);
const ManageTicketsContainer = loadable(() =>
  import("../../containers/ManageTicketsContainer")
);
const LoanDashboardContainer = loadable(() =>
  import("../../containers/LoanDashboardContainer")
);
const LoanServiceDashboardContainer = loadable(() =>
  import("../../containers/LoanServiceDashboardContainer")
);
const MyDocumentContainer = loadable(() =>
  import("../../containers/MyDocumentContainer")
);
const MyApprovalsContainer = loadable(() =>
  import("../../containers/MyApprovalsContainer")
);
const AuditContainer = loadable(() =>
  import("../../containers/AuditContainer")
);
const FilterAssignedContainer = loadable(() =>
  import("../../containers/FilterAssignedContainer")
);
const LabelManagementContainer = loadable(() =>
  import("../../containers/LabelManagementContainer")
);
const AdminWorkFlowContainer = loadable(() =>
  import("../../containers/AdminWorkFlowContainer")
);
const MyTaskContainer = loadable(() =>
  import("../../containers/MyTaskContainer")
);
const MyJobsContainer = loadable(() =>
  import("../../containers/MyJobsContainer")
);
const ManageJobTicketsContainer = loadable(() =>
  import("../../containers/ManageJobTicketsContainer")
);
const BrandConnectContainer = loadable(() =>
  import("../../containers/BrandConnectContainer")
);
const BrandInfoContainer = loadable(() =>
  import("../../containers/BrandInfoContainer")
);
const CampaignInfoContainer = loadable(() =>
  import("../../containers/CampaignInfoContainer")
);
const AccountDashboardContainer = loadable(() =>
  import("../../containers/AccountDashboardContainer.js")
);
const CometChatPanelWithState = loadable(() =>
  import("../CometChat/CometChatPanel")
);
const SettingsContainer = loadable(() =>
  import("../../containers/SettingsContainer")
);
const HoodProductAdvisorContainer = loadable(() =>
  import("../../containers/HoodProductAdvisorContainer")
);
const Builder = loadable(() => import("../formBuilder/Builder"));
const IntegrationContainer = loadable(() =>
  import("../../containers/IntegrationContainer")
);
// const KAMdashboardContainer = loadable(() =>
//   import("../../containers/KAMdashboardContainer")
// );
const OnBoardingFormContainer = loadable(() =>
  import("../../containers/OnBoardingFormContainer")
);

const StatusTransitionsContainer = loadable(() =>
  import("../../containers/StatusTransitionsContainer")
);
// const SocietyApprovalContainer = loadable(() => import("../SocietyApproval"));
const SocietyApprovalMobileScreen = loadable(() =>
  import("../SocietyApprovalMobile")
);
const BreUpdationContainer = loadable(() =>
  import("../BreUpdationContainer.js")
);
const MyMeetingContainer = loadable(() =>
  import("../../containers/MyMeetingContainer")
);
const SiteValidationPage = loadable(() =>
  import("../siteValidationPage/SiteValidationPage")
);
const CsvToTableContainer = loadable(() =>
  import("../../containers/CsvToTableContainer")
);
const IncentiveContainer = loadable(() =>
  import("../../containers/IncentiveContainer")
);
const InventoryKycContainer = loadable(() =>
  import("../../containers/InventoryKycContainer.js")
);
const RewardContainer = loadable(() =>
  import("../../containers/RewardContainer")
);
const LeadEventsContainer = loadable(() =>
  import("../../containers/LeadEventsContainer.js")
);
const RewardGenExecutionContainer = loadable(() =>
  import("../../containers/RewardGenExecutionContainer.js")
);
const VerifyBankAccountContainer = loadable(() =>
  import("../../containers/VerifyBankAccountContainer")
);

const SocialCRMContainer = loadable(() =>
  import("../../containers/SocialCRMContainer.js")
);
const SocialDashboardContainer = loadable(() =>
  import("../../containers/SocialDashboardContainer.js")
);
const SocialCustomer360Container = loadable(() =>
  import("../../containers/SocialCustomer360Container.js")
);

const ManageCPMSKycContainer = loadable(() =>
  import("../../containers/ManageCpmsKycContainer.js")
);
const SocialDetailsContainer = loadable(() =>
  import("../../containers/SocialDetailsContainer.js")
);

const DashboardLockContainer = loadable(() =>
  import("../../containers/DashboardLockContainer.js")
);

const IdeabookManageContainer = loadable(() =>
  import("../../components/IdeabookManageContainer.jsx")
);
const InventoryInvoiceContainer = loadable(() =>
  import("../../containers/InventoryInvoiceContainer.js")
);
function PrivateRoutes(props) {
  const pathName = props.history.location.pathname;
  const isSocialPage =
    pathName.includes("/social-tickets") ||
    pathName.includes("/social-dashboard") ||
    pathName.includes("/social-customer");
  const privatePage =
    pathName.includes("/society-payout-approvals") ||
    pathName.includes("/site-validation") ||
    pathName.includes("/inventory-kyc") ||
    pathName.includes("/cpms-kyc") ||
    isSocialPage;
  const searchParams = new URLSearchParams(window.location.search);
  const hasLeadId = searchParams.has("leadId");
  return (
    <PageFrame fromApprovals={privatePage}>
      <Consumer>
        {(control) => (
          <ModalControl>
            {(controlValue) => (
              <React.Fragment>
                <CometChatPanelWithState />
                {isSocialPage && (
                  <SocialCommonHeader
                    history={props.history}
                    openModal={controlValue?.openModal}
                    closeModal={controlValue?.closeModal}
                    notificationCount={control.notificationCount}
                    universalLabels={control.universalLabels}
                    profile={control.profile}
                    update={control.update}
                  />
                )}
                {!privatePage && (
                  <Header
                    history={props.history}
                    profile={control.profile}
                    update={control.update}
                    fullNavWindow={control.fullNavWindow}
                    universalLabels={control.universalLabels}
                    appConfiguration={control.appConfiguration}
                    notificationCount={control.notificationCount}
                    univiewConfig={control?.univiewConfig}
                    openModal={controlValue?.openModal}
                    closeModal={controlValue?.closeModal}
                  />
                )}
                {control.profile?.resource?.isDashboardLocked ? (
                  <>
                    {!privatePage && (
                      <Navigator history={props.history} {...props} />
                    )}
                    <>
                      {hasLeadId ? (
                        <Switch>
                          <Route
                            path="/"
                            exact={true}
                            component={DashboardContainer}
                          />
                          <Route
                            path="/lead-service"
                            exact={true}
                            component={ServiceDashboardContainer}
                          />
                          <Route
                            path="/sales-loan"
                            exact={true}
                            component={LoanDashboardContainer}
                          />
                          <Route
                            path="/service-loan"
                            exact={true}
                            component={LoanServiceDashboardContainer}
                          />
                        </Switch>
                      ) : (
                        <Switch>
                          <Route
                            path="/dashboard-lock"
                            exact={true}
                            component={DashboardLockContainer}
                          />
                          <Route
                            path="/tasks"
                            exact={true}
                            component={MyTaskContainer}
                          />
                          <Route
                            path="/tickets"
                            component={ManageTicketsContainer}
                          />
                          <Route
                            path="/meetings"
                            exact={true}
                            component={MyMeetingContainer}
                          />
                          <Route
                            path="/my-approval"
                            component={MyApprovalsContainer}
                          />
                        </Switch>
                      )}
                    </>
                    {/* <DashboardLockContainer /> */}
                  </>
                ) : (
                  <>
                    {!privatePage && (
                      <Navigator history={props.history} {...props} />
                    )}
                    <Switch>
                      <Route
                        path="/"
                        exact={true}
                        component={DashboardContainer}
                      />
                      <Route
                        path="/resource-tree"
                        exact={true}
                        component={ReasourceTreeContainer}
                      />
                      <Route
                        path="/resource"
                        exact={true}
                        component={ResourceContainer}
                      />
                      <Route
                        path="/partner"
                        exact={true}
                        component={PartnerContainer}
                      />
                      <Route path="/home" exact={true} component={Dashboard} />
                      <Route
                        path="/lead-import"
                        exact={true}
                        component={LeadImportContainer}
                      />
                      <Route
                        path="/job-role"
                        exact={true}
                        component={JobRoleContainer}
                      />
                      <Route
                        path="/manage-attribute"
                        exact={true}
                        component={ManageAttributeContainer}
                      />
                      <Route
                        path="/location"
                        component={SocietyLocation}></Route>
                      <Route
                        path="/lov-setup"
                        exact={true}
                        component={LovContainer}
                      />
                      <Route
                        path="/notification-management"
                        exact={true}
                        component={NotificationManagementContainer}
                      />
                      <Route
                        path="/service-feature-management"
                        exact={true}
                        component={ServiceFeatureContainer}
                      />
                      <Route
                        path="/brands-and-campaigns"
                        exact={true}
                        component={BrandConnectContainer}
                      />
                      <Route
                        path="/brand-connect"
                        exact={true}
                        component={BrandConnectContainer}
                      />
                      <Route
                        path="/calling-dashboard"
                        exact={true}
                        component={CallingDashboardContainer}
                      />
                      <Route
                        path="/geolocation"
                        exact={true}
                        component={GeolocationContainer}
                      />
                      <Route
                        path="/quotations"
                        exact={true}
                        component={QuotationContainer}
                      />
                      <Route
                        path="/templates"
                        exact={true}
                        component={TemplatesContainer}
                      />
                      <Route
                        path="/templates/draft-template"
                        exact={true}
                        component={TemplateEditor}
                      />
                      <Route
                        path="/templates/:template_id"
                        exact={true}
                        component={TemplateEditor}
                      />
                      <Route
                        path="/brands-and-campaigns/brands/:brand_id"
                        exact={true}
                        component={BrandInfoContainer}
                      />
                      <Route
                        path="/brands-and-campaigns/campaigns/:campaign_id"
                        exact={true}
                        component={CampaignInfoContainer}
                      />
                      <Route
                        path="/report-management"
                        exact={true}
                        component={ReportManagementContainer}
                      />
                      <Route
                        path="/document-management"
                        exact={true}
                        component={ManageDocumentsContainer}
                      />
                      <Route
                        path="/reports"
                        exact={true}
                        component={KibanaContainer}
                      />
                      <Route
                        path="/lead-service"
                        exact={true}
                        component={ServiceDashboardContainer}
                      />
                      <Route
                        path="/call-history"
                        exact={true}
                        component={CallLoginAdminContainer}
                      />
                      <Route
                        path="/audit"
                        exact={true}
                        component={AuditContainer}
                      />
                      <Route path="/inventory" component={InventoryContainer} />
                      <Route
                        path="/mydocument"
                        component={MyDocumentContainer}
                      />
                      <Route
                        path="/my-approval"
                        component={MyApprovalsContainer}
                      />
                      <Route
                        path="/tickets"
                        component={ManageTicketsContainer}
                      />
                      <Route
                        path="/tasks"
                        exact={true}
                        component={MyTaskContainer}
                      />
                      <Route path="/jobs" component={MyJobsContainer} />
                      <Route
                        path="/job-tickets"
                        exact={true}
                        component={ManageJobTicketsContainer}
                      />
                      <Route
                        path="/sales-loan"
                        exact={true}
                        component={LoanDashboardContainer}
                      />
                      <Route
                        path="/service-loan"
                        exact={true}
                        component={LoanServiceDashboardContainer}
                      />
                      <Route
                        path="/assign-filters"
                        exact={true}
                        component={FilterAssignedContainer}
                      />
                      <Route
                        path="/label-management"
                        exact={true}
                        component={LabelManagementContainer}
                      />
                      <Route
                        path="/admin-workflow"
                        exact={true}
                        component={AdminWorkFlowContainer}
                      />
                      <Route
                        path="/accounts"
                        exact={true}
                        component={AccountDashboardContainer}
                      />
                      <Route
                        path="/form-builder"
                        exact={true}
                        component={Builder}
                      />
                      <Route
                        path="/settings"
                        exact={true}
                        component={SettingsContainer}
                      />
                      <Route
                        path="/product-advisor/:lead_id"
                        exact={true}
                        component={HoodProductAdvisorContainer}
                      />
                      <Route
                        path="/integration"
                        exact={true}
                        component={IntegrationContainer}
                      />
                      <Route
                        path="/my-dashboard"
                        exact={true}
                        component={MyDashboardContainer}
                      />
                      <Route
                        path="/social-tickets"
                        exact={true}
                        component={SocialCRMContainer}
                      />
                      <Route
                        path="/onboarding-form"
                        exact={true}
                        component={OnBoardingFormContainer}
                      />
                      <Route
                        path="/integration/:integration_id"
                        exact={true}
                        component={IntegrationEditor}
                      />
                      <Route
                        path="/status-transition"
                        exact={true}
                        component={StatusTransitionsContainer}
                      />
                      {/* <Route
          path="/payout-approvals"
          exact={true}
          component={SocietyApprovalContainer}
        /> */}
                      <Route
                        path="/society-payout-approvals/:campaignId"
                        exact={true}
                        component={SocietyApprovalMobileScreen}
                      />
                      <Route
                        path="/manage-bre"
                        exact={true}
                        component={BreUpdationContainer}
                      />
                      <Route
                        path="/meetings"
                        exact={true}
                        component={MyMeetingContainer}
                      />
                      <Route
                        path="/site-validation/:id"
                        exact={true}
                        component={SiteValidationPage}
                      />
                      <Route
                        path="/:name/:id/:type/data-import"
                        exact={true}
                        component={CsvToTableContainer}
                      />
                      <Route
                        path="/incentive"
                        exact={true}
                        component={IncentiveContainer}
                      />
                      <Route
                        path="/rewards"
                        exact={true}
                        component={RewardContainer}
                      />
                      <Route
                        path="/lead-events"
                        exact={true}
                        component={LeadEventsContainer}
                      />
                      <Route
                        path="/reward-gen"
                        exact={true}
                        component={RewardGenExecutionContainer}
                      />
                      <Route
                        path="/inventory-kyc"
                        exact={true}
                        component={InventoryKycContainer}
                      />
                      <Route
                        path="/social-dashboard"
                        exact={true}
                        component={SocialDashboardContainer}
                      />
                      <Route
                        path="/verify-bank-account"
                        exact={true}
                        component={VerifyBankAccountContainer}
                      />
                      <Route
                        path="/verify-bank-account/:id"
                        exact={true}
                        component={VerifyBankAccountContainer}
                      />
                      <Route
                        path="/social-customer"
                        exact={true}
                        component={SocialCustomer360Container}
                      />
                      <Route
                        path="/social-tickets/:id"
                        exact={true}
                        component={SocialDetailsContainer}
                      />
                      <Route
                        path="/cpms-kyc/:id"
                        exact={true}
                        component={ManageCPMSKycContainer}
                      />
                      <Route
                        path="/dashboard-lock"
                        exact={true}
                        component={DashboardLockContainer}
                      />
                      <Route
                        path="/ideabook/:cxNbUserId/:leadName"
                        exact={true}
                        component={IdeabookManageContainer}
                      />
                      <Route
                        path="/manage-invoice"
                        exact={true}
                        component={InventoryInvoiceContainer}
                      />
                    </Switch>
                  </>
                )}
              </React.Fragment>
            )}
          </ModalControl>
        )}
      </Consumer>

      <Consumer>
        {(control) => (
          <React.Fragment>
            {control.profile && <MainContainer />}
          </React.Fragment>
        )}
      </Consumer>

      <PusherNotifications />
    </PageFrame>
  );
}

export default PrivateRoutes;
