import React from "react";
const ToastContext = React.createContext();
export class ToastProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toasts: [],
    };
  }

  addToast = (val) => {
    const toasts = [val, ...this.state.toasts];
    this.setState({ toasts });
  };
  componentDidUpdate() {}
  render() {
    return (
      <ToastContext.Provider
        value={{
          addToast: this.addToast,
          ...this.state,
        }}
      >
        {this.props.children}
      </ToastContext.Provider>
    );
  }
}
export class ToastConsumer extends React.Component {
  render() {
    return (
      <ToastContext.Consumer>
        {(toasts) => this.props.children(toasts)}
      </ToastContext.Consumer>
    );
  }
}
