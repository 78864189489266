import loadable from "@loadable/component";
import React from "react";
import { Consumer } from "../../containers/Provider";
import { ModalControl } from "../common/modal/ModalRoot";
// import SalesPitchModal from "../SalesPinch/SalesPitchModal";
import "./CallPanel.scss";
const LoansTools = loadable(() => import("../LoansTools"));
import Toast from "../common/Toasts/Toast";
import ModalRightPanel from "../common/modal/ModalRightPanel";
import { getNotificationsApi } from "../../api/reports";

export default function CallPanel(ownProps) {
  return (
    <Consumer>
      {(props) => (
        <ModalControl>
          {(control) => (
            <CallPanelWithState
              {...ownProps}
              account={props.profile?.resource}
              modal={control.modal}
              openModal={control.openModal}
              closeModal={control.closeModal}
              update={props.update}
              salesPitchHideVisibility={props.salesPitchHideVisibility}
              nbUserIdSocials={props.nbUserIdSocials}
            />
          )}
        </ModalControl>
      )}
    </Consumer>
  );
}
class CallPanelWithState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showIframe: false,
      notificationCount: 0,
    };
  }
  showError = (error) => {
    this.toastRef?.current?.addToast({
      type: "error",
      message: error ? error : "Something Went Wrong ",
    });
  };

  handleIframeToggle = () => {
    const iframeSrc = `https://socialhub.nobroker.in/social-dashboard-plugin/${
      this.props.nbUserIdSocials
        ? this.props.nbUserIdSocials
        : "8a9fc48282380801018238a221aa203311"
    }`;

    this.props.openModal(
      <ModalRightPanel width="90%">
        <iframe
          styleName="iframe"
          title="Socials"
          id="callingCrmIframe"
          src={iframeSrc}
        />
      </ModalRightPanel>
    );
  };

  getNotificationCount = async () => {
    const result = await getNotificationsApi();
    if (result?.status == 1) {
      this.setState({
        notificationCount:
          result?.data > 1000
            ? `${(result?.data / 1000).toFixed(1)}k`
            : result?.data,
      });
    }
  };

  getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  componentDidMount() {
    const SocialAccessToken = this.getCookie("accessToken");
    localStorage.setItem(
      "SocialAccessToken",
      JSON.stringify(SocialAccessToken)
    );
    this.getNotificationCount();
  }

  // salesPinch = () => <SalesPitchModal />;
  render() {
    const loanToolsRoutes = ["/sales-loan", "/service-loan"];
    return (
      <div styleName={this.state.open ? "open" : "base"}>
        {loanToolsRoutes?.includes(window.location.pathname) && <LoansTools />}
        <div
          styleName="panel-open"
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}></div>
        {/* <div
          styleName="sales-pitch"
          onClick={() => {
            if (!this.props.salesPitchHideVisibility) {
              if (!this.props.modal) {
                this.props.openModal(this.salesPinch());
              } else {
                this.props.closeModal();
              }
            } else {
              this.props.update({
                salesPitchHideVisibility: !this.props.salesPitchHideVisibility,
              });
            }
          }}></div> */}
        <div
          styleName="sales-pitch"
          onClick={() => {
            this.handleIframeToggle();
          }}
          style={{ position: "absolute" }}>
          <div styleName="notification-count">
            {this.state.notificationCount}
          </div>
        </div>

        {/* <iframe
          styleName="iframe"
          title="SLASH"
          src=""
          id="callingCrmIframe"
          src="chrome-extension://gdddjgleohekncgbiahjhgcmipjfhlmc/index.html?requestOrigin=https://www.nobroker.in/admin/home-service/sales"
        /> */}
        {/* <div id="" */}
        <div id="callingScreenSidebar"></div>
        <Toast ref={this.toastRef} />
      </div>
    );
  }
}
