import React from "react";
import Notifications from "../components/Notifications";
import {
  getNotificationCount,
  getNotifications,
  readNotification,
} from "../api/notifications";
import { Consumer } from "./Provider";

export default class NotificationContainer extends React.Component {
  render() {
    return (
      <Consumer>
        {(props) => (
          <WithState
            notificationCount={props.notificationCount}
            update={props.update}
            notifications={props.notifications}
            closeModal={this.props.closeModal}
            showToast={this.props.showToast}
            universalLabels={props.universalLabels}
          />
        )}
      </Consumer>
    );
  }
}

class WithState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      totalPages: null,
      readNotifications: [],
      loading: false,
      eventType: "",
      read: false,
    };
  }

  getNotificationCount = async () => {
    const result = await getNotificationCount();
    if (result.status === 1) {
      this.props.update({
        notificationCount: result.data,
      });
    }
  };
  getNotifications = async (eventType = "", read = false) => {
    this.setState({ loading: true });
    if (this.state.page === 0) {
      const result = await getNotifications(this.state.page, eventType, read);
      this.setState({ loading: false, eventType: eventType, read: read });
      if (result.status === 1) {
        this.props.update({
          notifications: result.data,
        });
      }
    } else {
      const result = await getNotifications(
        this.state.page,
        eventType ? eventType : this.state.eventType,
        read ? read : this.state.read
      );
      this.setState({ loading: false });
      if (result.status === 1) {
        let oldData = this.props.notifications;
        if (result.data.length > 0) {
          oldData = [...this.props.notifications, ...result.data];
          this.props.update({
            notifications: oldData,
          });
        }
      }
    }
  };
  filterNotifications = (eventType = "", read = false) => {
    this.props.update({ notifications: null });
    this.setState({ page: 0 }, () => {
      this.getNotifications(eventType, read);
    });
  };
  lazyLoad = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.getNotifications();
    });
  };
  readNotification = async (id) => {
    this.setState({ loading: true });
    const result = await readNotification({
      id,
      read: true,
    });
    this.setState({ loading: false });
    if (result && result.status === 1) {
      let readNotifications = this.state.readNotifications;
      readNotifications.push(id);
      this.setState({ readNotifications });
      this.getNotificationCount();
    }
  };
  componentDidMount() {
    this.getNotificationCount();
    this.getNotifications();
  }
  render() {
    return (
      <Notifications
        getNotifications={this.getNotifications}
        getNotificationCount={this.getNotificationCount}
        count={this.props.notificationCount}
        notifications={this.props.notifications}
        read={this.readNotification}
        readNotifications={this.state.readNotifications}
        filterNotifications={this.filterNotifications}
        lazyLoad={this.lazyLoad}
        loading={this.state.loading}
        page={this.state.page}
        closeModal={this.props.closeModal}
        showToast={this.props.showToast}
        universalLabels={this.props.universalLabels}
      />
    );
  }
}
