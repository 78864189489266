import React, { Component } from "react";
import "./ContextPopup.scss";
import DropDownBase from "../common/core/DropDownBase";
import SelectMultipleOption from "../common/dropdown/SelectMultipleOption";
import Input from "../common/Input";
// import down from "../images/down-arrow.svg";
export default class CustomPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }
  handleChange = (val) => {
    this.setState({ value: val });
    if (this.props.onSearch) {
      this.props.onSearch(val);
    }
  };
  componentDidMount() {
    this.props.callBackFn && this.props.callBackFn();
  }
  render() {
    return (
      <DropDownBase
        onSelect={this.props.onSelect}
        selected={this.props.selected}
        open={this.props.multiple}
        multiSelect={this.props.multiple}
        render={(props) => (
          <div
            styleName={`dropdown ${props.dropDownVisible ? "open" : ""}`}
            style={{ width: this.props.width }}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.disabled ? null : props.handleDropDown();
              }}>
              {this.props.children}
            </div>
            {/* <div
              styleName="more"
              onClick={(e) => {
                e.stopPropagation();
                this.props.disabled ? null : props.handleDropDown();
              }}></div> */}
            {this.props.multiple ? (
              <React.Fragment>
                {props.dropDownVisible && (
                  <div
                    styleName={
                      this.props.dropDownLeftAlign
                        ? "dropdown-menu left"
                        : "dropdown-menu"
                    }
                    style={{
                      minWidth: "230px",
                    }}>
                    {this.props.showSearch && (
                      <div>
                        <Input
                          placeholder={"Search"}
                          value={
                            this.props.disabled
                              ? this.props.value
                              : this.state.value
                          }
                          disabled={this.props.disabled}
                          autoFocus={true}
                          onFocus={this.props.onFocus}
                          onChange={(val) => this.handleChange(val)}
                          id={this.props.id + "input"}
                        />
                      </div>
                    )}
                    {this.props.ascIcon}
                    <SelectMultipleOption
                      onSelect={(val) => {
                        props.handleSelect(val);
                      }}
                      showCheckbox={this.props.showCheckbox}
                      selected={this.props.selected}
                      options={this.props.options}
                      showDetails={this.props.showDetails}
                      filter={this.props.filter}
                      searchValue={this.state.value}
                      sendEntireValue={this.props.sendEntireValue}
                      disableUnSelect={this.props.disableUnSelect}
                      isCustomPopup={true}
                      showFooterButton={this.props.showFooterButton}
                      buttonLabel={this.props.buttonLabel}
                      onButtonClick={this.props.onButtonClick}
                      buttonDisable={this.props.buttonDisable}
                      buttonColor={this.props.buttonColor}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {props.dropDownVisible && (
                  <React.Fragment>
                    <ul
                      styleName="dropdown-menu"
                      style={{ minWidth: this.props.minWidth }}>
                      {this.props.showSearch && (
                        <div>
                          <Input
                            placeholder={"Search"}
                            value={
                              this.props.disabled
                                ? this.props.value
                                : this.state.value
                            }
                            disabled={this.props.disabled}
                            autoFocus={true}
                            onFocus={this.props.onFocus}
                            onChange={(val) => this.handleChange(val)}
                            id={this.props.id + "input"}
                          />
                        </div>
                      )}
                      {this.props.ascIcon}
                      {this.props.customUI &&
                        this.props.customUI(props.handleDropDown)}
                      {this.props.showSearch ? (
                        <div styleName="dropdown-overflow">
                          {this.props.options &&
                            this.props.options.map((val, i) => {
                              if (
                                val &&
                                val.label
                                  ?.toLowerCase()
                                  ?.includes(this.state.value?.toLowerCase())
                              ) {
                                return (
                                  <div
                                    key={i}
                                    styleName={"custom-options"}
                                    style={{
                                      paddingLeft:
                                        this.props.selected !== val.value &&
                                        "30px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      props.handleSelect(val);
                                    }}>
                                    {this.props.selected === val.value && (
                                      <div styleName="checkMark" />
                                    )}{" "}
                                    {val.label}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      ) : (
                        <div>
                          {this.props.options &&
                            this.props.options.map((val, i) => {
                              if (val) {
                                return (
                                  <div
                                    key={i}
                                    styleName={"custom-options"}
                                    style={{
                                      paddingLeft: this.props.noPadding
                                        ? "8px"
                                        : this.props.selected !== val.value &&
                                          "30px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      props.handleSelect(val);
                                    }}>
                                    {this.props.selected === val.value && (
                                      <div styleName="checkMark" />
                                    )}{" "}
                                    {val.label}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      )}
                    </ul>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      />
    );
  }
}
CustomPopUp.defaultProps = {
  showIcon: true,
};
