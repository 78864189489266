export const importScript = (url, callback) => {
  const script = document.createElement("script");
  script.src = url;
  script.defer = true;
  document.body.appendChild(script);
  script.onload = () => {
    if (typeof callback === "function") callback();
  };
};
export const handleChromeRuntimeFn = (profileId, tenant = "") => {
  if (profileId && tenant) {
    try {
      // eslint-disable-next-line no-undef
      chrome?.runtime?.sendMessage("fkpoipoabekjghhplpafbpipfbofehah", {
        type: "auth",
        uuID: profileId,
        source: tenant,
      });
    } catch (error) {
      console.log("error", error);
    }
  }
};

export const loadGtm = (callback) => {
  const existingScript = document.getElementById("gtmTagEvent");
  if (!existingScript) {
    const script = document.createElement("script");
    script.id = "gtmTagEvent";
    script.defer = true;
    const scriptText = document.createTextNode(
      `var interval = setInterval(function() {
				if (document && document.readyState === 'complete') {
					clearInterval(interval);
				  (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NDNFVM5");}
			    }, 100);`
    );
    script.appendChild(scriptText);
    document.body.appendChild(script);
    setTimeout(() => {
      if (typeof callback === "function") callback();
    }, 100);
  }
};
