import React from "react";
import "./Button.scss";

export default function Button(props) {
  switch (props.type) {
    case "primary":
      return (
        <div
          styleName={props.loading ? "primary-loading" : "primary"}
          onClick={props.onClick}
          style={{
            width: props.width,
            fontSize: props.fontSize,
            height: props.height,
            ...props.styles,
          }}
          tabIndex="0"
          onKeyUp={(event) =>
            event.key === "Enter" && props.onClick && props.onClick()
          }>
          {props.label}
        </div>
      );
    case "secondary":
      return (
        <div
          styleName={props.loading ? "secondary-loading" : "secondary"}
          onClick={!props.disable && !props.loading && props.onClick}
          style={{
            width: props.width,
            fontSize: props.fontSize,
            height: props.height,
            opacity: props.disable && "0.5",
            fontWeight: props.fontFamily === "Regular" ? 400 : props.fontFamily,
            borderColor: props.color,
            color: props.color,
            backgroundColor: props.backgroundColor,
            cursor: props.disable && "not-allowed",
            ...props.styles,
          }}
          tabIndex="0"
          onKeyUp={(event) =>
            event.key === "Enter" && props.onClick && props.onClick()
          }>
          {props.label}
        </div>
      );
    case "tertiary":
      return (
        <div
          styleName={props.loading ? "tertiary-loading" : "tertiary"}
          onClick={props.onClick}
          style={{
            width: props.width,
            fontSize: props.fontSize,
            height: props.height,
            ...props.styles,
          }}
          tabIndex="0"
          onKeyUp={(event) =>
            event.key === "Enter" && props.onClick && props.onClick()
          }>
          {props.label}
        </div>
      );
    case "quaternary":
      return (
        <div
          styleName={props.loading ? "quaternary-loading" : "quaternary"}
          onClick={props.onClick}
          style={{
            width: props.width,
            fontSize: props.fontSize,
            height: props.height,
            padding: props.padding,
            ...props.styles,
          }}
          tabIndex="0"
          onKeyUp={(event) =>
            event.key === "Enter" && props.onClick && props.onClick()
          }>
          {props.label}
        </div>
      );
    default:
      return (
        <div
          styleName={props.loading ? "button-loading" : "button"}
          onClick={!props.disable && !props.loading && props.onClick}
          style={{
            width: props.width,
            fontSize: props.fontSize,
            height: props.height,
            backgroundColor: props.color,
            opacity: props.disable && "0.5",
            padding: props.padding,
            margin: props.margin,
            cursor: props.disable && "not-allowed",
            ...props.styles,
          }}
          tabIndex="0"
          onKeyUp={(event) =>
            event.key === "Enter" && props.onClick && props.onClick()
          }>
          {props.label}
        </div>
      );
  }
}
