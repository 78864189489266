import React, { useState } from "react";
import "./Textarea.scss";

export default function Textarea(props) {
  const [focused, handleFocus] = useState(false);
  const active = focused || props.value;
  const handleChange = (val) => props.onChange(val.target.value);
  const handleBlur = (bool) => {
    if (props.onBlur) {
      props.onBlur();
    }
    handleFocus(bool);
  };
  return (
    <React.Fragment>
      <div
        styleName={
          active
            ? props.hollow
              ? props.error
                ? "base-hollow-error"
                : "base-hollow"
              : props.error
              ? "base-active-error"
              : "base-active"
            : props.hollow
            ? props.error
              ? "base-hollow-error"
              : "base-hollow"
            : props.error
            ? "base-error"
            : "base"
        }
        style={{ height: props.height ? "100%" : "" }}>
        <div styleName="content">
          <textarea
            rows={props.rows}
            styleName="input-actual"
            value={props.value || ""}
            disabled={props.disabled}
            onBlur={() => handleBlur(false)}
            onFocus={() => handleFocus(true)}
            onChange={handleChange}
            maxLength={props.maxLength}>
            {props.value}
          </textarea>
          <div styleName={active ? "placeholder-active" : "placeholder"}>
            <div
              styleName={
                props.disabled ? "placeholder-disabled" : "placeholder-text"
              }>
              {props.placeholder}
            </div>
          </div>
          {props.maxLength && (
            <div styleName="character-count">
              {props.value?.length || "0"}/{props.maxLength || 200}
            </div>
          )}
        </div>
      </div>
      {props.error && (
        <div styleName="error-help">{props.errorMsg || props.error}</div>
      )}
    </React.Fragment>
  );
}
Textarea.defaultProps = {
  rows: "6",
  maxLength: 2000,
};
