import React from "react";
import { ModalControl } from "./ModalRoot";
import "./ModalPanel.scss";

export default class ModalPanel extends React.Component {
  constructor(props) {
    super(props);
    this.mydivRef = React.createRef();
  }
  componentDidMount() {
    if (this.mydivRef && this.props.drag) {
      this.dragElement(this.mydivRef);
    }
  }
  dragElement = (elmnt) => {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    if (this.props.headerRef) {
      /* if present, the header is where you move the DIV from:*/
      this.props.headerRef.current.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.current.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.current.style.top = elmnt.current.offsetTop - pos2 + "px";
      elmnt.current.style.left = elmnt.current.offsetLeft - pos1 + "px";
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  };
  render() {
    return (
      <div styleName="base" style={{ ...this.props.baseStyles }}>
        <ModalControl>
          {(modal) => (
            <div
              styleName="panel"
              style={{ ...this.props.panelStyles }}
              onClick={() => {
                if (this.props.onClose) {
                  this.props.onClose();
                }
                if (!this.props.disablePanelClose) modal.closeModal();
              }}></div>
          )}
        </ModalControl>
        <div
          styleName={
            this.props.animation
              ? "animation-window"
              : this.props.fullWindow
              ? "full-window"
              : "window"
          }
          style={{
            width: this.props.width,
            overflow: this.props.overflow,
            borderRadius: this.props.borderRadius,
            ...this.props.windowStyles,
          }}
          ref={this.mydivRef}>
          {/* <div styleName="header">
            {this.props.header}
            <ModalControl>
              {modal => (
                <div
                  styleName="cross"
                  onClick={() => {
                    if (this.props.onClose) {
                      this.props.onClose();
                    }
                    modal.closeModal();
                  }}
                ></div>
              )}
            </ModalControl>
          </div> */}
          <div style={{ height: this.props.height }}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
