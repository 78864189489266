import moment from "moment";
import aes from "./aes";
import CryptoJS, { MD5 } from "crypto-js";
import { CRYPTOSECRETKEY } from "./constant";
import { COUNTRY_TIME_ZONES } from "./timeZones";

export function objectToArray(object) {
  if (object) {
    return Object.keys(object).map((val) => {
      return { value: val, label: object[val] };
    });
  } else return [];
}
export function objectToArray2(object) {
  if (object) {
    return Object.keys(object).map((val) => {
      return { value: object[val], label: val };
    });
  } else return [];
}
export function objectToArrayTeamList(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].taskTitle,
      data: object[val],
    };
  });
}
export function objectToArrayMeetingList(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].meetingTitle,
      data: object[val],
    };
  });
}
export function objectToArrayLabel(object) {
  if (object) {
    return Object.keys(object).map((val) => {
      return { value: object[val], label: object[val] };
    });
  } else return [];
}
export function unixMillisecondsToLocalDateTime(time) {
  const d = new Date(time);
  const dateArray = d.toDateString().split(" ");
  const date = `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`;
  const formattedTime = d.toTimeString();
  let splitTime = formattedTime.split(":");
  let hour = splitTime[0] > 12 ? splitTime[0] - 12 : splitTime[0];
  let minute = splitTime[1];
  let Ampm = splitTime[0] > 11 ? "Pm" : "Am";
  const shortHandTime = hour + ":" + minute + ":" + Ampm;
  return date + " " + shortHandTime;
}
export function unixMillisecondsToDateTime(time) {
  const d = new Date(time);
  const dateArray = d.toUTCString().split(" ");
  const date = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`;
  const formattedTime = dateArray[4];
  let splitTime = formattedTime.split(":");
  let hour = splitTime[0] > 12 ? splitTime[0] - 12 : splitTime[0];
  let minute = splitTime[1];
  let Ampm = splitTime[0] > 11 ? "Pm" : "Am";
  const shortHandTime = hour + ":" + minute + ":" + Ampm;
  return date + " " + shortHandTime;
}
export function getTime(time) {
  const d = new Date(time);
  const formattedTime = d.toUTCString().split(" ")[4];
  let splitTime = formattedTime.split(":");
  let hour = splitTime[0] > 12 ? splitTime[0] - 12 : splitTime[0];
  let minute = splitTime[1];
  let Ampm = splitTime[0] > 12 ? "Pm" : "Am";
  const shortHandTime = hour + ":" + minute + " " + Ampm;
  return shortHandTime;
}
export function getDate(time) {
  const d = new Date(time);
  const dateArray = d.toUTCString().split(" ");
  const date = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`;
  return date;
}

export function formatedDateTimeForReq(date, time, defaultValue) {
  const minute =
    time && new Date(time).getMinutes() > 0
      ? new Date(time).getMinutes() > 9
        ? new Date(time).getMinutes()
        : "0" + new Date(time).getMinutes()
      : "00";
  const hour =
    time && new Date(time).getHours() > 9
      ? new Date(time).getHours()
      : "0" + new Date(time).getHours();
  let formattedTime =
    moment(date).format("YYYY-MM-DD") +
    "T" +
    (time
      ? `${hour + ":" + minute + ":00"}`
      : defaultValue
      ? defaultValue
      : "23:59:59");
  return formattedTime;
}
export function formateDateForFilterReq(date) {
  let formattedDate = moment(date).format("YYYY-MM-DD");
  return formattedDate;
}
export function formateDateAndTimeForFilterReq(date) {
  let formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
  return formattedDate;
}

export function getDateForApi(time) {
  const d = new Date(time).toUTCString();
  const formattedTime = d.split(" ");
  let splitTime = formattedTime[4];
  let str =
    formattedTime[3] +
    " " +
    formattedTime[1] +
    ",  " +
    formattedTime[2] +
    " " +
    splitTime;
  const date = new Date(`${str}`);
  return date;
}
export function getUtcDateForApi(time) {
  const d = new Date(time).toString();
  const formattedTime = d.split(" ");
  let splitTime = formattedTime[4];
  let str =
    formattedTime[3] +
    " " +
    formattedTime[1] +
    ",  " +
    formattedTime[2] +
    " " +
    splitTime;
  const date = new Date(`${str}`);
  return date;
}
export function getTimeForApi(time) {
  const d = new Date(time);
  const dateArray = d.toUTCString().split(" ");
  const formattedTime = dateArray[4];
  return formattedTime;
}
export function checkCurrentDate(date) {
  const todayDate = moment(new Date().toUTCString()).format("YYYY-MM-DD");
  const existingDate = moment(getDateForApi(date).toUTCString()).format(
    "YYYY-MM-DD"
  );
  if (todayDate == existingDate) {
    return true;
  }
  return false;
}
export function checkTodayDate(date) {
  const todayDate = new Date().toDateString();
  const existingDate = new Date(date).toDateString();
  if (todayDate === existingDate) {
    return true;
  }
  return false;
}

export function checkDifferenceOfDates(date) {
  const todayDate = new Date();
  const dueDate = new Date(date);
  const utc1 = Date.UTC(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate()
  );
  const utc2 = Date.UTC(
    dueDate.getFullYear(),
    dueDate.getMonth(),
    dueDate.getDate()
  );
  let difference = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
  if (difference < 0) {
    return true;
  }
  return false;
}
export function checkTomorrow(date) {
  const todayDate = new Date(Date.now() + 3600 * 1000 * 24).toDateString();
  const existingDate = new Date(date).toDateString();
  if (todayDate === existingDate) {
    return true;
  }
  return false;
}
export function checkTomorrowDate(date) {
  const todayDate = new Date().toDateString() + 1;
  const existingDate = new Date(date).toDateString() + 1;
  if (todayDate === existingDate) {
    return true;
  }
  return false;
}
export function checkYesterdayDate(date) {
  var prev_date = new Date();
  prev_date.setDate(prev_date.getDate() - 1);
  const yesterdayDate = new Date(prev_date).toDateString();
  const existingDate = new Date(date).toDateString();
  if (yesterdayDate === existingDate) {
    return true;
  }
  return false;
}
export function getTodaysData(data, type) {
  let result = null;
  if (type == "TASK") {
    result =
      data &&
      data.length > 0 &&
      data.filter((val) => {
        if (
          checkCurrentDate(val.taskDueDate) &&
          val.taskStatus != "closed" &&
          val.taskStatus != "task_cancelled"
        ) {
          return val;
        }
      });
  } else {
    result =
      data &&
      data.length > 0 &&
      data.filter((val) => {
        if (
          checkCurrentDate(val.startDate) &&
          val.meetingStatus != "cancelled"
        ) {
          return val;
        }
      });
  }
  return result;
}
export function objectToArrayList(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].name ? object[val].name : object[val].roleName,
      data: object[val],
    };
  });
}
export function objectToArrayResourceList(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].partyName
        ? object[val].partyName
        : object[val].roleName,
      data: object[val],
    };
  });
}
export function objectToArrayParty(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].partyName
        ? object[val].partyName
        : object[val].firstName,
      data: object[val],
    };
  });
}
export function objectToArrayTicketList(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].ticketId,
      data: object[val],
    };
  });
}
export function objectToArrayDisplayValue(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].code,
      label: object[val].displayValue,
      data: object[val],
    };
  });
}
export function objectToArrayListOfEmail(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].primaryEmailAddress,
      data: object[val],
    };
  });
}
export function objectToArrayManageResource(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].primaryEmailAddress,
      data: object[val],
    };
  });
}
export function objectToArrayLOV(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].lovName,
      data: object[val],
    };
  });
}
export function getUtcDate(time) {
  if (time) {
    let date = new Date(time);
    return date;
  } else {
    return null;
  }
}
export function strToDate(dtStr) {
  if (!dtStr) return null;
  let dateParts = dtStr?.split("-");
  let timeParts = dateParts[2]?.split(" ")[1].split(":");
  dateParts[2] = dateParts[2]?.split(" ")[0];
  // month is 0-based, that's why we need dataParts[1] - 1
  // eslint-disable-next-line no-undef
  let dateObject = new Date(
    +dateParts[2],
    dateParts[1] - 1,
    +dateParts[0],
    timeParts[0],
    timeParts[1],
    timeParts[2]
  );
  return dateObject;
}
export function creatKeyValueLov(lov) {
  let obj = {};

  lov &&
    lov.length > 0 &&
    lov.map((val) => {
      obj[val.code] = val.displayValue;
    });
  return obj;
}
export function arrayToOptionValue(lov) {
  let obj = {};

  lov &&
    lov.length > 0 &&
    lov.map((val) => {
      obj[val.key] = val.value;
    });
  return obj;
}
export function createKeyValueofArray(lov) {
  if (lov && lov.length > 0) {
    let result = lov.map((val) => ({
      label: val.displayValue,
      value: val.code,
    }));
    return result;
  }
}

export function creatKeyValueAttributes(lov) {
  let obj = {};
  lov &&
    lov.length > 0 &&
    lov.map((val) => {
      obj[val.code] = val;
    });
  return obj;
}
export function creatKeyStatusValueLov(lov) {
  let obj = {};

  lov &&
    lov.length > 0 &&
    lov.map((val) => {
      obj[val.statusCode] = val.statusDisplayName;
    });
  return obj;
}
export function objectToArrayAttributes(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].code,
      label: object[val].label,
      data: object[val],
    };
  });
}

export function decry(length, id) {
  let l = 0;
  let bp = "";

  if (id) {
    bp = window.atob(id);
  }
  if (length) {
    l = parseInt(length);
  }

  return { l, bp };
}
export function decryD(val) {
  if (val.entity) {
    const data = val.entity;
    const reqLength = val.length;
    const guid = val.unvKey;
    const decryptKey = decry(reqLength, guid);
    const decryp = aes[
      eval(
        "[(window.atob('YXNkbGhBREpIQUxLZGhMQUlIV0lIYURKYmRxd0FTREhBU0pESA=='))]"
      )
    ](data, decryptKey);

    const final = Object.assign(val, { data: decryp });

    return final;
  } else {
    return val;
  }
}
export function utcDate(date) {
  return moment(date, "YYYY-MM-DDTHH:mm").utc();
}
export function formatedUtcDateTimeForReq(date, time) {
  const minute =
    time && new Date(time).getMinutes() > 0
      ? new Date(time).getMinutes() > 9
        ? new Date(time).getMinutes()
        : "0" + new Date(time).getMinutes()
      : "00";
  const hour =
    time && new Date(time).getHours() > 9
      ? new Date(time).getHours()
      : "0" + new Date(time).getHours();
  let formattedTime =
    moment(date).format("YYYY-MM-DD") +
    "T" +
    (time ? `${hour + ":" + minute + ":00"}` : "23:59:59");
  return moment(formattedTime, "YYYY-MM-DDTHH:mm").utc();
}
export function checkImageInURL(url) {
  return url?.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) != null;
}
export function checkHtmlInURL(url) {
  return url.match(/\.(html)$/) != null;
}
export function getInitials(name) {
  if (name) {
    const array = name?.split(" ").slice(0, 2);
    return array.map((val) => val.split("")[0]);
  }
}

export function numDimension(value, currency_symbol = "₹ ") {
  if (currency_symbol === "₹ ") {
    let val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000) {
      val = (val / 1000).toFixed(2) + " K";
    }
    return (currency_symbol ? currency_symbol : "₹ ") + val;
  } else {
    if (value >= 1000000000) {
      // Convert number to billions with one decimal place and add "B" suffix
      return (
        (currency_symbol ? currency_symbol : "$ ") +
        (value / 1000000000).toFixed(1) +
        "B"
      );
    } else if (value >= 1000000) {
      // Convert number to millions with one decimal place and add "M" suffix
      return (
        (currency_symbol ? currency_symbol : "$ ") +
        (value / 1000000).toFixed(1) +
        "M"
      );
    } else if (value >= 1000) {
      // Convert number to thousands with no decimal places and add "K" suffix
      return (
        (currency_symbol ? currency_symbol : "$ ") +
        Math.floor(value / 1000) +
        "K"
      );
    } else {
      // Format number with commas and return
      return (
        (currency_symbol ? currency_symbol : "$ ") + value?.toLocaleString()
      );
    }
  }
}
export function currencyFormatter(x) {
  if (isNaN(x)) return "NA";
  x = x.toString();
  let afterPoint = "";
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
  x = Math.floor(x);
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  let res =
    "₹" +
    " " +
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    lastThree +
    afterPoint;

  return res;
}
export function monthsToyears(months) {
  let dur1 = Math.floor(months / 12);
  let dur2 = months / 12 - dur1;
  let dur3 = Math.floor(dur2 * 12);
  return dur1 + " years and " + dur3 + " months";
}
export function momentFormat(data, format) {
  if (data) {
    return moment(data).format(format);
  } else {
    return "";
  }
}
export function lovCodeClean(text) {
  if (text) {
    const words = text.split("_");
    const capitalizedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
      } else {
        return word.charAt(0).toLowerCase() + word.slice(1)?.toLowerCase();
      }
    });
    return capitalizedWords.join(" ");
  }
}
export function debounce(func, wait, immediate) {
  var timeout;
  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
export function objectToLocationArray(array) {
  return array?.map((val) => {
    return {
      value: val.place_id,
      label: val.name || val.description,
    };
  });
}
export function objectToArrayLeadList(object) {
  return Object.keys(object).map((val) => {
    return { value: object[val].id, label: object[val].leadName };
  });
}
export function checkObjectLength(object) {
  return Object.keys(object)?.length;
}
export function checkPdfInURL(url) {
  return url.match(/\.(pdf)$/) != null;
}
export const convertToReadableTime = (timeSec) => {
  if (timeSec && timeSec > 0) {
    if (timeSec < 60) {
      return `${timeSec} seconds`;
    }
    if (timeSec < 3600) {
      let timeData = `${parseInt(timeSec / 60)} minute`;
      if (timeSec % 60 !== 0) {
        timeData += `${timeSec % 60} seconds`;
      }
      return timeData;
    }
    let timeData = `${parseInt(timeSec / 3600)} hour`;
    if (timeSec - 36000 !== 0) {
      timeData += `${(timeSec - 36000) / 60}  minute`;
    }
    return timeData;
  }
  return "Not Answered";
};
export const DisplayCurrentTime = (timeStamp) => {
  const date = new Date(timeStamp);
  let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const amPm = date.getHours() >= 12 ? "PM" : "AM";
  hours = hours < 10 ? `0 ${hours}` : hours;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const time = `${hours}:${minutes} ${amPm}`;
  return time;
};
export const checkIsMobileDevice = (req) => {
  // to check in csr
  if (typeof window !== "undefined") {
    return window.innerWidth < 768;
  }
  if (
    req.headers &&
    (req.headers["cloudfront-is-mobile-viewer"] === true ||
      req.headers["cloudfront-is-mobile-viewer"] === "true")
  ) {
    return true;
  }
  if (
    req.headers &&
    (req.headers["cloudfront-is-desktop-viewer"] === true ||
      req.headers["cloudfront-is-desktop-viewer"] === "true")
  ) {
    return false;
  }
  const ua = req.headers ? req.headers["user-agent"] : req.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Opera Mobi|symbian|treo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile/i.test(
    ua
  );
};
export function createCustomKeyValueLov(lov, attributeOne, attributeTwo) {
  let obj = {};
  lov &&
    lov.length > 0 &&
    lov.filter((val) => {
      if (attributeOne in val) {
        obj[val[attributeTwo]] = val[attributeOne];
      }
    });
  return obj;
}
export function convertMeterToKm(meter) {
  let km = meter / 1000;
  return km.toFixed(1) + " km";
}
export function convertSecondsToMin(duration) {
  let min = duration / 60;
  return min.toFixed(1) + " Min";
}
export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k";
  } else if (num > 1000000) {
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M";
  } else if (num < 1000) {
    return Math.sign(num) * Math.abs(num);
  }
}
export function toDollarFormat(number) {
  // Convert number to a string with two decimal places
  const formattedNumber = Number(number).toFixed(0);

  // Add commas every three digits
  const parts = formattedNumber.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Add a dollar sign at the beginning and return the formatted string
  return parts.join(".");
}
export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0)?.toUpperCase() + string.slice(1)?.toLowerCase();
  } else {
    return "";
  }
}
export function creatKeyValueLovCity(lov) {
  let obj = {};

  lov &&
    lov.length > 0 &&
    lov.map((val) => {
      obj[val.id] = val.name;
    });
  return obj;
}
export function noOfDaysBetweenTwoTimeStamps(date1, date2) {
  let diff = date2 - date1;
  let dayDiff = diff / (1000 * 60 * 60 * 24);
  return dayDiff;
}

export function objectToArrayListDutyRole(object) {
  return Object.keys(object).map((val) => {
    return {
      value: object[val].id,
      label: object[val].name ? object[val].name : object[val].dutyName,
      data: object[val],
    };
  });
}

export function checkBusinessValue(settings) {
  let loan = false;

  if (
    settings?.filter(
      (val) =>
        val.setting === "CRM_TYPE" &&
        (val.settingValue === "loan" || val.settingValue === "LOAN")
    )?.length > 0
  ) {
    loan = true;
    return loan;
  } else {
    loan = false;
    return loan;
  }
}
export function arrayToArrayOfObjects(array) {
  return array?.map((val) => {
    return {
      value: val,
      label: val,
    };
  });
}
export function createKeyValueofArrayofAccount(lov) {
  if (lov && lov.length > 0) {
    let result = lov.map((val) => ({
      label: val.displayValue,
      value: val.code,
      parentLOVCode: val.parentLOVCode,
    }));
    return result;
  }
}

export function formatDate(timestamp, withTime = "false") {
  const today = new Date();
  const inputDate = new Date(timestamp);

  // Check if the input date is today
  if (
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear()
  ) {
    return `Today${withTime ? `, ${momentFormat(timestamp, "hh:mm A")}` : ``}`;
  }

  // Check if the input date is tomorrow
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (
    inputDate.getDate() === tomorrow.getDate() &&
    inputDate.getMonth() === tomorrow.getMonth() &&
    inputDate.getFullYear() === tomorrow.getFullYear()
  ) {
    return `Tomorrow${
      withTime ? `, ${momentFormat(timestamp, "hh:mm A")}` : ``
    }`;
  }

  // Check if the input date is yesterday
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  if (
    inputDate.getDate() === yesterday.getDate() &&
    inputDate.getMonth() === yesterday.getMonth() &&
    inputDate.getFullYear() === yesterday.getFullYear()
  ) {
    return `Yesterday${
      withTime ? `, ${momentFormat(timestamp, "hh:mm A")}` : ``
    }`;
  }

  // Otherwise, return the exact date in the format if withTime true "DD MMM YYYY hh:mm A"
  return momentFormat(
    timestamp,
    withTime ? "DD MMM YYYY hh:mm A" : "DD MMM YYYY"
  );
}

export function randomColour() {
  const colourSet = [
    "#55efc4",
    "#dfe6e9",
    "#ffeaa7",
    "#c8d6e5",
    "#00cec9",
    "#1dd1a1",
    "#a4b0be",
    "#9AECDB",
    "#F8EFBA",
  ];
  return colourSet[Math.floor(Math.random() * 9)];
}

export function addHoursToCurrentDate(date, hours) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date;
}
export function addOrRemoveValue(arr, value) {
  if (arr.includes(value)) {
    arr.splice(arr.indexOf(value), 1);
  } else {
    arr.push(value);
  }
  return arr;
}

export function timeAgo(epochTime) {
  const now = Date.now();
  const diff = now - epochTime;

  // Define time units in milliseconds
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (diff < minute) {
    return Math.floor(diff / 1000) + " seconds ago";
  } else if (diff < hour) {
    return Math.floor(diff / minute) + " minutes ago";
  } else if (diff < day) {
    return Math.floor(diff / hour) + " hours ago";
  } else if (diff < week) {
    return Math.floor(diff / day) + " days ago";
  } else if (diff < month) {
    return Math.floor(diff / week) + " weeks ago";
  } else if (diff < year) {
    return Math.floor(diff / month) + " months ago";
  } else {
    return Math.floor(diff / year) + " years ago";
  }
}
export function lastMonthDate() {
  var today = new Date();
  var priorDate = new Date(new Date().setDate(today.getDate() - 30));
  return priorDate;
}

export function updateTimeInDate(date) {
  var currentDate = new Date();
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
}

export function daysTillToday(date) {
  const todayDate = new Date();
  const dueDate = new Date(date);
  const utc1 = Date.UTC(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate()
  );
  const utc2 = Date.UTC(
    dueDate.getFullYear(),
    dueDate.getMonth(),
    dueDate.getDate()
  );
  let difference = Math.abs(Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24)));
  return difference;
}

export const sendErrorToFaultWatch = async (event, hint) => {
  try {
    const description =
      event?.exception?.values[0]?.stacktrace?.frames[
        event?.exception?.values[0]?.stacktrace?.frames?.length - 1
      ];
    const errorDetails = event.exception?.values?.map((data) => {
      const { value, type } = data;
      return {
        value,
        type,
      };
    });
    const breadcrumbs = event?.breadcrumbs?.map((value) => {
      const { category, message, timestamp, data } = value;
      if (category === "xhr") {
        return {
          category,
          data,
          timestamp,
        };
      }
      return {
        category,
        message,
        timestamp,
      };
    });
    const ErrorPayload = {
      project: "uniview_crm",
      releaseVersion: event.release,
      logLevel: "ERROR",
      pageUrl: window?.location?.href,
      title: hint?.originalException?.message,
      stackTrace: hint.originalException?.stack,
      description,
      errorDetails,
      deviceInfo: {
        osFamilyName: window?.navigator?.userAgent,
      },
      breadcrumbs,
    };
    try {
      const result = await fetch(
        "https://nb-capi.nobroker.in/nb-capi/api/v1/pushErrorToFaultWatch",
        {
          method: "POST",
          body: JSON.stringify(ErrorPayload),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (result) return await result.json();
    } catch (err) {
      return err;
    }
  } catch (error) {
    console.log(error);
  }
};
export function processPhoneNumber(phoneNumber) {
  if (phoneNumber?.length === 12) {
    return phoneNumber.slice(2);
  } else if (phoneNumber.length === 10) {
    return phoneNumber;
  } else {
    // Handle any other cases or error conditions
    return null;
  }
}
export function arrayToLabelValueArray(array) {
  return array?.map((val) => {
    return { label: val, value: val };
  });
}
export function spaceBetweenWords(originalString) {
  const convertedString = originalString
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/^./, (match) => match.toUpperCase());
  return convertedString;
}

export function findDurationBetweenTwoDate(startDate, endDate, format = false) {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  // Calculate the time difference in milliseconds
  const durationMilliseconds = endDateTime - startDateTime;

  // Calculate the time difference in seconds
  const durationSeconds = durationMilliseconds / 1000;

  // Calculate the time difference in minutes
  const durationMinutes = durationSeconds / 60;

  // Calculate the time difference in hours
  const durationHours = durationMinutes / 60;

  // Calculate the time difference in days
  const durationDays = durationHours / 24;
  const duration = {
    durationMilliseconds: durationMilliseconds,
    durationSeconds: durationSeconds,
    durationMinutes: durationMinutes,
    durationHours: durationHours,
    durationDays: durationDays,
  };

  return format
    ? `${Math.floor(durationDays)} Days ${Math.floor(
        durationHours
      )}:${Math.floor(durationMinutes)}`
    : duration;
}

export function formatDuration(durationMilliseconds) {
  const millisecondsPerSecond = 1000;
  const secondsPerMinute = 60;
  const minutesPerHour = 60;
  const hoursPerDay = 24;

  const durationSeconds = durationMilliseconds / millisecondsPerSecond;
  const durationMinutes = durationSeconds / secondsPerMinute;
  const durationHours = durationMinutes / minutesPerHour;
  const durationDays = durationHours / hoursPerDay;

  if (durationDays >= 1) {
    return Math.floor(durationDays) + " day";
  } else if (durationHours >= 1) {
    return Math.floor(durationHours) + " hrs";
  } else if (durationMinutes >= 1) {
    return Math.floor(durationMinutes) + " mins";
  } else {
    return Math.floor(durationSeconds) + " secs";
  }
}
export function durationOf(startDate, endDate) {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  // Calculate the time difference in milliseconds
  const durationMilliseconds = endDateTime - startDateTime;

  const formattedDuration = formatDuration(durationMilliseconds);
  return formattedDuration;
}

export function noOfHoursBetweenTwoTimeStamps(date1, date2) {
  let diff = date2 - date1;
  let dayDiff = diff / (1000 * 60 * 60);
  return dayDiff;
}

export function currentTimeEpoch() {
  const currentTime = Math.floor(Date.now());
  return currentTime;
}

export function objectToArrayListOfTemplates(object) {
  if (object) {
    return Object.keys(object).map((val) => {
      return { value: val, label: val };
    });
  } else return [];
}
export function convertTo12HourFormat(time24) {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes] = time24.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const hours12 = hours % 12 || 12;

  // Format the result
  const time12 = `${hours12}:${minutes} ${period}`;

  return time12;
}

export function getDateAfterThreeDays() {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 3));

  const year = futureDate.getUTCFullYear();
  const month = String(futureDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(futureDate.getUTCDate()).padStart(2, "0");
  const hours = String(futureDate.getUTCHours()).padStart(2, "0");
  const minutes = String(futureDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(futureDate.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.888Z`;
}

export function findArrayDifferences(arr1, arr2) {
  const uniqueInArr1 = arr1?.filter((item) => !arr2?.includes(item));
  const uniqueInArr2 = arr2?.filter((item) => !arr1?.includes(item));

  return {
    uniqueInArr1: uniqueInArr1,
    uniqueInArr2: uniqueInArr2,
  };
}
export function roundOff(number = "0") {
  if (number) {
    return Math.round(number);
  } else {
    return "0";
  }
}
export function formatIndianNumber(amount) {
  // Round off the amount
  const roundedAmount = roundOff(amount);

  // Format the amount in Indian numbering system
  const formattedAmount = new Intl.NumberFormat("en-IN").format(roundedAmount);

  return formattedAmount;
}

export function encryptData(id) {
  const encryptedId = CryptoJS.AES.encrypt(
    id.toString(),
    CRYPTOSECRETKEY
  ).toString();
  return encryptedId;
}

export function decryptData(encryptedId) {
  const bytes = CryptoJS.AES.decrypt(encryptedId, CRYPTOSECRETKEY);
  const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedId;
}
export function convertCamelCaseToWords(inputString) {
  const result = inputString
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
  return result;
}

export function loadChatScripts(url, id) {
  const chatScripts = document.querySelector("#chatScripts");
  if (!chatScripts) {
    const script = document.createElement("script");
    script.src = url;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);
  }
}

export const ScrollToX = (id) => {
  if (id) {
    const element = document.getElementById(id);
    element &&
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }
};
export function getUtcDateAndTime(epoch) {
  const date = new Date(epoch);
  if (isNaN(date)) {
    return "Invalid Date";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate} ${formattedTime}`;
}
export function removeAdditionalAttributes(obj, keyToBeRemoved) {
  let result = {};

  for (let key in obj) {
    if (!key.startsWith(keyToBeRemoved)) {
      result[key] = obj[key];
    }
  }

  return result;
}

export function getTimeZoneByCountryCode(countryCode) {
  const timeZone = COUNTRY_TIME_ZONES[countryCode?.toUpperCase()];
  if (timeZone) {
    const dateAndTime = new Date().toLocaleString("en-US", { timeZone });
    return dateAndTime;
  } else {
    return null;
  }
}
export function getHoursByComparingEpoch(epoch) {
  const epochDate = new Date(epoch);
  const epochHours = epochDate.getHours();
  const currentDate = new Date();
  const currentHours = currentDate.getHours();
  const hoursDifference = currentHours - epochHours;

  return hoursDifference;
}
export function isWithinMentionedHours(epoch, value) {
  const currentTime = Date.now();
  const difference = currentTime - epoch;
  const hoursDifference = difference / (1000 * 60 * 60);
  if (value === 0) {
    return false;
  } else {
    return hoursDifference <= value;
  }
}
export function groupBy(arr, key) {
  return arr.reduce((acc, obj) => {
    const groupKey = obj[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(obj);
    return acc;
  }, {});
}
export function showDateWithMonthName(epoch) {
  const date = new Date(epoch);
  const day = date.getDate();
  const month = date.toLocaleString("en-us", { month: "short" });
  const suffix = ["th", "st", "nd", "rd"][
    day % 10 > 3 ? 0 : (day % 100) - (day % 10) != 10 ? day % 10 : 0
  ];

  return `${day}${suffix} ${month}`;
}
export function addDurationToDate(dateString, duration) {
  const date = new Date(dateString);
  let sixMonthsDuration = "";
  let oneYearDuration = "";
  let twoYearsDuration = "";
  // Add 6 months
  if (duration === "6") {
    sixMonthsDuration = new Date(date);
    sixMonthsDuration.setMonth(sixMonthsDuration.getMonth() + 6);
    return sixMonthsDuration;
  }

  // Add 1 year
  if (duration === "1") {
    oneYearDuration = new Date(date);
    oneYearDuration.setFullYear(oneYearDuration.getFullYear() + 1);
    return oneYearDuration;
  }
  // Add 2 years
  if (duration === "2") {
    twoYearsDuration = new Date(date);
    twoYearsDuration.setFullYear(twoYearsDuration.getFullYear() + 2);
    return twoYearsDuration;
  }
}
export function calculateDifferenceBetweenEpochTimes(epochTime1, epochTime2) {
  if (epochTime1 > epochTime2) {
    [epochTime1, epochTime2] = [epochTime2, epochTime1];
  }

  const date1 = new Date(epochTime1);
  const date2 = new Date(epochTime2);

  let years = date2.getFullYear() - date1.getFullYear();
  let months = date2.getMonth() - date1.getMonth();
  let days = date2.getDate() - date1.getDate();

  if (days < 0) {
    months--;
    const previousMonth = new Date(date2.getFullYear(), date2.getMonth(), 0);
    days += previousMonth.getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months, days };
}

export const getUnvDate = (data, dayStart, dayEnd) => {
  const reqDate = data?.date ? new Date(data.date) : new Date();

  if (data?.monthStart) {
    reqDate.setDate(1);
  }

  if (dayStart) {
    reqDate.setHours(0, 0, 0, 0);
  }

  if (dayEnd) {
    reqDate.setHours(23, 59, 59, 999);
  }

  if (data?.timeDifference) {
    const timeDiff = data?.timeDifference?.split(":");
    const dateOffset =
      Math.sign(timeDiff?.[0]) *
      ((Math.abs(+timeDiff?.[0]) || 0) * 60 * 60 + (timeDiff?.[1] || 0) * 60) *
      1000;
    reqDate.setTime(reqDate.getTime() + dateOffset);
  }

  if (data?.days) {
    const dateOffset = +data?.days * 24 * 60 * 60 * 1000;
    reqDate.setTime(reqDate.getTime() + dateOffset);
  }

  if (data?.callFunction && typeof reqDate?.[data?.callFunction] === "function")
    return reqDate?.[data?.callFunction]();
  return reqDate;
};

export const findDuration = (d1, d2) => {
  const value = d2 - d1;
  const duration = moment.duration(value);
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  let result = "";
  if (days === 1) {
    result += ` ${days} day,`;
  }
  if (days > 1) {
    result += ` ${days} days,`;
  }
  if (hours === 1) {
    result += ` ${hours} hour,`;
  }
  if (hours > 1) {
    result += ` ${hours} hours,`;
  }
  if (minutes === 1) {
    result += ` ${minutes} minute`;
  }
  if (minutes > 1) {
    result += ` ${minutes} minutes`;
  }
  if (seconds === 1) {
    result += ` ${seconds} second`;
  }
  if (seconds > 1) {
    result += ` ${seconds} seconds`;
  }
  return result.replace(/,\s*$/, "");
};

export function convertProjectLineChartData(inputData) {
  const roles = new Set();
  inputData.forEach((item) => {
    item.checkInResponse.forEach((response) => {
      roles.add(response.role);
    });
  });
  const rolesArray = Array.from(roles);
  const result = [["Date", ...rolesArray]];
  inputData.forEach((item) => {
    const date = moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY");
    const row = [date];

    const counts = {};
    item.checkInResponse.forEach((response) => {
      counts[response.role] = response.count;
    });
    rolesArray.forEach((role) => {
      row.push(counts[role] || 0);
    });
    result.push(row);
  });
  return result;
}
export function createKeyValuesWithParentCode(lov) {
  if (lov && lov.length > 0) {
    let result = lov.map((val) => ({
      label: val.value,
      value: val.key,
      parentCode: val.parentCode,
    }));
    return result;
  }
}
export function showDateWithMonthAndYear(epoch) {
  const date = new Date(epoch);
  const day = date.getDate();
  const month = date.toLocaleString("en-us", { month: "long" }); // Changed to full month name
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed

  const time = `${formattedHours}:${formattedMinutes}${ampm}`;

  const suffix = ["th", "st", "nd", "rd"][
    day % 10 > 3 ? 0 : (day % 100) - (day % 10) != 10 ? day % 10 : 0
  ];

  return `${month} ${day}${suffix}, ${year} | ${time}`;
}
export function dateToString(date) {
  const [day, month, year] = date.split("/");

  const dateObject = new Date(year, month - 1, day);

  const formattedDate = dateObject;
  return formattedDate;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export function arrayToArrayOfObjectsOfLeadName(array) {
  return array?.map((val) => {
    return {
      value: val?.serviceLeadId,
      label: val?.serviceLeadName,
    };
  });
}
export function encodedAdvocateFlowId() {
  const now = new Date();
  const formattedDate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()} ${now.getHours()}`;
  const authKey = MD5(`RPCBT6b@cbt0k3v${formattedDate}`).toString();
  return authKey;
}
