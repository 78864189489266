import React, { useEffect, useState } from "react";
import "./UserProfile.scss";
import CustomPopUp from "./popup/CustomPopUp";
import loadable from "@loadable/component";
const CallLogContainer = loadable(() =>
  import("../containers/CallLogContainer")
);
const ModalPanel = loadable(() => import("./common/modal/ModalPanel"));

export default function UserProfile(props) {
  const initials = props.name && props.name.split("")[0];
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusIcon, setStatusIcon] = useState("");
  const options = [
    {
      value: "updateStatus",
      label: (
        <div styleName="option" id="updateStatus">
          Update Status
        </div>
      ),
    },
    {
      value: "clearStatus",
      label: (
        <div styleName="option" id="clearStatus">
          Clear Status
        </div>
      ),
    },
    {
      value: "viewProfile",
      label: (
        <div styleName="option" id="viewProfile">
          View Profile
        </div>
      ),
    },
    props.label?.SHOW_CALL_HISTORY_ON_PROFILE !== "NA" && {
      value: "callHistory",
      label: (
        <div styleName="option" id="callHistory">
          Call History
        </div>
      ),
    },
    props?.workLogAutoOpen &&
      props.label?.PROFILE_WORK_LOG !== "NA" && {
        value: "workLog",
        label: (
          <div styleName="option" id="logout">
            {props?.label?.PROFILE_WORK_LOG}
          </div>
        ),
      },
    {
      value: "logout",
      label: (
        <div styleName="option" id="logout">
          Logout
        </div>
      ),
    },
  ];

  const handleSelection = (val) => {
    if (props?.profile?.resource?.isDashboardLocked) {
      props.showToast({ type: "error", message: "Dashboard has been locked" });
    } else {
      switch (val.value) {
        case "updateStatus":
          props.updateStatus();
          break;
        case "clearStatus":
          props.endBreak();
          setStatusIcon("#ffda97");
          setCurrentStatus("");
          break;
        case "viewProfile":
          props.goToProfile();
          break;
        case "logout":
          logOutFunc();
          break;

        case "workLog":
          props?.workLogModal();
          break;

        case "callHistory":
          props.openModal(callLog());
          break;

        default:
          break;
      }
    }
  };
  const logOutFunc = () => {
    localStorage.removeItem("accessToken");
    if (window.CometChatWidget) {
      window.CometChatWidget?.logout();
    }
    window.location = "/";
  };
  const callLog = () => (
    <ModalPanel width={"1150px"} overflow="unset">
      <CallLogContainer
        closeModal={props.closeModal}
        showLoader={props.showLoader}
        hideLoader={props.hideLoader}
        showToast={props.showToast}
        isLoan={true}
        showCDRID={
          (props.label?.SHOW_CALL_LOG_CDRID || "SHOW_CALL_LOG_CDRID") !== "NA"
        }
        agentId={props?.profile?.resource?.id}
        assigneFilter={true}
      />
    </ModalPanel>
  );
  useEffect(() => {
    setCurrentStatus(props.currentStatus);
    switch (props.currentStatus) {
      case "break":
        setStatusIcon("#00b893");
        break;
      case "meeting":
        setStatusIcon("#ff435e");
        break;
      case "lunch":
        setStatusIcon("#1976d2");
        break;

      default:
        setStatusIcon("#FFDA97");
        break;
    }
  }, [props.currentStatus]);

  return (
    <CustomPopUp options={options} onSelect={(val) => handleSelection(val)}>
      <div
        styleName="base"
        title={currentStatus}
        style={{
          color: props.textColor,
          cursor: props.isDashboardLocked ? "not-allowed" : "",
        }}>
        <div styleName="initials">{initials}</div>
        <div styleName="name" style={{ color: props.textColor }}>
          {props.name}
        </div>
        <div
          styleName={props.textColor ? "action-btn-color" : "action-btn"}></div>
        <div>
          <div
            styleName="status"
            style={{
              backgroundColor: statusIcon,
            }}
          />
        </div>
      </div>
    </CustomPopUp>
  );
}
