import React, { useState } from "react";
import "./KAMdashboard.scss";
import KAMTileCard from "./KAMTileCard";
import KAMPieCard from "./KAMPieCard";
import KAMBarCard from "./KAMBarCard";
import MeetingPanel from "./MeetingPanel";
function KAMdashboard({ userName, agentDetails, agentMeetingDetails }) {
  const [subCards, setsubCards] = useState(
    agentDetails?.[0]?.subCards ? "0" : null
  );
  const isSubCards = agentDetails?.some((val) => val.subCards?.length > 0);
  const iconStyle = [
    "facebook",
    "email",
    "instagram",
    "linkedin",
    "twitter",
    "playstore",
  ];
  return (
    <div styleName="base">
      <div styleName="content">
        <div styleName="details-container">
          <div styleName="title-container">
            <div styleName="user-image" />
            <div styleName="title">{userName}</div>
          </div>
          <div styleName="card-main-container">
            {agentDetails?.map((item, i) => {
              return (
                <div
                  key={item.id}
                  onClick={() => isSubCards && setsubCards(i?.toString())}
                  style={{ width: "45%", cursor: isSubCards ? "pointer" : "" }}
                  styleName={
                    i?.toString() === subCards ? "selected-shadow" : ""
                  }>
                  {item?.type === "CARD" ? (
                    <KAMTileCard
                      title={
                        isSubCards &&
                        iconStyle?.includes(item?.title?.toLowerCase()) ? (
                          <div styleName="card-title-holder">
                            <div styleName={item?.title?.toLowerCase()} />
                            <div styleName="title">{item?.title}</div>
                          </div>
                        ) : (
                          item?.title
                        )
                      }
                      cardData={item?.content}
                      completed={item?.completed}
                      total={item?.total}
                    />
                  ) : item?.type === "PIE_CHART" ? (
                    <KAMPieCard title={item?.title} pieData={item?.content} />
                  ) : item?.type === "BAR_CHART" ? (
                    <KAMBarCard title={item?.title} barData={item?.content} />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        {!subCards ? (
          <div styleName="meeting-container">
            <MeetingPanel meetingData={agentMeetingDetails} />
          </div>
        ) : (
          <div styleName="meeting-container p-10">
            <div styleName="mv-10">
              {iconStyle?.includes(
                agentDetails?.[subCards]?.title?.toLowerCase()
              ) ? (
                <div styleName="card-title-holder">
                  <div
                    styleName={`${agentDetails?.[
                      subCards
                    ]?.title?.toLowerCase()} `}
                  />
                  <div styleName="title" style={{ fontSize: "14px" }}>
                    {agentDetails?.[subCards]?.title}
                  </div>
                </div>
              ) : (
                <div>{agentDetails?.[subCards]?.title}</div>
              )}
            </div>
            {agentDetails?.[subCards]?.subCards?.length > 0 ? (
              <React.Fragment>
                {agentDetails?.[subCards]?.subCards?.map((item) => (
                  <div key={item.id} styleName="mv-10">
                    <KAMTileCard
                      title={item?.title}
                      cardData={item?.content}
                      completed={item?.completed}
                      total={item?.total}
                    />
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <div styleName="not-found">No Data Found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default KAMdashboard;
