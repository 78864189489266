import React, { Component } from "react";
import "./ErrorPage.scss";

export default class ErrorPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <div styleName="base">
        <div styleName="content">
          <div styleName="error-description">
            You are not authorised to access this page !!
          </div>
        </div>
      </div>
    );
  }
}
