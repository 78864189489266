import * as api from "./api";

export async function getReports(menu, resourceId = "") {
  const result = await api.get(
    `reports?menu=${menu}${resourceId ? `&resourceId=${resourceId}` : ``}`
  );
  return result;
}
export async function getCustomReports(reportType) {
  const result = await api.get(`custom-reports/all?reportType=${reportType}`);
  return result;
}
export async function getMyCustomReports(reportType) {
  const result = await api.get(`custom-reports/my?reportType=${reportType}`);
  return result;
}
export async function getCustomReportsById(reportId, key = "", value = "") {
  const result = await api.get(
    `custom-reports/${reportId}?${key ? `${key}=${value}` : ``}`
  );
  return result;
}
export async function addCustomReportTab(data) {
  const result = await api.mediaPost(`custom-reports`, data);
  return result;
}
export async function updateCustomReportTab(data) {
  const result = await api.put(`custom-reports`, data);
  return result;
}
export async function addResourceAccessToReport(reportId, data) {
  const result = await api.put(
    `custom-reports/add-access?reportId=${reportId}`,
    data
  );
  return result;
}
export async function removeResourceAccessToReport(reportId, data) {
  const result = await api.put(
    `custom-reports/remove-access?reportId=${reportId}`,
    data
  );
  return result;
}
export async function getResourceById(reportId) {
  const result = await api.get(`custom-reports/resources?reportId=${reportId}`);
  return result;
}

export async function getRoleById(reportId) {
  const result = await api.get(`custom-reports/roles?reportId=${reportId}`);
  return result;
}
export async function addRoleAccessToReport(reportId, data) {
  const result = await api.put(
    `custom-reports/add-role-access?reportId=${reportId}`,
    data
  );
  return result;
}
export async function removeRoleAccessToReport(reportId, data) {
  const result = await api.put(
    `custom-reports/remove-role-access?reportId=${reportId}`,
    data
  );
  return result;
}
export async function getNotificationsApi() {
  const result = await api.customGet2(
    "https://socialhub.nobroker.in/api/v1",
    `notifications/app/count?messageType=push`
  );
  return result;
}
