import React, { useEffect, useRef, useState } from "react";
import { Consumer } from "../containers/Provider";
import "./IntegrationEditor.scss";
import { getByIdIntegration, updateIntegration } from "../api/integrations";
import Toast from "./common/Toasts/Toast";
import Loader from "./common/Loader";
import BuzzBroker from "./IntegrationComponent/BuzzBroker";
import SlashRtc from "./IntegrationComponent/SlashRtc";
import CometChatWhatsApp from "./IntegrationComponent/CometChatWhatsApp.jsx";

import { ModalControl } from "./common/modal/ModalRoot";
import ModalPanel from "./common/modal/ModalPanel";
import ConfirmationDialog from "./ConfirmationDialog";
import Button from "./common/Button";
import ConfigModal from "./IntegrationComponent/ConfigModal";

export default function IntegrationEditor({
  match: {
    params: { integration_id },
  },
  history,
}) {
  return (
    <Consumer>
      {(props) => (
        <ModalControl>
          {({ openModal, closeModal }) => (
            <WithState
              history={history}
              integration={props.integration}
              integration_id={integration_id}
              profile={props.profile}
              openModal={openModal}
              closeModal={closeModal}
            />
          )}
        </ModalControl>
      )}
    </Consumer>
  );
}

function WithState({
  integration_id,
  profile,
  history,
  openModal,
  closeModal,
}) {
  const [integrationData, setintegrationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeFLage, setActiveFLage] = useState(null);

  // ref
  const toastRef = useRef();

  //functions
  const showLoader = () => {
    setLoading(true);
  };
  const hideLoader = () => {
    setLoading(false);
  };

  const showToast = (toast) => {
    toastRef?.current?.addToast({
      type: toast.type,
      message: toast.message,
    });
  };
  const fetchIntegrations = async (id) => {
    showLoader();
    const result = await getByIdIntegration(id);
    hideLoader();
    if (result.status === 1) {
      setintegrationData(result.data[0]);
      setActiveFLage(result.data[0]?.active);
    } else {
      showToast({
        type: "error",
        message: result?.errorDescription
          ? result?.errorDescription
          : "Something Went Wrong ",
      });
    }
  };

  const updateIntegrationApi = async (data) => {
    showLoader();
    const result = await updateIntegration(data);
    hideLoader();
    if (result?.status === 1) {
      showToast({
        type: "success",
        message: "Saved Successfully",
      });
      fetchIntegrations(integration_id);
      closeModal && closeModal();
    } else {
      showToast({
        type: "error",
        message: result?.errorDescription
          ? result?.errorDescription
          : "Something Went Wrong ",
      });
    }
  };

  const updateStatusConfirmationDialog = (obj, val) => {
    openModal(
      <ModalPanel>
        <ConfirmationDialog
          label={`Are you sure, you want to ${
            val ? "Activate" : "Deactivate"
          } this app ?`}
          closeModal={closeModal}
          onSubmit={() => updatedStatus(obj, val)}
        />
      </ModalPanel>
    );
  };

  const updatedStatus = (obj, val) => {
    const payload = { ...obj, active: val };
    updateIntegrationApi(payload);
  };
  const editIntegrationComponent = (id) => {
    switch (id) {
      case "1":
        return (
          <BuzzBroker
            updateIntegrationApi={updateIntegrationApi}
            history={history}
            integrationData={integrationData}
            activeFLage={activeFLage}
            showToast={showToast}
          />
        );

      case "2":
      case "4":
      case "6":
      case "7":
        return (
          <ConfigModal
            updateIntegrationApi={updateIntegrationApi}
            history={history}
            integrationData={integrationData}
            activeFLage={activeFLage}
            showToast={showToast}
          />
        );
      case "5":
        return (
          <ConfigModal
            updateIntegrationApi={updateIntegrationApi}
            history={history}
            integrationData={integrationData}
            activeFLage={activeFLage}
            showToast={showToast}
          />
        );

      case "3":
        return (
          <SlashRtc
            updateIntegrationApi={updateIntegrationApi}
            history={history}
            integrationData={integrationData}
            activeFLage={activeFLage}
            showToast={showToast}
          />
        );
      case "8":
        return (
          <CometChatWhatsApp
            updateIntegrationApi={updateIntegrationApi}
            history={history}
            integrationData={integrationData}
            activeFLage={activeFLage}
            showToast={showToast}
          />
        );
      default:
        return "No Configuration setting added for this Integration";
    }
  };

  useEffect(() => {
    integration_id && fetchIntegrations(integration_id);
  }, []);

  const integration =
    profile &&
    profile.capabilities &&
    profile.capabilities.includes("ROLE_MANAGE_INTEGRATION");

  return (
    <div styleName="base">
      {integration ? (
        loading ? (
          <Loader />
        ) : integrationData?.id ? (
          <div styleName="content">
            <div styleName="card-base">
              <div styleName="card-header">
                <img styleName="card-img" src={integrationData?.image} />
                {activeFLage && (
                  <span styleName="status-tag">{activeFLage && "Active"}</span>
                )}
              </div>
              <div styleName="card-info">
                <span styleName="card-header-info">
                  {integrationData?.displayName}
                </span>
                <div title={integrationData?.description} styleName="desc">
                  {integrationData?.description}
                </div>
              </div>
              <div styleName="card-oprtn">
                <a
                  href={integrationData?.websiteUrl}
                  styleName="card-btn"
                  rel="noopener noreferrer"
                  target="_blank">
                  VISIT SITE
                </a>
              </div>
              <div styleName="edit-button">
                <Button
                  label={activeFLage ? "Deactivate" : "Activate"}
                  type={activeFLage ? "secondary" : ""}
                  onClick={() =>
                    updateStatusConfirmationDialog(
                      integrationData,
                      !activeFLage
                    )
                  }
                />
              </div>
            </div>
            <div styleName="info-section">
              <div styleName="info-info">
                {integrationData?.id &&
                  editIntegrationComponent(integrationData?.id)}
              </div>
            </div>
          </div>
        ) : (
          <div styleName="calling-error">{"Some thing went wrong!"}</div>
        )
      ) : (
        <div styleName="calling-error">
          {
            "Looks like you don't have access to any dashboards yet ! Contact your manager immediately."
          }
        </div>
      )}
      <Toast ref={toastRef} />
    </div>
  );
}
