import React from "react";
import "./MyWorkSkeleton.scss";
export default function MyWorkSkeleton() {
  return (
    <div styleName="base">
      <div styleName="header skeleton-box" />
      <div styleName="main-container">
        <div styleName="content">
          <div styleName="left-section">
            <div styleName="header skeleton-box"></div>
            <div styleName="task-list">
              {[1, 2,3,4,5,6].map((val) => (
                <div key={val} styleName="card skeleton-box"></div>
              ))}
            </div>
          </div>
          <div styleName="right-section skeleton-box"></div>
        </div>
      </div>
    </div>
  );
}
