import React from "react";
import ReactDOM from "react-dom";
import "./Toast.scss";

export default class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toasts: [] };
    this.root = document.getElementById("toast-root");
  }

  handleToast = () => {
    if (this.props.onShowToast) {
      this.props.onShowToast();
    }
  };
  addToast = (val) => {
    this.setState({ toasts: [val, ...this.state.toasts] }, () => {
      setTimeout(() => {
        this.setState({
          toasts: this.state.toasts.filter((val, i) => {
            return i !== 0;
          }),
        });
      }, 3600);
    });
  };

  render() {
    return ReactDOM.createPortal(
      <div styleName={"base"}>
        {this.state.toasts.map((val, i) => (
          <ToastBox type={val.type} message={val.message} key={i} />
        ))}
      </div>,
      this.root
    );
  }
}

function ToastBox(props) {
  return (
    <div styleName={"toast"} key={props.key}>
      <div styleName={props.type} />
      <div styleName={"content"}>{props.message}</div>
    </div>
  );
}
