import React, { useState } from "react";
import { Form, FormElement } from "../common/core/Form";
import Input from "../common/Input";
import SingleSelectCheckBox from "../common/SingleSelectCheckBox";
import Button from "../common/Button";
import "../IntegrationEditor.scss";
import { checkEmptyObject, validateEmpty } from "../../utils/validations";
import { spaceBetweenWords } from "../../utils/utils";
import AgentDetailTable from "./AgentDetailTable";

function ConfigModal({
  history,
  activeFLage,
  integrationData,
  updateIntegrationApi,
  showToast,
}) {
  const [errors, seterrors] = useState({});
  const [flag, setflag] = useState(false);
  const [showConfig, setShowConfig] = useState(true);
  const [showAgentDetails, setShowAgentDetails] = useState(false);
  const columnName = ["User Name", "Email Id", "did Number", "Action"];
  const showAgents = ["2"];
  const initialValues = {
    ...integrationData,
    active: activeFLage,
  };
  if (initialValues?.name === "sarthi") {
    initialValues.credentials.createSarthiTicketOnUniview =
      integrationData?.credentials?.createSarthiTicketOnUniview || "true";
  }
  const updatedActiveSupportType = (props, flag, val) => {
    const tempArray = props.activeSupportedTypes || [];
    const filteredArray =
      flag === "true"
        ? tempArray.push(val)
        : tempArray?.filter((selVal) => selVal !== val);
    props.updateForm({
      activeSupportedTypes: flag === "true" ? tempArray : filteredArray,
    });
  };

  const updateEndPointsConfig = (props, key, val) => {
    const tempObj = { ...props.endPoints };
    tempObj[key] = val;
    props.updateForm({ endPoints: tempObj });
  };
  const updateConfigCredentials = (props, key, val) => {
    const tempObj = { ...props.credentials };
    tempObj[key] = val;
    props.updateForm({ credentials: tempObj });
  };
  let errorMessages = {
    CallZen: {
      accessToken: "Please enter accessToken",
      userId: "Please enter userId",
      clientId: "Please enter clientId",
    },
    SkyNet: {
      accessToken: "Please enter accessToken",
      serviceType: "Please enter serviceType",
    },
    StarShip: {
      accessToken: "Please enter accessToken",
    },
    sarthi: {
      createSarthiTicketOnUniview: "This field is required",
    },
  };
  const handleSubmit = (val) => {
    validate(val);
    if (checkEmptyObject(errors)) {
      updateIntegrationApi(val);
    }
  };

  const validateField = (value, validator) => {
    return validator(value);
  };
  const validate = (val) => {
    Object.keys(errorMessages?.[val?.name])?.map((key) => {
      if (!validateField(val?.credentials?.[key], validateEmpty)) {
        const error = Object.assign(errors, { [key]: true });
        seterrors(error);
      } else {
        const error = Object.assign(errors, { [key]: false });
        delete error[key];
        seterrors(error);
      }
      setflag(!flag);
    });
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(val) => {
          handleSubmit(val);
        }}
        initialValues={initialValues}
        canUpdateInitialValues={true}>
        <FormElement>
          {(props) => (
            <div styleName="int-base">
              <div
                styleName="info-header"
                onClick={() => {
                  setShowConfig(!showConfig);
                  setShowAgentDetails(false);
                }}>
                Configuration
                <div styleName={showConfig ? "arrow-up" : "down-arrow"} />
              </div>
              <div styleName="flex-col">
                {showConfig && (
                  <div styleName="config">
                    <div styleName="accordian-div">
                      <div styleName="accordian-text">Basic</div>
                      <div styleName="inpt-holder">
                        <div styleName="col">
                          <Input
                            placeholder="Base URL"
                            value={props.baseUrl}
                            onChange={(val) =>
                              props.updateForm({ baseUrl: val })
                            }
                            border="1px solid #e3e3e3d9"
                          />
                        </div>
                        <div styleName="col">
                          <Input
                            placeholder="Website URL"
                            value={props.websiteUrl}
                            onChange={(val) =>
                              props.updateForm({ websiteUrl: val })
                            }
                            border="1px solid #e3e3e3d9"
                          />
                        </div>
                        <div styleName="col">
                          <Input
                            placeholder="Image URL"
                            value={props.image}
                            onChange={(val) => props.updateForm({ image: val })}
                            border="1px solid #e3e3e3d9"
                          />
                        </div>
                      </div>
                    </div>
                    {props?.endPoints &&
                      Object.keys(props?.endPoints)?.length > 0 && (
                        <div styleName="accordian-div">
                          <div styleName="accordian-text">End Points</div>
                          <div styleName="inpt-holder">
                            {Object.keys(props?.endPoints)?.map((key, i) => {
                              return (
                                <div styleName="col" key={i}>
                                  <Input
                                    placeholder={spaceBetweenWords(key)}
                                    value={props.endPoints[key]}
                                    onChange={(val) =>
                                      updateEndPointsConfig(props, key, val)
                                    }
                                    border="1px solid #e3e3e3d9"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {props?.supportedTypes?.length > 0 && (
                      <div styleName="accordian-div">
                        <div styleName="accordian-text">Supported Types</div>
                        <div styleName="other">
                          <div styleName="flex-wrap">
                            {props?.supportedTypes?.map((val, _i) => (
                              <div styleName="ele" key={_i}>
                                <SingleSelectCheckBox
                                  options={[{ label: val, value: "true" }]}
                                  selected={
                                    props.activeSupportedTypes?.includes(val)
                                      ? "true"
                                      : false
                                  }
                                  onSelect={(flag) => {
                                    updatedActiveSupportType(props, flag, val);
                                  }}
                                  fontSize="14px"
                                  tabIndex="0"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {props?.credentials &&
                      Object.keys(props?.credentials)?.length > 0 && (
                        <div styleName="accordian-div">
                          <div styleName="accordian-text">Credentials</div>
                          <div styleName="inpt-holder">
                            {Object.keys(props?.credentials)?.map((key, i) => {
                              return (
                                <div styleName="col" key={i}>
                                  <Input
                                    placeholder={spaceBetweenWords(key)}
                                    value={props?.credentials[key]}
                                    onChange={(val) =>
                                      updateConfigCredentials(props, key, val)
                                    }
                                    error={
                                      errors?.[key]
                                        ? errorMessages?.[props?.name]?.[key]
                                        : null
                                    }
                                    border="1px solid #e3e3e3d9"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </div>
                )}
                {showAgents.includes(integrationData.id) && (
                  <>
                    <div
                      styleName="info-header"
                      onClick={() => {
                        setShowAgentDetails(!showAgentDetails);
                        setShowConfig(false);
                      }}>
                      Agent Details
                      <div
                        styleName={showAgentDetails ? "arrow-up" : "down-arrow"}
                      />
                    </div>
                    {showAgentDetails && (
                      <AgentDetailTable
                        providerId={integrationData?.id}
                        history={history}
                        columnName={columnName}
                        showToast={showToast}
                      />
                    )}
                  </>
                )}
              </div>
              <div styleName="footer-button">
                <Button
                  type="secondary"
                  label="Go Back"
                  onClick={() => history.goBack()}
                />
                <Button
                  margin="0 10px 0 10px"
                  label="Save"
                  onClick={props.onSubmit}
                />
              </div>
            </div>
          )}
        </FormElement>
      </Form>
    </React.Fragment>
  );
}

export default ConfigModal;
