import React, { useState } from "react";
import "./NotificationFilter.scss";
import DropDownBase from "./common/core/DropDownBase";
import Button from "./common/Button";
// import ToggleButton from "./societyCard/ToggleButton";

export default function NotificationFilter({
  width,
  getNotifications,
  tabValue,
  sortValue,
}) {
  const tagOption = [
    // { label: "All", value: "" },
    { label: "Tasks", value: "task" },
    { label: "Meetings", value: "meeting" },
    { label: "Jobs", value: "job" },
    { label: "Leads", value: "lead" },
    { label: "Tickets", value: "ticket" },
    { label: "Approvals", value: "approval" },
    { label: "Document", value: "document" },
    { label: "Bulk Import", value: "bulk-import" },
    { label: "Contact", value: "contact" },
    { label: "Note", value: "note" },
  ];

  const [tab, setTab] = useState(tabValue || []);
  const [sort, setSort] = useState(sortValue || false);
  const sortOption = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Read",
      value: true,
    },
  ];
  const handleTab = (val) => {
    let exisitingTab = tab ? [...tab] : [];
    if (!tab?.includes(val)) {
      exisitingTab.push(val);
      setTab(exisitingTab);
    } else {
      const filteredOptions = exisitingTab?.filter((value) => {
        return value !== val;
      });
      setTab(filteredOptions);
    }
  };
  const handleSubmit = (props) => {
    getNotifications(tab, sort);
    props.handleDropDown();
  };
  const handleClear = (props) => {
    setSort(false);
    setTab([]);
    getNotifications && getNotifications();
    props.handleDropDown();
  };
  return (
    <DropDownBase
      //   onSelect={this.props.onSelect}
      //   selected={this.props.selected}
      onClickOuter={() => {
        setSort(sortValue);
        setTab(tabValue);
      }}
      render={(props) => (
        <div
          styleName={`dropdown ${props.dropDownVisible ? "open" : ""}`}
          style={{ width: width }}
          onClick={(e) => e.stopPropagation()}>
          <div
            onClick={() => {
              setSort(sortValue);
              setTab(tabValue);
              props.handleDropDown();
            }}
            styleName="filter-icon"></div>
          {props.dropDownVisible && (
            <div styleName="dropdown-menu">
              <div styleName="header">Filters</div>
              <div styleName="tag-container">
                {tagOption?.map((val, i) => (
                  <div
                    styleName={tab?.includes(val.value) ? "tag-active" : "tag"}
                    key={i}
                    onClick={() => handleTab(val.value)}>
                    {val.label}
                  </div>
                ))}
              </div>
              <div styleName="header">Show</div>
              <div styleName="tag-container">
                {sortOption?.map((val, i) => (
                  <div
                    styleName={sort === val.value ? "tag-active" : "tag"}
                    key={i}
                    onClick={() => setSort(val.value)}>
                    {val.label}
                  </div>
                ))}
                {/* <div styleName="flex">
                  <div
                    styleName={sort === true ? "text-inactive" : "text-active"}>
                    read
                  </div>
                  <div styleName="mgr-8">
                    <ToggleButton
                      selected={sort}
                      onChange={() => setSort(!sort)}
                    />
                  </div>
                  <div
                    styleName={sort === true ? "text-active" : "text-inactive"}>
                    Read
                  </div>
                </div> */}
              </div>
              <div styleName="button-container">
                <div>
                  <Button
                    label="Clear"
                    type="secondary"
                    onClick={() => {
                      handleClear(props);
                    }}
                  />
                </div>
                <div>
                  <Button label="Search" onClick={() => handleSubmit(props)} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
}
