import React, { useEffect, useState } from "react";
import { formatedUtcDateTimeForReq, getUtcDateForApi } from "../../utils/utils";
import DropDown from "../common/DropDown";
import DropDownWithMultiSelect from "../common/dropdown/DropDownWithMultiSelect";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import DatePicker from "../common/DatePicker";
import "../Form.scss";
import DateAndTimePicker from "../common/DateAndTimePicker";
import AutoLocationSuggest from "../AutoLocationSuggest";
// import InternationTelInput from "../common/core/InternationTelInput";
import RadioButton from "../common/core/RadioButton";
import CheckBox from "../common/CheckBox";
import { maskNumber } from "../slashRtc";
import { validateAdditionalFunction } from "../../utils/validations";
import { numDimension } from "../../utils/utils";
import DropDownWithSearch from "../common/DropDownWithSearch";

export const additonalAttributeValidateType = [
  "URL_FORM_DATA",
  "EMAIL_FORM_DATA",
  "PHONE_FORM_DATA",
];
const additonalAttributeTextType = [
  "TEXT_FORM_DATA",
  "NUMBER_FORM_DATA",
  "PASSWORD_FORM_DATA",
  "TEXTAREA_FORM_DATA",
  "URL_FORM_DATA",
  "EMAIL_FORM_DATA",
  "PHONE_FORM_DATA",
  "FORMULA_FORM_DATA",
];
export default function AdditionFields({
  placeholder,
  disableField,
  error,
  data,
  updateAdditionAttribute,
  formData,
  onChange,
  editMode,
}) {
  //removed data.type === "BOOLEAN_FORM_DATA" ||
  let mandatoryErrorMessage =
    data.mandatory &&
    !(data.type === "FORMULA_FORM_DATA"
      ? formData.additionalAttributes &&
        formData.additionalAttributes[data.code]?.toString() !== "undefined"
      : formData.additionalAttributes &&
        formData.additionalAttributes[data.code]) &&
    error &&
    (editMode ? data.editable !== false : true)
      ? additonalAttributeTextType?.includes(data.type)
        ? `Please enter ${data.label}`
        : `Please select ${data.label}`
      : null;
  let validateErrorMessage =
    additonalAttributeValidateType?.includes(data.type) &&
    error &&
    !validateAdditionalFunction(
      data.type,
      formData.additionalAttributes && formData.additionalAttributes[data.code]
    )
      ? `Please enter valid ${data.label}`
      : null;
  const errorMessage = mandatoryErrorMessage
    ? mandatoryErrorMessage
    : validateErrorMessage;
  const [editMasked, setEditMasked] = useState(
    (editMode &&
      formData.additionalAttributes &&
      formData.additionalAttributes[data.code] &&
      data.masked) ||
      (editMode &&
        formData.additionalAttributes &&
        formData.additionalAttributes[data.code] &&
        data?.metaData?.masked)
  );
  const booleanOption = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];
  const textOptions = data.textOptions
    ? data.textOptions.split(",").map((val) => ({
        label: val,
        value: val,
      }))
    : [];

  //formula form field related chnages

  let formula = " " + data.metaData?.formula + " ";
  if (data.type === "FORMULA_FORM_DATA") {
    const variable = data.metaData?.formula
      ?.split(" ")
      ?.filter((val) => !["+", "-", "*", "/", "%"].includes(val) && isNaN(val));
    variable?.map((val) => {
      formula = formula.replaceAll(
        " " + val + " ",
        formData.additionalAttributes && formData.additionalAttributes[val]
          ? formData.additionalAttributes && formData.additionalAttributes[val]
          : 0
      );
    });
  }
  useEffect(() => {
    if (data.type === "FORMULA_FORM_DATA" && formData.additionalAttributes) {
      onChange
        ? onChange(eval(formula))
        : updateAdditionAttribute(formData, data.code, eval(formula));
    }
  }, [formula]);
  //currency
  const currency_symbol = data.metaData?.currency
    ? data.metaData?.currency + " "
    : "₹ ";
  //dependent dropdown
  let dependantDropDownOption = [];
  if (data.type === "DEPENDENT_DROPDOWN_TEXT_FORM_DATA") {
    let convertTextOptionToJSON = JSON.parse(data.textOptions);
    dependantDropDownOption =
      formData.additionalAttributes &&
      formData.additionalAttributes[data.metaData?.parentCode]
        ? convertTextOptionToJSON[
            formData.additionalAttributes[data.metaData?.parentCode]
          ]
            ?.split(",")
            ?.map((val) => ({
              label: val,
              value: val,
            }))
        : [];
    const index = dependantDropDownOption?.findIndex((val) => {
      return val.value === formData.additionalAttributes[data.code];
    });
    //if parent dropdown value got changed it removes the current value
    if (
      index === -1 &&
      formData.additionalAttributes &&
      formData.additionalAttributes[data.code]
    ) {
      onChange
        ? onChange("")
        : updateAdditionAttribute(formData, data.code, "");
    }
  }

  return (
    <React.Fragment>
      <div
        styleName={
          data.type === "TEXTAREA_FORM_DATA" ||
          data.type === "LOCATION_FORM_DATA"
            ? "address"
            : data.type === "RADIO_FORM_DATA" ||
              data.type === "CHECKBOX_FORM_DATA"
            ? "input-holder"
            : data.type === "DATETIME_FORM_DATA"
            ? "input-col"
            : "input"
        }>
        {(data.type === "TEXT_FORM_DATA" ||
          data.type === "NUMBER_FORM_DATA" ||
          data.type === "PASSWORD_FORM_DATA") && (
          <Input
            type={
              data.type === "NUMBER_FORM_DATA"
                ? editMasked
                  ? "text"
                  : "number"
                : data.type === "PASSWORD_FORM_DATA"
                ? "password"
                : "text"
            }
            value={
              editMasked
                ? formData.additionalAttributes &&
                  formData.additionalAttributes[data.code] &&
                  maskNumber(formData.additionalAttributes[data.code])
                : formData.additionalAttributes &&
                  formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]
                : ""
            }
            onChange={(val) => {
              onChange
                ? onChange(editMasked ? "" : val)
                : updateAdditionAttribute(
                    formData,
                    data.code,
                    editMasked ? "" : val
                  );
              editMasked && setEditMasked(false);
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
            maxlength={data.metaData?.maxLength}
            autocomplete={
              data.type === "PASSWORD_FORM_DATA" ? "new-password" : ""
            }
          />
        )}
        {(data.type === "URL_FORM_DATA" || data.type === "EMAIL_FORM_DATA") && (
          <Input
            type={data.type === "EMAIL_FORM_DATA" ? "email" : "url"}
            value={
              editMasked
                ? formData.additionalAttributes &&
                  formData.additionalAttributes[data.code] &&
                  maskNumber(formData.additionalAttributes[data.code])
                : formData.additionalAttributes &&
                  formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]
                : ""
            }
            onChange={(val) => {
              onChange
                ? onChange(editMasked ? "" : val)
                : updateAdditionAttribute(
                    formData,
                    data.code,
                    editMasked ? "" : val
                  );
              editMasked && setEditMasked(false);
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
            maxlength={data.metaData.maxLength}
          />
        )}
        {data.type === "TEXTAREA_FORM_DATA" && (
          <Textarea
            value={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]
                : ""
            }
            onChange={(val) => {
              onChange
                ? onChange(val)
                : updateAdditionAttribute(formData, data.code, val);
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
            maxLength={data.metaData.maxLength}
          />
        )}

        {data.type === "MULTIVALUE_TEXT_FORM_DATA" && (
          <DropDownWithMultiSelect
            options={textOptions}
            selected={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]?.split(",")
                : ""
            }
            onSelect={(val) => {
              onChange
                ? onChange(val?.toString())
                : updateAdditionAttribute(formData, data.code, val?.toString());
            }}
            onClear={() => {
              onChange
                ? onChange("")
                : updateAdditionAttribute(formData, data.code, "");
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
          />
        )}
        {data.type === "DROPDOWN_TEXT_FORM_DATA" && (
          <DropDownWithSearch
            options={textOptions}
            selected={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]
                : ""
            }
            onSelect={(val) => {
              onChange
                ? onChange(val.value)
                : updateAdditionAttribute(formData, data.code, val.value);
            }}
            onClear={() => {
              onChange
                ? onChange("")
                : updateAdditionAttribute(formData, data.code, "");
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
            showSearch={true}
            single={true}
            filter={true}
          />
        )}
        {data.type === "BOOLEAN_FORM_DATA" && (
          <DropDown
            options={booleanOption}
            selected={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]?.toString()
            }
            onSelect={(val) => {
              onChange
                ? onChange(val.value)
                : updateAdditionAttribute(formData, data.code, val.value);
            }}
            onClear={() => {
              onChange
                ? onChange("")
                : updateAdditionAttribute(formData, data.code, "");
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
          />
        )}
        {data.type === "DATE_FORM_DATA" && (
          <DatePicker
            selected={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]
                ? getUtcDateForApi(formData.additionalAttributes[data.code])
                : ""
            }
            showPastDate={true}
            onChange={(val) =>
              onChange
                ? onChange(formatedUtcDateTimeForReq(val))
                : updateAdditionAttribute(
                    formData,
                    data.code,
                    formatedUtcDateTimeForReq(val)
                  )
            }
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
            onClear={() => updateAdditionAttribute(formData, data.code, "")}
          />
        )}
        {data.type === "DATETIME_FORM_DATA" && (
          <DateAndTimePicker
            selected={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]
                ? getUtcDateForApi(formData.additionalAttributes[data.code])
                : ""
            }
            showPastDate={true}
            onChange={(val) =>
              onChange
                ? onChange(val)
                : updateAdditionAttribute(formData, data.code, val)
            }
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
          />
        )}
        {data.type === "LOCATION_FORM_DATA" && (
          <React.Fragment>
            {editMode &&
            formData.additionalAttributes &&
            formData.additionalAttributes[data.code] ? (
              <Input
                value={
                  formData.additionalAttributes &&
                  formData.additionalAttributes[data.code]
                }
                placeholder={placeholder}
                onChange={(val) =>
                  onChange
                    ? onChange(val)
                    : updateAdditionAttribute(formData, data.code, val)
                }
                disabled={disableField}
                id={placeholder}
              />
            ) : (
              <AutoLocationSuggest
                placeholder={placeholder}
                selected={
                  formData.additionalAttributes &&
                  formData.additionalAttributes[data.code]
                }
                onSelect={(val) => {
                  onChange
                    ? onChange(val.locationAddress)
                    : updateAdditionAttribute(
                        formData,
                        data.code,
                        val.locationAddress
                      );
                }}
                onClear={() => {
                  onChange
                    ? onChange("")
                    : updateAdditionAttribute(formData, data.code, "");
                }}
                disabled={disableField}
                error={errorMessage}
                id={placeholder}
              />
            )}
          </React.Fragment>
        )}
        {data.type === "PHONE_FORM_DATA" && (
          <Input
            type={editMasked ? "text" : "number"}
            value={
              editMasked
                ? formData.additionalAttributes &&
                  formData.additionalAttributes[data.code] &&
                  maskNumber(formData.additionalAttributes[data.code])
                : formData.additionalAttributes &&
                  formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]
                : ""
            }
            onChange={(val) => {
              onChange
                ? onChange(editMasked ? "" : val)
                : updateAdditionAttribute(
                    formData,
                    data.code,
                    editMasked ? "" : val
                  );
              editMasked && setEditMasked(false);
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
            maxlength={data.metaData.maxLength}
          />
          // <InternationTelInput
          //   selected={
          //     formData.additionalAttributes &&
          //     formData.additionalAttributes[data.code]?.split("-")[0]
          //   }
          //   onSelect={(val) => {
          //     onChange
          //       ? onChange(
          //           val.value +
          //             "-" +
          //             (formData.additionalAttributes[data.code]?.split("-")[1]
          //               ? formData.additionalAttributes[data.code]?.split(
          //                   "-"
          //                 )[1]
          //               : "")
          //         )
          //       : updateAdditionAttribute(
          //           formData,
          //           data.code,
          //           val.value +
          //             "-" +
          //             (formData.additionalAttributes[data.code]?.split("-")[1]
          //               ? formData.additionalAttributes[data.code]?.split(
          //                   "-"
          //                 )[1]
          //               : "")
          //         );
          //   }}
          //   placeholder={placeholder}
          //   type={"number"}
          //   value={
          //     formData.additionalAttributes &&
          //     formData.additionalAttributes[data.code]?.split("-")[1]
          //   }
          //   onChange={(val) => {
          //     onChange
          //       ? onChange(
          //           (formData.additionalAttributes[data.code]?.split("-")[0]
          //             ? formData.additionalAttributes[data.code]?.split("-")[0]
          //             : "91") +
          //             "-" +
          //             val
          //         )
          //       : updateAdditionAttribute(
          //           formData,
          //           data.code,
          //           (formData.additionalAttributes[data.code]?.split("-")[0]
          //             ? formData.additionalAttributes[data.code]?.split("-")[0]
          //             : "91") +
          //             "-" +
          //             val
          //         );
          //   }}
          //   disabled={disableField}
          //   error={errorMessage}
          //   maxlength={data.metaData?.maxLength}
          // />
        )}
        {data.type === "RADIO_FORM_DATA" && (
          <React.Fragment>
            <div styleName="address">
              <div>{placeholder}</div>
              <div styleName="error">{errorMessage}</div>
            </div>
            {textOptions?.map((val, i) => {
              return (
                <div styleName="col50" key={i}>
                  <RadioButton
                    name={data.code}
                    label={val.label}
                    value={val.value}
                    checked={
                      formData.additionalAttributes &&
                      formData.additionalAttributes[data.code] === val.value
                    }
                    size={20}
                    onChange={() => {
                      onChange
                        ? onChange(val.value)
                        : updateAdditionAttribute(
                            formData,
                            data.code,
                            val.value
                          );
                    }}
                    id={placeholder}
                  />
                </div>
              );
            })}
          </React.Fragment>
        )}
        {data.type === "CHECKBOX_FORM_DATA" && (
          <React.Fragment>
            <div styleName="address" style={{ marginBottom: "0px" }}>
              <div>{placeholder}</div>
              <div styleName="error">{errorMessage}</div>
            </div>
            <div styleName="address" style={{ marginTop: "0px" }}>
              <CheckBox
                onSelect={(val) => {
                  onChange
                    ? onChange(val?.toString())
                    : updateAdditionAttribute(
                        formData,
                        data.code,
                        val?.toString()
                      );
                }}
                selected={
                  formData.additionalAttributes &&
                  formData.additionalAttributes[data.code]?.split(",")
                }
                options={
                  data.textOptions
                    ? data.textOptions.split(",").map((val) => ({
                        label: val,
                        value: val,
                      }))
                    : []
                }
                multiple={true}
                id={placeholder}
              />
            </div>
          </React.Fragment>
        )}
        {data.type === "FORMULA_FORM_DATA" && (
          <Input
            disabled={true}
            value={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]?.toString()
            }
            placeholder={placeholder}
            error={errorMessage}
            id={placeholder}
          />
        )}
        {data.type === "CURRENCY_FORM_DATA" && (
          <React.Fragment>
            <Input
              type={"text"}
              value={
                currency_symbol +
                (formData.additionalAttributes &&
                formData.additionalAttributes[data.code]
                  ? formData.additionalAttributes[data.code]
                  : "")
              }
              onChange={(val) => {
                const removeCurrencySymbol = val.replace(currency_symbol, "");
                if (!isNaN(removeCurrencySymbol)) {
                  onChange
                    ? onChange(removeCurrencySymbol)
                    : updateAdditionAttribute(
                        formData,
                        data.code,
                        removeCurrencySymbol
                      );
                }
              }}
              placeholder={placeholder}
              id={placeholder}
              disabled={disableField}
              error={errorMessage}
              maxlength={23}
            />
            {formData.additionalAttributes &&
              formData.additionalAttributes[data.code] && (
                <div styleName={"amount-dimension"}>
                  <div styleName={"amount-content"}>
                    {numDimension(
                      formData.additionalAttributes &&
                        formData.additionalAttributes[data.code],
                      currency_symbol
                    )}
                  </div>
                </div>
              )}
          </React.Fragment>
        )}
        {data.type === "DEPENDENT_DROPDOWN_TEXT_FORM_DATA" && (
          <DropDown
            options={dependantDropDownOption}
            selected={
              formData.additionalAttributes &&
              formData.additionalAttributes[data.code]
                ? formData.additionalAttributes[data.code]
                : ""
            }
            onSelect={(val) => {
              onChange
                ? onChange(val.value)
                : updateAdditionAttribute(formData, data.code, val.value);
            }}
            onClear={() => {
              onChange
                ? onChange("")
                : updateAdditionAttribute(formData, data.code, "");
            }}
            placeholder={placeholder}
            id={placeholder}
            disabled={disableField}
            error={errorMessage}
          />
        )}
      </div>
    </React.Fragment>
  );
}
