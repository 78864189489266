import React, { createRef } from "react";
// import ReactDOM from "react-dom";

export default class DropDownBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownVisible: this.props.isDropDownVisible ? true : false,
      distanceToBottom: null,
    };
    this.wrapper = createRef();
    this.node = null;
  }
  componentDidMount() {
    this.node = this.wrapper.current;
    this.node.onclick = (event) => {
      this.handleClick(event);
    };
  }
  getDistanceToEdge = () => {
    const windowHeight = window.innerHeight;
    const scrollDistance = window.pageYOffset;
    const offsetBottom = this.node.getBoundingClientRect().bottom;
    const distanceToBottom = Math.abs(
      offsetBottom - (scrollDistance + windowHeight)
    );
    const distanceToTop = this.node.getBoundingClientRect().top;
    return { distanceToBottom, distanceToTop };
  };
  handleDropDown = () => {
    const distanceToBottom = this.getDistanceToEdge().distanceToBottom;
    const distanceToTop = this.getDistanceToEdge().distanceToTop;
    if (this.props.onDropDown) {
      this.props.onDropDown(!this.state.dropDownVisible);
    }
    this.setState(
      {
        dropDownVisible: this.props.open ? true : !this.state.dropDownVisible,
        distanceToBottom,
        distanceToTop,
      },
      () => {
        if (this.state.dropDownVisible) {
          this.props.toggleOverlay && this.props.toggleOverlay(true);
          document.addEventListener("mousedown", this.handleClick, false);
        } else {
          this.props.toggleOverlay && this.props.toggleOverlay(false);
          document.removeEventListener("mousedown", this.handleClick, false);
        }
      }
    );
  };
  setDropDown = (val) => {
    const distanceToBottom = this.getDistanceToEdge().distanceToBottom;
    const distanceToTop = this.getDistanceToEdge().distanceToTop;
    if (this.props.onDropDown) {
      this.props.onDropDown(val);
    }
    this.setState(
      {
        dropDownVisible: val,
        distanceToBottom,
        distanceToTop,
      },
      () => {
        if (this.state.dropDownVisible) {
          document.addEventListener("mousedown", this.handleClick, false);
        } else {
          document.removeEventListener("mousedown", this.handleClick, false);
        }
      }
    );
  };
  handleClick = (evt) => {
    this.props.handleFocuse && this.props.handleFocuse(true);
    if (!this.node.contains(evt.target)) {
      if (this.props.selected) {
        this.props.handleFocuse && this.props.handleFocuse(true);
      } else {
        this.props.handleFocuse && this.props.handleFocuse(false);
      }
      this.props.onClickOuter && this.props.onClickOuter();
      this.props.toggleOverlay && this.props.toggleOverlay(false);
      this.setState({ dropDownVisible: false });
    }
  };
  handleSelect = (val) => {
    if (this.props.onSelect) {
      this.props.handleFocuse && this.props.handleFocuse(true);
      this.props.onSelect && this.props.onSelect(val);
      this.props.handleBlur && this.props.handleBlur();
      if (!this.props.open) this.setState({ dropDownVisible: false });
    }
  };

  render() {
    return (
      <span ref={this.wrapper} style={{ ...this.props.renderStyles }}>
        {this.props.render({
          ...this.state,
          handleDropDown: this.handleDropDown,
          handleSelect: this.handleSelect,
          setDropDown: this.setDropDown,
        })}
      </span>
    );
  }
}
