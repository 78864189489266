import React from "react";
import loadable from "@loadable/component";
const DropDownBase = loadable(() => import("../common/core/DropDownBase"));
import "./DropDown.scss";

export default class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.selected ? true : false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ active: this.props.selected ? true : false });
    }
  }

  handleFocus = (val) => {
    if (this.props.active) {
      this.setState({ active: val });
    }
  };
  handleClick = (props) => {
    this.props.onClick && this.props.onClick();
    props.handleDropDown();
  };
  onKeyUp = (key, props) => {
    if (key === "Enter") {
      this.props.disabled ? null : props.handleDropDown();
    }
  };
  render() {
    let selectedKeys = !this.props.multiple
      ? this.props.selected
      : this.props.selected &&
        this.props.selected.length > 0 &&
        this.props.selected.map((data) => {
          return data.value;
        });
    const selected = this.props.selected
      ? this.props.options?.find((sel) => {
          return sel.value == this.props.selected;
        })
      : false;
    return (
      <DropDownBase
        onSelect={this.props.onSelect}
        selected={this.props.selected}
        handleFocuse={(val) => this.handleFocus(val)}
        handleBlur={() => this.props.onBlur && this.props.onBlur()}
        render={(props) => (
          <div styleName={"base"}>
            <div
              styleName={
                this.props.disabled
                  ? "disabled"
                  : this.props.error
                  ? "selected-error"
                  : "selected"
              }
              style={{
                height: this.props.height,
                width: this.props.width,
                border: this.props.border,
                borderRadius: this.props.borderRadius,
                backgroundColor: this.props.backgroundColor,
                color: this.props.color,
                padding: this.props.padding,
              }}
              onClick={
                this.props.disabled
                  ? null
                  : this.props.onClick
                  ? () => this.handleClick(props)
                  : props.handleDropDown
              }
              id={this.props.id || this.props.placeholder}
              tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
              onKeyUp={(event) => this.onKeyUp(event.key, props)}
            >
              {this.props.placeholder && (
                <div
                  styleName={
                    this.state.active || selected
                      ? this.props.disabled
                        ? "placeholder-active"
                        : this.props.noAnimate
                        ? "placeholder-active-disable"
                        : "placeholder-active"
                      : "placeholder"
                  }
                >
                  <div
                    styleName={"placeholder-text"}
                    style={{
                      ...this.props.placeholderStyle,
                      backgroundColor: this.props.backgroundColor,
                      color: this.props.color,
                    }}
                  >
                    {this.props.placeholder}{" "}
                    {this.props.required && (
                      <span styleName="required"> *</span>
                    )}
                  </div>
                </div>
              )}
              {selected && (
                <span
                  style={{ ...this.props.headerStyle }}
                  title={selected.name ? selected?.name : selected?.label}
                  styleName="selected-text"
                >
                  {selected.label}
                </span>
              )}
              {props.dropDownVisible && (
                <div
                  styleName={
                    props.distanceToBottom < 200 && props.distanceToTop > 200
                      ? "dropDown-top"
                      : "dropDown"
                  }
                  style={{
                    top: this.props.height,
                    height: this.props.listSize,
                    width: this.props.listWidth,
                  }}
                >
                  {this.props.options &&
                    this.props.options.map((val, i) => {
                      if (this.props.selected) {
                        if (this.props.multiple) {
                          if (!selectedKeys?.includes(val.value))
                            return (
                              <div
                                styleName={
                                  val.disable ? "option-disable" : "option"
                                }
                                key={i}
                                onClick={() => {
                                  if (!val.disable) {
                                    props.handleSelect(val);
                                  }
                                }}
                                tabIndex={
                                  this.props.tabIndex
                                    ? this.props.tabIndex
                                    : "0"
                                }
                                onKeyUp={(event) =>
                                  event.key === "Enter" &&
                                  !val.disable &&
                                  props.handleSelect(val)
                                }
                              >
                                {val.displayLabel
                                  ? val.displayLabel
                                  : val.label}
                              </div>
                            );
                        } else {
                          if (selectedKeys !== val.value)
                            return (
                              <div
                                styleName={
                                  val.disable ? "option-disable" : "option"
                                }
                                key={i}
                                onClick={() => {
                                  if (!val.disable) {
                                    props.handleSelect(val);
                                  }
                                }}
                                tabIndex={
                                  this.props.tabIndex
                                    ? this.props.tabIndex
                                    : "0"
                                }
                                onKeyUp={(event) =>
                                  event.key === "Enter" &&
                                  !val.disable &&
                                  props.handleSelect(val)
                                }
                              >
                                {val.displayLabel
                                  ? val.displayLabel
                                  : val.label}
                              </div>
                            );
                        }
                      } else {
                        return (
                          <div
                            styleName={
                              val.disable ? "option-disable" : "option"
                            }
                            key={i}
                            onClick={() => {
                              if (!val.disable) {
                                props.handleSelect(val);
                              }
                            }}
                            tabIndex={
                              this.props.tabIndex ? this.props.tabIndex : "0"
                            }
                            onKeyUp={(event) =>
                              event.key === "Enter" &&
                              !val.disable &&
                              props.handleSelect(val)
                            }
                          >
                            {val.displayLabel ? val.displayLabel : val.label}
                          </div>
                        );
                      }
                    })}
                </div>
              )}
            </div>

            {this.props.showIcon && (
              <div
                styleName={
                  this.props.disabled
                    ? "down-arrow-disable"
                    : this.props.selected && this.props.onClear
                    ? "cancel-icon"
                    : "downarrow"
                }
                onClick={
                  this.props.disabled
                    ? null
                    : this.props.selected && this.props.onClear
                    ? () => this.props.onClear()
                    : props.handleDropDown
                }
              ></div>
            )}

            {this.props.error && (
              <div styleName={"error"}>{this.props.error}</div>
            )}
          </div>
        )}
      />
    );
  }
}
DropDown.defaultProps = {
  showIcon: true,
  active: true,
  noAnimate: false,
};
