import React from "react";
import DatePicker from "react-datepicker";
import styles from "./DatePicker.scss";
import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export default class DateAndTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }
  handleChange = (date) => {
    this.setState({ active: true });
    if (this.props.onChange) {
      this.props.onChange(date);
    }
  };
  range = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step))
      .fill(start)
      .map((x, y) => x + y * step);

  render() {
    let dt = new Date();
    const years = this.range(1920, dt.getFullYear() + 30, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      <div className={this.props.error ? styles.baseErr : styles.base}>
        <DatePicker
          locale="en"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            let selectedDate = new Date(date);
            return (
              <div
              // style={{
              //   margin: 10,
              //   display: "flex",
              //   justifyContent: "center",
              // }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={selectedDate.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[selectedDate.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            );
          }}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          selected={this.props.selected}
          onChange={this.handleChange}
          placeholderText={this.props.placeholder}
          disabled={this.props.disabled}
          id={this.props.placeholder}
          dateFormat={"dd-MM-yyyy h:mm aa"}
          minDate={
            this.props.minDate
              ? this.props.minDate
              : this.props.showPastDate
              ? null
              : moment().toDate()
          }
        />
        {(this.state.active || this.props.selected) && (
          <div className={styles.placeholder}>
            <div className={styles.placeholderText}>
              {this.props.placeholder}
            </div>
          </div>
        )}
        {this.props.error && (
          <div className={styles.errorMsg}>{this.props.error}</div>
        )}
      </div>
    );
  }
}
