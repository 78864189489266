import React, { Component, useState } from "react";
import AutoSearchBar from "./societyCard/AutoSearchBar";
import "./ResourceTree.scss";
import { getResourceTree } from "../api/resource";
import Button from "./common/Button";
function ParentLeaf(props) {
  const [show, showHide] = useState(false);
  const salesTypes = {
    admin: "sales-administrator",
    sales_administrator: "sales-administrator",
    sales_head: "sales-head",
    sales_city_head: "city-head",
    sales_territory_manager: "sales-area-manager",
    sales_area_manager: "sales-area-manager",
  };

  return (
    <div styleName="leaf-holder">
      <div
        styleName={
          props.roleCode && salesTypes[props.roleCode]
            ? salesTypes[props.roleCode]
            : "default"
        }
        onClick={() =>
          props.children && props.children.length > 0 && showHide(!show)
        }>
        <div styleName="initials">{props.name && props.name.split("")[0]}</div>
        {props.name}
        <span styleName="role">({props.role && props.role})</span>
        {props.children && props.children.length > 0 && (
          <div styleName={show ? "arrow-active" : "arrow"}></div>
        )}
      </div>
      {show && props.children && (
        <React.Fragment>
          <div styleName="leaf-children">
            <div
              styleName={
                salesTypes[props.roleCode]
                  ? `${salesTypes[props.roleCode]}-border`
                  : "default-border"
              }></div>
            {props.children}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function PreviousHierarchy({ parentHierarchy }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {parentHierarchy?.[0]?.roleCode !== "root" && (
        <span style={{ color: "red" }}>
          **The hierarchy link chain is broken.Please try to recompute.**
        </span>
      )}
      {parentHierarchy?.map((item) => {
        return (
          <div key={item?.id} styleName="leaf-holder">
            <div styleName="default">
              <div styleName="initials">{item.name.split("")[0]}</div>
              {item?.name}
              <span styleName="role">({item?.role})</span>
            </div>
            <div></div>
          </div>
        );
      })}
    </div>
  );
}
class Leaf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      resourceTree: [],
    };
  }
  getResourceTree = async () => {
    if (!this.state.show) {
      const result = await getResourceTree(this.props.id);
      if (result.status === 1) {
        this.setState({ resourceTree: result.data, show: true });
      }
    }
  };
  render() {
    const salesTypes = {
      admin: "sales-administrator",
      sales_administrator: "sales-administrator",
      sales_head: "sales-head",
      sales_city_head: "city-head",
      sales_territory_manager: "sales-area-manager",
      sales_area_manager: "sales-area-manager",
    };
    return (
      <div
        styleName="leaf-holder"
        onClick={() => this.getResourceTree()}
        key={this.props.key}>
        <div
          styleName={
            this.props.role &&
            this.props.roleCode &&
            salesTypes[this.props.roleCode]
              ? salesTypes[this.props.roleCode]
              : "default"
          }
          onClick={() => this.setState({ show: !this.state.show })}>
          <div styleName="initials">
            {this.props.name && this.props.name.split("")[0]}
          </div>
          {this.props.name}
          <span styleName="role">({this.props.role && this.props.role})</span>
          {this.props.hasChildren && (
            <div styleName={this.state.show ? "arrow-active" : "arrow"}></div>
          )}
        </div>
        {this.state.show && this.state.resourceTree?.children && (
          <React.Fragment>
            <div styleName="leaf-children">
              <div
                styleName={
                  salesTypes[this.props.roleCode]
                    ? `${salesTypes[this.props.roleCode]}-border`
                    : "default-border"
                }></div>

              {this.state.resourceTree?.children?.map((val, i) => (
                <Leaf {...val} key={i}></Leaf>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default class ResourceTree extends React.Component {
  render() {
    const data = this.props.resourceTree;
    const parentHierarchy = this.props.parentHierarchy;
    return (
      <div styleName="base">
        <div styleName="header">
          <div styleName="content">
            {!this.props?.disableSearch ? (
              <div styleName="search-bar">
                <AutoSearchBar
                  onChange={this.props.searchResource}
                  onSelect={this.props.filterTreeById}
                  value={this.props.searchText}
                  options={
                    this.props.resourceSearchResults &&
                    this.props.resourceSearchResults.map((val) => {
                      return { value: val.id, label: val.partyName };
                    })
                  }
                />
              </div>
            ) : (
              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                Hierachy Tree
              </div>
            )}
            {/* <div styleName="resource-hierarchy-holder">
              <DropDown
                options={resourceHierarchyOption}
                selected={this.props.resourceHierarchy}
                border={"1px solid #e4e4e4"}
                active={false}
                placeholder={this.props.resourceHierarchy ? "" : "Hierarchy"}
                onSelect={(val) => {
                  if (this.props.handleHierarchy) {
                    this.props.handleHierarchy(val.value);
                  }
                }}
              />
            </div> */}
          </div>
        </div>
        <div
          styleName="content"
          style={{
            border: this.props?.disableSearch && "none",
            flexDirection: "column",
          }}>
          {this.props?.disableParentHierarchy && (
            <PreviousHierarchy parentHierarchy={parentHierarchy} />
          )}
          <ParentLeaf
            role={data?.parent?.role}
            name={data?.parent?.name}
            id={data?.parent?.id}
            roleCode={data?.parent?.roleCode}>
            {data?.children?.map((val, i) => (
              <Leaf {...val} key={i}></Leaf>
            ))}
          </ParentLeaf>
        </div>
        <div styleName="footer">
          <Button
            label="Recompute Hierarchy"
            onClick={this.props.handleComputeHierarchy}
            styles={{
              alignSelf: "flex-end",
              marginRight: "15px",
            }}
          />
        </div>
      </div>
    );
  }
}
