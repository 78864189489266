import React from "react";
import "./UpdateStatus.scss";
import Button from "../common/Button";
import { useState } from "react";
import { currentTimeEpoch, momentFormat } from "../../utils/utils";
import Input from "../common/Input";
function UpdateStatus({ profile, postStatus, closeModal }) {
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [customStatus, setCustomStatus] = useState("");
  const STATUS = [
    { id: 1, icon: "#ff435e", title: "In a Meeting", value: "meeting" },
    { id: 2, icon: "#00b893", title: "Going On A Break", value: "break" },
    { id: 3, icon: "#1976d2", title: "Lunch Break", value: "lunch" },
  ];
  const TIME_SLOTS = [
    { id: 1, value: 30, label: "30 min" },
    { id: 2, value: 60, label: "60 min" },
    { id: 3, value: 120, label: "2 hrs" },
  ];

  const handleSelect = (val) => {
    setSelectedStatus(val);
    setCustomStatus("");
  };
  const handleCustomSelect = (val) => {
    setSelectedStatus("");
    setCustomStatus(val);
  };
  const handleSelectTime = (val) => {
    setSelectedTime(val);
    setButtonDisabled(false);
  };
  const onSubmit = () => {
    const startDate = momentFormat(
      currentTimeEpoch(),
      `YYYY-MM-DDTHH:mm:ss.SSS`
    );
    const endDate = momentFormat(
      currentTimeEpoch() + 60000 * selectedTime?.value,
      `YYYY-MM-DDTHH:mm:ss.SSS`
    );
    let jsonObj = {
      resourceId: profile?.resource?.id,
      resourceBreakType: "BREAK",
      startDate: startDate,
      status: selectedStatus?.value || customStatus,
      endDate: endDate,
    };
    if (!buttonDisabled) {
      postStatus(jsonObj);
    }
  };
  return (
    <div styleName="main-container">
      <div styleName="title">Update Status</div>
      {STATUS?.map((item, index) => {
        return (
          <div key={index} styleName="status-container">
            <div styleName="status-title" onClick={() => handleSelect(item)}>
              <div
                styleName="status"
                style={{
                  backgroundColor: item.icon,
                }}></div>
              <span styleName={selectedStatus?.id === item?.id ? "bold" : ""}>
                {item?.title}
              </span>
              {selectedStatus?.id === item?.id && (
                <div styleName="tick-icon"></div>
              )}
            </div>
            {selectedStatus?.id === item?.id && (
              <div styleName="status-time-container">
                {TIME_SLOTS?.map((slotValue) => {
                  return (
                    <div
                      key={slotValue?.id}
                      styleName={`status-time ${
                        selectedTime?.id === slotValue?.id
                          ? "selected-time"
                          : ""
                      }`}
                      onClick={() => handleSelectTime(slotValue)}>
                      {slotValue?.label}
                    </div>
                  );
                })}
              </div>
            )}
            {selectedTime?.id === 3 && selectedStatus?.id === item?.id && (
              <div styleName="warning-container">
                <div styleName="warning-icon"></div>
                <div>An approval request will be shared with your manager.</div>
              </div>
            )}
          </div>
        );
      })}
      <div styleName="status-container">
        <div styleName="status-title">
          <div styleName="status" />
          <div
            style={{
              width: "50%",
            }}>
            <Input
              placeholder="Custom Status"
              value={customStatus}
              onChange={(val) => {
                handleCustomSelect(val);
              }}
            />
          </div>
          {customStatus && <div styleName="tick-icon"></div>}
        </div>
        {customStatus && (
          <div styleName="status-time-container">
            {TIME_SLOTS.map((slotValue) => {
              return (
                <div
                  key={slotValue?.id}
                  styleName={`status-time ${
                    selectedTime?.id === slotValue?.id ? "selected-time" : ""
                  }`}
                  onClick={() => handleSelectTime(slotValue)}>
                  {slotValue?.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div styleName="footer">
        <Button
          type="secondary"
          label="Close"
          onClick={closeModal}
          styles={{
            fontSize: "14px",
            borderColor: "#D4DADF",
          }}
        />
        <Button
          type="secondary"
          label="Save"
          onClick={onSubmit}
          styles={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#0CB88F",
            borderColor: "#0CB88F",
            opacity: buttonDisabled ? "0.40" : "1",
            cursor: buttonDisabled ? "not-allowed" : "pointer",
          }}
        />
      </div>
    </div>
  );
}
export default UpdateStatus;
