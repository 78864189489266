/* eslint-disable */

// import _0x411e8b from './Aes.js';
import CryptoJS from "crypto-js";

class AesCtr {
  static asdlhADJHALKdhLAIHWIHaDJbdqwASDHASJDH(data, param) {
    let returnData;
    let actualData;
    try {
      //   console.log(arg);
      //   const { a } = arg;
      //   console.log(a);
      //   if (!a) return null;
      //   const [data, param] = a;
      // console.log(data);
      // console.log(param);
      if (!data || !param) return null;
      const { l, bp } = param;
      if (!l || !bp) return null;

      const newData = data.split(bp);
      // console.log(bp);
      // console.log(data);

      let cipherText = newData[0];
      const fx = newData[1].substr(0, l);

      cipherText += newData[1].substr(l);

      let __m;
      if (typeof global && global.server === true) {
        __m = global.atob;
      } else {
        __m = window.atob;
      }

      const key = eval(__m(fx))();

      actualData = AesCtr._mighty(cipherText, key);
      // console.log(actualData);
      returnData = actualData;

      returnData = JSON.parse(actualData);
    } catch (e) {
      console.info("error at deconding1", e);

      console.log(actualData);
      returnData = actualData;
    }
    return returnData;
  }

  static _mighty(ciphertext, password, nBits = 256) {
    try {
      const encrypted = CryptoJS.enc.Base64.parse(ciphertext);
      const key = CryptoJS.enc.Base64.parse(password);
      const desc = CryptoJS.enc.Utf8.stringify(
        CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        })
      );
      return desc;
    } catch (error) {
      console.info("err at decoding2", error);
      return null;
    }
  }
}

export default AesCtr;
