import React from "react";
import MultiSelect from "./MultiSelect";
import "./SelectMultipleOption.scss";
export default class SelectMultipleOption extends React.Component {
  render() {
    return (
      <MultiSelect
        onSelect={this.props.onSelect}
        sendEntireValue={this.props.sendEntireValue}
        selected={this.props.selected}
        disableUnSelect={this.props.disableUnSelect}
        render={(props) => (
          <React.Fragment>
            <div styleName={this.props.isCustomPopup ? "base-custom" : "base"}>
              {this.props.filter
                ? this.props.options &&
                  this.props.options.map((val, i) => {
                    if (
                      val.label
                        ?.toLowerCase()
                        ?.startsWith(this.props.searchValue.toLowerCase())
                    ) {
                      return (
                        <div
                          styleName={"row"}
                          onClick={() => {
                            props.onSelect(
                              this.props.sendEntireValue ? val : val.value
                            );
                          }}
                          key={i}>
                          {this.props.showCheckbox ? (
                            <div
                              styleName={
                                this.props.selected &&
                                this.props.selected.length > 0 &&
                                this.props.selected?.includes(val.value)
                                  ? "checkmark-active"
                                  : "checkmark-inactive"
                              }
                            />
                          ) : (
                            <div style={{ width: "20px" }}>
                              {this.props.sendEntireValue
                                ? this.props.selected &&
                                  this.props.selected.length > 0 &&
                                  this.props.selected?.findIndex(
                                    (data) => data.value === val.value
                                  ) !== -1 && <div styleName="checkMark" />
                                : this.props.selected &&
                                  this.props.selected.length > 0 &&
                                  this.props.selected?.includes(val.value) && (
                                    <div styleName="checkMark" />
                                  )}
                            </div>
                          )}
                          <div styleName={"label"}>
                            {val.displayLabel ? val.displayLabel : val.label}
                          </div>
                        </div>
                      );
                    }
                  })
                : this.props.options &&
                  this.props.options.map((val, i) => {
                    return (
                      <div
                        styleName={"row"}
                        onClick={() => {
                          props.onSelect(
                            this.props.sendEntireValue ? val : val.value
                          );
                        }}
                        key={i}>
                        {this.props.showCheckbox ? (
                          <div
                            styleName={
                              this.props.selected &&
                              this.props.selected.length > 0 &&
                              this.props.selected?.includes(val.value)
                                ? "checkmark-active"
                                : "checkmark-inactive"
                            }
                          />
                        ) : (
                          <div style={{ width: "20px" }}>
                            {this.props.sendEntireValue
                              ? this.props.selected &&
                                this.props.selected.length > 0 &&
                                this.props.selected?.findIndex(
                                  (data) => data.value === val.value
                                ) !== -1 && <div styleName="checkMark" />
                              : this.props.selected &&
                                this.props.selected.length > 0 &&
                                this.props.selected?.includes(val.value) && (
                                  <div styleName="checkMark" />
                                )}
                          </div>
                        )}
                        {val.displayLabel ? val.displayLabel : val.label}
                      </div>
                    );
                  })}
            </div>
            {this.props.showFooterButton && (
              <div
                styleName="footer-button"
                style={{
                  opacity: this.props.buttonDisable && 0.5,
                  color: this.props.buttonColor,
                }}
                onClick={!this.props.buttonDisable && this.props.onButtonClick}>
                {this.props.buttonLabel ? this.props.buttonLabel : "Apply"}
              </div>
            )}
          </React.Fragment>
        )}
      />
    );
  }
}
