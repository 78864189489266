import React, { Component } from "react";
import DropDownBase from "../common/core/DropDownBase";
import "./SearchBar.scss";
export default class AutoSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleChange = (val) => this.props.onChange(val.target.value);
  handleSelect = (val) => this.props.onSelect(val);
  render() {
    return (
      <DropDownBase
        onSelect={this.handleSelect}
        render={(props) => (
          <div styleName="base">
            <div styleName="content">
              <input
                styleName="input"
                value={this.props.value}
                type={this.props.type}
                disabled={this.props.disabled}
                onFocus={() => props.handleDropDown()}
                //onBlur={() => this.setState({ open: false })}
                onChange={(val) => this.handleChange(val)}
                placeholder={this.props.placeholder || "Quick search"}
              ></input>
            </div>
            <div>
              {props.dropDownVisible && (
                <div styleName={"drop-down"} style={{ top: this.props.height }}>
                  {this.props.options &&
                    this.props.options.map((val, i) => {
                      return (
                        <div
                          styleName={"option"}
                          key={i}
                          onClick={() => {
                            props.handleSelect(val);
                          }}
                        >
                          {val.label}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}
