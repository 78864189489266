import React, { useState } from "react";
import "./Input.scss";

export default function Input(props) {
  const [focused, handleFocus] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [value, setValue] = useState(props.value);
  const active = focused || props.value?.toString();
  const handleChange = (val) => {
    props.onChange(val.target.value);
    setValue(val.target.value || value);
  };
  const handleBlur = (bool) => {
    if (props.onBlur) {
      props.onBlur();
    }
    handleFocus(bool);
  };
  const handleFocusfunction = (bool) => {
    if (props.onFocus) {
      props.onFocus();
    }
    handleFocus(bool);
  };
  const onKeyUp = (key) => {
    if (props.onKeyUp) {
      props.onKeyUp(key);
    }
  };
  return (
    <div
      styleName={
        active
          ? props.hollow
            ? props.error
              ? "base-hallow-err "
              : "base-hollow"
            : props.error
            ? "base-active-err"
            : "base-active"
          : props.hollow
          ? props.error
            ? "base-hallow-err "
            : "base-hollow"
          : props.error
          ? "base-err"
          : "base"
      }
      style={{ border: props.border, height: props.height, ...props.styles }}>
      <div styleName={"content"}>
        <input
          styleName="input-actual"
          value={props.value ? props.value : ""}
          type={
            props.type === "password"
              ? hidden
                ? "password"
                : "text"
              : props.type
          }
          disabled={props.disabled}
          onBlur={() => handleBlur(false)}
          onFocus={() => handleFocusfunction(true)}
          onKeyUp={(event) => onKeyUp(event.key)}
          onChange={handleChange}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          min={props.min}
          max={props.max}
          placeholder={props.placeholderText}
          id={props.id || props.placeholder}
          autoComplete={props.autocomplete || "off"}></input>
        <div styleName={active ? "placeholder-active" : "placeholder"}>
          <div
            styleName={
              props.disabled ? "placeholder-disabled" : "placeholder-text"
            }
            style={{ color: props.color }}>
            {props.placeholder}{" "}
            {props.required && <span styleName="required"> *</span>}
          </div>
        </div>
      </div>
      {props.value?.toString() && props.onClear && (
        <span styleName={"crossIcon"} onClick={props.onClear}></span>
      )}
      {props.type === "password" && (
        <div
          styleName={hidden ? "password-hidden" : "password"}
          onClick={() => setHidden(!hidden)}
        />
      )}
      {props.error && <div styleName={"errorMsg"}>{props.error}</div>}
    </div>
  );
}
