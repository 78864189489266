import React, { Component } from "react";
import "./RadioButton.scss";
export default class RadioButton extends Component {
  handleChange = (val) => {
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  };
  render() {
    return (
      <div>
        <label
          styleName="container"
          style={{ paddingLeft: `${this.props.size + 5}px` }}>
          {this.props.detailedInfo ? (
            <div styleName="label">
              {this.props.label}
              {this.props.city && (
                <span styleName="city">{`(${this.props.city})`}</span>
              )}
              {this.props.email && (
                <div styleName="email">{this.props.email}</div>
              )}
            </div>
          ) : (
            <div styleName="label"> {this.props.label}</div>
          )}
          <input
            type="radio"
            checked={this.props.checked}
            disabled={this.props.disabled}
            name={this.props.name}
            value={this.props.value}
            onChange={(e) => this.handleChange(e.target.value)}
          />
          <span
            styleName="checkmark"
            style={{
              width: `${this.props.size}px`,
              height: `${this.props.size}px`,
            }}></span>
        </label>
      </div>
    );
  }
}
