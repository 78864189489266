import { additonalAttributeValidateType } from "../components/additionalAttribute/AdditionFields";

export function validateEmpty(val = "") {
  let str = val?.toString();
  if (str) {
    return true;
  } else {
    return false;
  }
}
export function valdateAmountLesserThen(val = "", minAmount = "100000") {
  let str = val?.toString();
  if (str) {
    if (parseInt(str, 10) >= minAmount) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function validateNumber(val) {
  if (val) {
    const regex = /^(?!0)[1-9][0-9]{6,14}$/;
    return regex.test(val);
  } else {
    return false;
  }
}
export function validateValidNumber(val) {
  if (val) {
    const regex = /^[+]?(?:[0-9]{2})?[0-9]{10}$/;
    return regex.test(val);
  } else {
    return false;
  }
}
export function validatePincode(val) {
  if (val) {
    const regex = /\d{6}$/;
    return regex.test(val);
  } else {
    return false;
  }
}
export function validateEmail(val) {
  if (val) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(val);
  } else {
    return false;
  }
}

export function checkEmptyObject(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function checkContactEmpty(data, isEmailMandatory) {
  let hasError = false;
  data.map((val, i) => {
    if (i == 0) {
      if (
        !validateEmpty(val.name) ||
        !validateEmpty(val.phone) ||
        // val.secondaryPhoneNumber
        //   ? !validateEmpty(val.secondaryPhoneNumber)
        //   : false ||
        (val.email
          ? !validateEmail(val.email)
          : isEmailMandatory
          ? !validateEmail(val.email)
          : false) ||
        val.contactDesignation == null
      ) {
        hasError = true;
      }
    } else {
      if (
        !(
          !validateEmpty(val.name) &&
          !validateEmpty(val.phone) &&
          val.contactDesignation == null
        ) &&
        (!validateEmpty(val.name) ||
          !validateEmpty(val.phone) ||
          val.contactDesignation == null)
      ) {
        hasError = true;
      }
    }
  });
  return hasError;
}

export function checkApplicantEmpty(data) {
  let hasError = false;
  data.map((val, i) => {
    if (i == 0) {
      if (
        !validateEmpty(val.firstName) ||
        !validateEmpty(val.lastName) ||
        !validateEmpty(val.primaryPhoneNumber)
      ) {
        hasError = true;
      }
    } else {
      if (
        !(
          !validateEmpty(val.firstName) &&
          !validateEmpty(val.lastName) &&
          !validateEmpty(val.primaryPhoneNumber)
        ) &&
        (!validateEmpty(val.firstName) ||
          !validateEmpty(val.lastName) ||
          !validateEmpty(val.primaryPhoneNumber))
      ) {
        hasError = true;
      }
    }
  });
  return hasError;
}
export function checkCoApplicantEmpty(data) {
  let hasError = false;
  data?.map((val, i) => {
    if (
      val &&
      (val.firstName !== "" ||
        val.employmentStatus !== "" ||
        val.primaryPhoneNumber !== "")
    ) {
      if (i == 0) {
        if (
          !validateEmpty(val.firstName) ||
          !validateEmpty(val.employmentStatus) ||
          !validateEmpty(val.primaryPhoneNumber)
        ) {
          hasError = true;
        }
      } else {
        if (
          !(
            !validateEmpty(val.firstName) &&
            !validateEmpty(val.employmentStatus) &&
            !validateEmpty(val.primaryPhoneNumber)
          ) &&
          (!validateEmpty(val.firstName) ||
            !validateEmpty(val.employmentStatus) ||
            !validateEmpty(val.primaryPhoneNumber))
        ) {
          hasError = true;
        }
      }
    }
  });
  return hasError;
}
export function checkSellerEmpty(data) {
  const sum = data?.reduce(
    (accumulator, currentValue) =>
      accumulator + parseInt(currentValue.ownerShipShare),
    0
  );
  let hasError = false;
  data?.map((val) => {
    if (
      !validateEmpty(val.firstName) ||
      // !validateEmpty(val.lastName) ||
      !validateEmpty(val.primaryPhoneNumber) ||
      // !validateEmpty(val.panNumber) ||
      !validatePanDetails(val.panNumber) ||
      // !validateEmpty(val.ownerShipShare) ||
      sum > 100
    ) {
      hasError = true;
    }
  });
  return hasError;
}
export function checkPropertyEmpty(data) {
  let hasError = false;
  data.map((val, i) => {
    if (i == 0) {
      if (
        !validateEmpty(val.approvalType) ||
        !validateEmpty(val.approvalStatus)
        //  !validateEmpty(val.approved)
      ) {
        hasError = true;
      }
    } else {
      if (
        !(
          !validateEmpty(val.approvalType) && !validateEmpty(val.approvalStatus)
        ) &&
        (!validateEmpty(val.approvalType) || !validateEmpty(val.approvalStatus))
      ) {
        hasError = true;
      }
    }
  });
  return hasError;
}
export function paramsToObject(entries) {
  let result = {};
  for (let entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}
export function validateEmptyArray(val) {
  if (val?.length > 0) {
    return true;
  } else {
    return false;
  }
}

export function validatePanDetails(val) {
  let regPan = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  if (!validateEmpty(val) || regPan.test(val)) {
    return true;
  } else {
    return false;
  }
}
export function validURL(url) {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  if (!validateEmpty(url) || pattern.test(url)) {
    return true;
  } else {
    return false;
  }
}

export function validateAadharDetails(val) {
  var regAadhar = /^\d{4}\d{4}\d{4}$/;
  if (!validateEmpty(val) || regAadhar.test(val)) {
    return true;
  } else {
    return false;
  }
}
export const validateField = (value, validator) => {
  return validator(value);
};
export const validateFunction = (val) => {
  switch (val) {
    case "validateEmail":
      return validateEmail;

    case "panNumber":
      return validatePanDetails;

    default:
      return validateEmpty;
  }
};

export const validateAdditionalFunction = (type, value) => {
  if (value) {
    switch (type) {
      case "URL_FORM_DATA":
        return validURL(value) ? true : false;
      case "EMAIL_FORM_DATA":
        return validateEmail(value) ? true : false;
      case "PHONE_FORM_DATA":
        return validateNumber(value) ? true : false;
      default:
        return true;
    }
  } else return true;
};
export const validatedAdditionalAttribute = (
  additionalAttributeList,
  additionalAttributes,
  editMode = false
) => {
  const attributeError = additionalAttributeList
    ?.filter(
      (data) =>
        data.mandatory === true && (editMode ? data.editable !== false : true)
    )
    ?.filter((data) => {
      if (
        additionalAttributes &&
        (data.type === "BOOLEAN_FORM_DATA" || data.type === "FORMULA_FORM_DATA"
          ? additionalAttributes &&
            additionalAttributes[data.code]?.toString() !== "undefined"
          : additionalAttributes[data.code])
      ) {
        return false;
      } else {
        return true;
      }
    });
  const attributeValidationError = additionalAttributeList
    ?.filter((data) => additonalAttributeValidateType?.includes(data.type))
    ?.filter((data) => {
      if (
        !validateAdditionalFunction(
          data.type,
          additionalAttributes && additionalAttributes[data.code]
        )
      ) {
        return true;
      } else {
        return false;
      }
    });
  return attributeError?.length > 0 || attributeValidationError?.length > 0;
};
export function validateGSTNumber(val) {
  let regex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );
  if (!validateEmpty(val) || regex.test(val)) {
    return true;
  } else {
    return false;
  }
}
