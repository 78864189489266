import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import styles from "./DatePicker.scss";
// import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import en from "date-fns/locale/en-AU/index";
registerLocale("en", en);

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      startDate: new Date(),
    };
  }
  handleChange = (date) => {
    this.setState({ active: true });
    if (this.props.onChange && date) {
      // const d = new Date(date);
      this.props.onChange(date);
    }
  };
  range = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step))
      .fill(start)
      .map((x, y) => x + y * step);

  render() {
    let dt = new Date();
    const years = this.range(1920, dt.getFullYear() + 30, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const color = "#ff435e";
    const Rule = <b style={{ color }}>&nbsp;*</b>;
    return (
      <div
        className={
          this.props?.customClass
            ? this.props.error
              ? styles.baseErr
              : `${styles.base} ${styles[this.props?.customClass]}`
            : this.props.error
            ? styles.baseErr
            : styles.base
        }>
        <DatePicker
          locale="en"
          className={this.props.editStyleName || ""}
          // styleName={this.props.editStyleName || ""}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            let selectedDate = new Date(date);
            return (
              <div
              // style={{
              //   margin: 10,
              //   display: "flex",
              //   justifyContent: "center",
              // }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}>
                  {"<"}
                </button>
                <select
                  value={selectedDate.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}>
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[selectedDate.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }>
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}>
                  {">"}
                </button>
              </div>
            );
          }}
          dateFormat="dd-MM-yyyy"
          selected={this.props.selected}
          minDate={
            this.props.minDate
              ? this.props.minDate
              : this.props.showPastDate
              ? null
              : moment().toDate()
          }
          maxDate={this.props.maxDate}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          onChangeRaw={(event) => {
            if (event.target.value) {
              this.handleChange(this.props.selected);
            } else {
              this.handleChange("");
            }
          }}
          placeholderText={
            this.state.active || this.props.selected || this.props.showRule
              ? ""
              : this.props.required
              ? [this.props.placeholder, Rule]
              : this.props.placeholder
          }
          onBlur={(event) => {
            if (event.target.value) {
              this.handleChange(this.props.selected);
            } else {
              this.handleChange("");
            }
          }}
          id={this.props.id || this.props.placeholder}
          autoComplete="off"
          popperPlacement={this.props.popperPlacement}
        />
        {this.props.selected && this.props.onClear && (
          <span
            className={styles.crossIcon}
            onClick={this.props.onClear}></span>
        )}
        {(this.state.active || this.props.selected) && (
          <div className={styles.placeholder}>
            <div className={styles.placeholderText}>
              {this.props.placeholder}
              {this.props.required && (
                <span className={styles.required}> *</span>
              )}
            </div>
          </div>
        )}

        {this.props.showRule && !(this.state.active || this.props.selected) && (
          <div className={styles.place}>
            {this.props.placeholder}{" "}
            {this.props.required && <span className={styles.required}> *</span>}
          </div>
        )}

        {this.props.error && (
          <div className={styles.errorMsg}>{this.props.error}</div>
        )}
      </div>
    );
  }
}
