import * as api from "./api";

const LEAD_URL = "leads";
const ADD_NOTE_URL = "notes";
const METTING_URL = "meetings";
const Task_URL = "tasks";
const CONTACT_URL = "contacts";
const LOAN_URL = "loans";
const SERVICE_LEAD_URL = "service-leads";
var googleDUMP = [
  "https://www.googleapis.com/storage/v1/b/nb-recordings/o/recordings1%2f",
  "https://www.googleapis.com/storage/v1/b/nb-recordings/o/recordings%2f",
  "https://www.googleapis.com/storage/v1/b/nb-recordings/o/recordings2%2f",
  "https://www.googleapis.com/storage/v1/b/nb-recordings/o/recordings3%2f",
  "https://www.googleapis.com/storage/v1/b/nb-recordings/o/recordings4%2f",
];
export async function getLeads(pageNumber, searchParam) {
  const urlParams = new URLSearchParams(location.search);
  const pageSizeParam = urlParams.get("pageSize");
  const leadTypeParam = localStorage.getItem("salesLeadType")
    ? localStorage.getItem("salesLeadType")
    : "";
  let searchQuery = searchParam.replace("?", "&");
  const result = await api.get(
    `${LEAD_URL}?pageNum=${
      pageNumber ? pageNumber : 0
    }&includeCallHistory=true${pageSizeParam ? `` : `&pageSize=10`}${
      leadTypeParam ? `&leadType=${leadTypeParam}` : ""
    }${searchQuery ? searchQuery : ""}`
  );
  return result;
}
export async function getOnCallLeadApi(dataObj, serviceLead) {
  const leadType = serviceLead ? SERVICE_LEAD_URL : LEAD_URL;
  let str = "&";
  for (let [property, data] of Object.entries(dataObj)) {
    let encodedKey;
    let encodedValue;
    if (data != null) {
      encodedKey = encodeURIComponent(property);
      encodedValue = encodeURIComponent(data);
      str = str + `${encodedKey}=${encodedValue}&`;
    }
  }
  const result = await api.get(
    `${leadType}?pageNum=0&pageSize=10&leadFilter=search_all${str ? str : ""}
    `
  );
  return result;
}
export async function getLeadsCount(keyword) {
  let searchParam = location.search;
  const urlParams = new URLSearchParams(searchParam);
  const leadStatus = urlParams.get("leadStatus");
  let searchQuery = searchParam.replace("?", "&");
  let search_query = searchQuery.replace(`leadStatus=${leadStatus}`, "");
  const leadTypeParam = localStorage.getItem("salesLeadType")
    ? localStorage.getItem("salesLeadType")
    : "";
  const result = await api.get(
    `${LEAD_URL}/aggregate_count?${
      leadTypeParam ? `leadType=${leadTypeParam}&` : ""
    }${keyword ? `aggregateBy=${keyword}` : ""}${
      search_query ? search_query : ""
    }`
  );
  return result;
}
export async function getLeadById(id) {
  const result = await api.get(`${LEAD_URL}/${id}?includeCallHistory=true`);
  return result;
}
export async function getServiceLeadById(id) {
  const result = await api.get(`${SERVICE_LEAD_URL}/${id}`);
  return result;
}
export async function getLeadByName(
  name = "",
  pageNumber,
  fromTickets = false,
  service,
  loan = false,
  key = "leadName"
) {
  const isService = service === "true" ? true : false;
  const isLoan = loan === "true" ? true : false;
  const result = await api.get(
    `${isService ? SERVICE_LEAD_URL : isLoan ? LOAN_URL : LEAD_URL}?pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=10&${
      !fromTickets
        ? isService
          ? `serviceStageIn=pre_onboarding,onboarding,live&`
          : isLoan
          ? `leadStatusIn=loan_in_process, loan_interested, loan_pending_login,loan_open&`
          : `leadStatusIn=new,inprogress&`
        : ""
    }${key}=${name}&leadFilter=${fromTickets ? `search_all` : "all"}`
  );
  return result;
}
export async function getAccountByName(name = "") {
  const result = await api.get(
    `account?accountSearch=${name}&assigneeFilter=account_search`
  );
  return result;
}
export async function getLeadDocumentById(id, service = false) {
  const result = await api.get(`leads/${id}/documents?service=${service}`);
  return result;
}
export async function deleteSocietyImage(id) {
  const result = await api.coreDelete(`society/images/${id}`);
  return result;
}
export async function deleteHoodSocietyImage(id) {
  const result = await api.coreDelete(`leads/document/${id}`);
  return result;
}
export async function addLeads(data, duplicateCheck = true) {
  const result = await api.mediaPost(
    `${LEAD_URL}?checkDuplicate=${duplicateCheck}`,
    data
  );
  return result;
}

export async function addNotes(data) {
  const result = await api.notePost(ADD_NOTE_URL, data);
  return result;
}

export async function addNotesWithDocsApi(data) {
  const result = await api.formDataPost("notes/document", data);
  return result;
}

export async function addMeeting(data, setDefaultLocation = true) {
  const result = await api.mediaPost(
    `${METTING_URL}?setDefaultLocation=${setDefaultLocation}`,
    data
  );
  return result;
}

export async function editMeeting(
  data,
  setDefaultLocation = true,
  changeFromLeadLocation = false
) {
  const result = await api.put(
    `${METTING_URL}?setDefaultLocation=${setDefaultLocation}&changeFromLeadLocation=${changeFromLeadLocation}`,
    data
  );
  return result;
}
export async function deleteMeeting(meetingId) {
  const result = await api.coreDelete(`${METTING_URL}/${meetingId}`);
  return result;
}

export async function addTask(data) {
  const result = await api.mediaPost(Task_URL, data);
  return result;
}

export async function editTask(data) {
  const result = await api.put(`${Task_URL}`, data);
  return result;
}

export async function deleteTask(id) {
  const result = await api.coreDelete(`${Task_URL}/${id}`);
  return result;
}

export async function postRemainder(id) {
  const result = await api.mediaPost(`${Task_URL}/${id}/reminder`);
  return result;
}

export async function addMember(id, data, checkDupRole = true) {
  const result = await api.mediaPost(
    `leads/${id}/team?checkDupRole=${checkDupRole}`,
    data
  );
  return result;
}
export async function editMember(id, data) {
  const result = await api.put(`leads/${id}/team`, data);
  return result;
}
export async function removeMember(id, resourceId) {
  const result = await api.post(`leads/${id}/team/${resourceId}`);
  return result;
}

export async function editLead(id, data) {
  const result = await api.put(`leads/${id}`, data);
  return result;
}
export async function assignOwner(
  id,
  transferMeetings = false,
  transferTasks = false,
  data
) {
  const result = await api.put(
    `leads/${id}/assign?transferMeetings=${transferMeetings}&transferTasks=${transferTasks}`,
    data
  );
  return result;
}
export async function editLeadStatus(id, status) {
  const result = await api.put(`leads/${id}/status`, status);
  return result;
}
export async function addContact(id, data, isLead = false) {
  const result = await api.mediaPost(
    `leads/${id}/contacts${isLead ? "?isLead=true" : ""}`,
    data
  );
  return result;
}

export async function editContact(id, data, isLead = false) {
  const result = await api.put(
    `leads/${id}/contacts${isLead ? "?isLead=true" : ""}`,
    data
  );
  return result;
}
export async function validatePhoneNumber(number) {
  const result = await api.get(`validate/resource?phoneNumber=${number}`);
  return result;
}
export async function deleteContact(id, contactId, isLead = false) {
  const result = await api.coreDelete(
    `leads/${id}/contacts/${contactId}${isLead ? "?isLead=true" : ""}`
  );
  return result;
}
export async function verifyContact(id, data) {
  const result = await api.mediaPost(`contacts/verified-contact/${id}`, data);
  return result;
}
export async function salesResources(
  search = "",
  city = "",
  hierarchy = "",
  id = "",
  showParent = false,
  roleCodes
) {
  const result = await api.get(
    `resources/list-view?empName=${search}${
      hierarchy ? `&hierarchy=${hierarchy}` : ``
    }${id ? `&id=${id}` : ``}${city ? `&city=${city}` : ``}${
      showParent ? `&includeParent=${showParent}` : ``
    }${roleCodes ? `&roleCodes=${roleCodes}` : ``}`
  );
  return result;
}
export async function auditResources(search = "", city = "") {
  const result = await api.get(
    `resources/list-view?empName=${search}&includeSelf=true&city=${city}&hierarchy=audit_hierarchy`
  );
  return result;
}

export async function dashboardResources(dashboardId, search = "") {
  const result = await api.get(
    `calling-dashboard/resources?dashboardId=${dashboardId}&resourceName=${search}`
  );
  return result;
}
export async function salesResourcesChild(
  search = "",
  city = "",
  hierarchy = "",
  id = "",
  showParent = false
) {
  const result = await api.get(
    `resources/list-view?empName=${search}&child=true${
      hierarchy ? `&hierarchy=${hierarchy}` : ``
    }${id ? `&id=${id}` : ``}${city ? `&city=${city}` : ``}${
      showParent ? `&includeParent=${showParent}` : ``
    }`
  );
  return result;
}
export async function getResourceList(search = "", hierarchy = "") {
  const result = await api.get(
    `resources/list-view?resourceName=${search}${
      hierarchy ? `&hierarchy=${hierarchy}` : ``
    }`
  );
  return result;
}
export async function getContactsList(id, search = "") {
  const result = await api.get(`contacts/${id}?name=${search}`);
  return result;
}
export async function getContactsServiceList(leadId, searchText = "") {
  const result = await api.get(
    `contacts/${leadId}?service=true&name=${searchText}`
  );
  return result;
}
export async function uploadSocietyImage(data) {
  const result = await api.imagePost(`society/upload`, data);
  return result;
}

export async function uploadHoodSocietyImage(data) {
  const result = await api.imagePost(`leads/upload`, data);
  return result;
}

export async function getLocality(data) {
  const result = await api.get(
    `location/locality?societyName=${
      data.societyName ? data.societyName : ""
    }&city=${data.city}&state=${data.state}&pincode=${data.pinCode}${
      data.landmark ? `&landmark=${data.landmark}` : ""
    }`
  );
  return result;
}
export async function leadClosureData(leadId, data) {
  const result = await api.formDataPost(`leads/${leadId}/closure`, data);
  return result;
}
export async function initiateLeadClosureData(leadId, data) {
  const result = await api.mediaPost(`leads/${leadId}/init-closure`, data);
  return result;
}
export async function postClosureDocument(leadId, data) {
  const result = await api.formDataPut(`leads/${leadId}/closure`, data);
  return result;
}
export async function getClosureNumber(leadId) {
  const result = await api.get(`leads/${leadId}/closure/number`);
  return result;
}
export async function getClosure(leadId) {
  const result = await api.get(`leads/${leadId}/closure`);
  return result;
}
export async function getClosureList(leadId, isService = false) {
  const result = await api.get(
    `${isService ? "service-leads" : "leads"}/${leadId}/closure/v2`
  );
  return result;
}
export async function getClosureUIForm(formId = "win_sales_closure", data) {
  const result = await api.mediaPost(`ui-forms/config?formId=${formId}`, data);
  return result;
}
export async function getClosureByClosureNumber(
  leadId,
  closureNumber,
  isService = false
) {
  const result = await api.get(
    `${
      isService ? "service-leads" : "leads"
    }/${leadId}/closure/v2/${closureNumber}`
  );
  return result;
}
export async function getTeamMemberOfLead(leadId) {
  const result = await api.get(`team/${leadId}`);
  return result;
}
export async function getServiceTeamMemberOfLead(leadId) {
  const result = await api.get(`team/${leadId}?service=true`);
  return result;
}
export async function getTeamMembersOfAccount(accountId) {
  const result = await api.get(`team/${accountId}?isAccount=true`);
  return result;
}
export async function getViewAllOwner(leadId) {
  const result = await api.get(`leads/${leadId}/team`);
  return result;
}
export async function postTeamShares(leadId, data) {
  const result = await api.put(`team/${leadId}/share`, data);
  return result;
}
export async function getActivityLog(leadId, data) {
  const result = await api.get(
    `activity/v2/leads?entityIds=${leadId}&pageNum=${
      data?.pageNumber ? data?.pageNumber : 0
    }&pageSize=5${
      data?.createdOnStartDate &&
      `&createdOnStartDate=${data?.createdOnStartDate}`
    }${
      data?.createdOnEndDate && `&createdOnEndDate=${data?.createdOnEndDate}`
    }${data?.entityType && `&entityType=${data?.entityType}`}${
      data?.service ? `&service=${data?.service}` : ""
    }`
  );
  return result;
}

export async function addMeetingLog(data) {
  const result = await api.mediaPost(`meeting-log `, data);
  return result;
}
export async function getMeetingLog(meetingId) {
  const result = await api.get(`meetings/${meetingId}/call-logs`);
  return result;
}
export async function editMeetingLog(meetingId, data) {
  const result = await api.put(`meetings/${meetingId}/call-logs`, data);
  return result;
}
export async function addAdditionalAttributes(leadId, data) {
  const result = await api.mediaPost(
    `leads/${leadId}/additional-attributes`,
    data
  );
  return result;
}
export async function bulkAssignee(data, queryParam = "") {
  const result = await api.mediaPost(
    `bulk/leads/assign?${queryParam ? queryParam : ``}`,
    data
  );
  return result;
}
export async function bulkAuditAssignee(data) {
  const result = await api.mediaPost(`audit`, data);
  return result;
}
export async function getCallLog(
  leadId,
  pageNumber,
  audit = false,
  service = false,
  fromNb = false,
  isLoan = false,
  disposeValue = "",
  callsFrom = "",
  callsTill = "",
  contactName = ""
) {
  const result = await api.get(
    `contacts/${leadId}/call-history?${audit ? "audit=true&" : ""}pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=10&${service ? "service=true&" : ""}${
      fromNb ? "fromNb=true&" : ""
    }${isLoan ? "isLoan=true&" : ""}${
      disposeValue ? `disposeName=${disposeValue}&` : ""
    }${callsFrom ? `callsFrom=${callsFrom}&` : ""}${
      callsTill ? `callsTill=${callsTill}&` : ""
    }${contactName ? `contactName=${contactName}&` : ""}`
  );
  return result;
}
export async function getAgentCallLog(
  pageNumber,
  audit = false,
  service = false,
  fromNb = false,
  isLoan = false,
  agentId,
  disposeValue = "",
  processName = "",
  callsFrom = "",
  callsTill = ""
) {
  const result = await api.get(
    `contacts/agent/call-history?${audit ? "audit=true&" : ""}pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=10&${service ? "service=true&" : ""}${
      fromNb ? "fromNb=true&" : ""
    }${isLoan ? "isLoan=true&" : ""}${agentId ? `agentId=${agentId}&` : ""}${
      disposeValue ? `disposeName=${disposeValue}&` : ""
    }${processName ? `processName=${processName}&` : ""}${
      callsFrom ? `callsFrom=${callsFrom}&` : ""
    }${callsTill ? `callsTill=${callsTill}&` : ""}`
  );
  return result;
}

export async function getCallLogForCallingDashboard(leadId) {
  const result = await api.get(`call-history/caller-data?leadId=${leadId}`);
  return result;
}
export async function getAdminCallLog(
  leadId,
  serviceLeadId,
  ticketId,
  pageNumber,
  audit = false,
  service = false,
  fromNb = false,
  processName = "",
  startDate = "",
  endDate = ""
) {
  const result = await api.get(
    `contacts/call-history?leadId=${leadId}&serviceLeadId=${serviceLeadId}&ticketId=${ticketId}${
      audit ? "&audit=true&" : ""
    }pageNum=${pageNumber ? pageNumber : 0}&pageSize=10&${
      service ? "service=true&" : ""
    }${fromNb ? "fromNb=true&" : ""}${
      processName ? `processName=${processName}&` : ""
    }${startDate ? `startDate=${startDate}&` : ""}${
      endDate ? `endDate=${endDate}&` : ""
    }`
  );
  return result;
}
export async function getRecordings(recordId, token, i) {
  const result = await api.makeGCPAudioBlobRequest(
    `${googleDUMP[i]}${recordId}.mp3?alt=media`,
    token
  );
  return result;
}

export async function requestNumber(data) {
  const result = await api.mediaPost("request-access", data);
  return result;
}
export async function requestApplicantNumber(data) {
  const result = await api.mediaPost("request-access/loan-applicant", data);
  return result;
}
export async function broadcastBrochure(data) {
  const result = await api.notePost(
    `contacts/brochure?type=brochure_category`,
    data
  );
  return result;
}
export async function auditCall(leadId, auditId) {
  const result = await api.mediaPost(
    `audit/call?leadId=${leadId}&auditId=${auditId}`
  );
  return result;
}

export async function getAddressData(data) {
  const result = await api.getWithTimeout(
    `location/locality/list?city=${data.city}&state=${data.state}${
      data.pinCode ? `&pincode=${data.pinCode}` : ""
    }${data.landmark ? `&landmark=${data.landmark}` : ""}`
  );
  return result;
}

export async function deleteSeller(id, sellerId) {
  const result = await api.coreDelete(`loans/${id}/sellers/${sellerId}`);
  return result;
}
export async function getParticipantOfLead(
  leadId,
  service = false,
  loan = false,
  includeSelf = true,
  account = false
) {
  const result = await api.get(
    `contacts/${leadId}/participants?service=${service}&loans=${loan}&includeSelf=${includeSelf}&account=${account}`
  );
  return result;
}
export async function getValidateClosure(leadId) {
  const result = await api.mediaPost(`leads/${leadId}/closure/validate`);
  return result;
}
export async function completeTask(data) {
  const result = await api.put(`${Task_URL}/complete`, data);
  return result;
}
export async function getTicketById(
  leadId,
  pageNum,
  hoodSocietyId = "",
  ticketFilter = "",
  statusIn = "",
  isService = false,
  isAccount = false
) {
  let typeCases = {
    job: true,
    audit: true,
    inventory: true,
    hood_job_tickets: true,
  };
  // if ticket filter is hood_job_tickets then leadId should be neccessary
  let filter = false;
  if (ticketFilter === "hood_job_tickets") {
    filter = ticketFilter === "hood_job_tickets";
  } else {
    filter = typeCases[ticketFilter.toLowerCase()];
  }
  const result = await api.get(
    `tickets?ticketFilter=${
      ticketFilter ? ticketFilter : "lead_tickets"
    }&pageNum=${pageNum}&pageSize=10&${
      filter
        ? `${
            isAccount
              ? `accountId=${leadId}`
              : isService
              ? `serviceLeadId=${leadId}`
              : `leadId=${leadId}`
          }`
        : hoodSocietyId
        ? `hoodSocietyId=${hoodSocietyId}`
        : `${
            isAccount
              ? `accountId=${leadId}`
              : isService
              ? `serviceLeadId=${leadId}`
              : `leadId=${leadId}`
          }`
    }${statusIn ? `&ticketStatusIn=${statusIn}` : ``}${
      !isService ? `&leadType=SOCIETY` : ``
    }`
  );
  return result;
}
export async function addSupportTicket(data) {
  const result = await api.formDataPost(`tickets/support`, data);
  return result;
}
export async function getTicketDocumentById(ticketId) {
  const result = await api.get(
    `document-repo?documentFilter=search_documents&entityId=${ticketId}`
  );
  return result;
}
export async function updateMeeting(data) {
  const result = await api.put(`${METTING_URL}/update`, data);
  return result;
}
export async function meetingClosureValidationCheck(meetingId) {
  const result = await api.mediaPost(
    `${METTING_URL}/${meetingId}/closure-check`
  );
  return result;
}
export async function getMeetingDetailsById(meetingId) {
  const result = await api.get(`${METTING_URL}/${meetingId}`);
  return result;
}
export async function getMeetingNotes(meetingId) {
  const result = await api.get(`notes/meeting/${meetingId}`);
  return result;
}

export async function getMeetings(pageNumber, leadId, isService = false) {
  const result = await api.get(
    `${METTING_URL}/v2?pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=5&meetingFilter=assigned_to_anyone&${
      isService ? `serviceLeadId=${leadId}` : `leadId=${leadId}`
    }&${isService ? "service=true&" : ""}`
  );
  return result;
}
export async function getTasks(pageNumber, leadId, isService = false) {
  const result = await api.get(
    `tasks?assigneeFilter=task_assigned_to_anyone&pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=10&${
      isService ? `serviceLeadId=${leadId}` : `leadId=${leadId}`
    }&${isService ? "service=true&" : ""}`
  );
  return result;
}
export async function getContacts(
  pageNumber,
  accountId,
  isService = false,
  active = true,
  pageSize = 5
) {
  const result = await api.get(
    `${CONTACT_URL}?includeCallHistory=true&activeFlag=${active}&pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=${pageSize}&parentAccountId=${accountId}&${
      isService ? "service=true&" : ""
    }`
  );
  return result;
}
export async function getContactsByLeadId(
  pageNumber,
  leadId,
  isService = false,
  active = true,
  pageSize = 5
  // accountId = ""
) {
  const result = await api.get(
    `${CONTACT_URL}?includeCallHistory=true&activeFlag=${active}&pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=${pageSize}&baseLeadId=${leadId}${
      isService ? "&service=true&" : ""
    }`
  );
  return result;
}
export async function getContactsListByAccountId(accountId) {
  const result = await api.get(
    `${CONTACT_URL}/list?parentAccountId=${accountId}`
  );
  return result;
}
export async function getAssociatedLeads(pageNumber, accountId) {
  const result = await api.get(
    `leads?pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=10&leadFilter=search_all${
      accountId ? `&accountId=${accountId}&` : ""
    }`
  );
  return result;
}
export async function getHsLeads(pageNo, accountId) {
  const result = await api.get(
    `associated-lead/${accountId}?pageNum=${
      pageNo ? pageNo : 0
    }&sortBy=createdOn&pageSize=5&isAsc=true`
  );
  return result;
}
export async function hsLeadsPostCall(accountId, data) {
  const result = await api.mediaPost(
    `associated-lead/create-hs-lead/${accountId}`,
    data
  );
  return result;
}
export async function downloadRecordings(url, cdrid) {
  const result = await api.coreGet(`${url}/${cdrid}.mp3`);
  return result;
}
export async function getSupportedComm(data) {
  const result = await api.mediaPost(`automation/supported-comm`, data);
  return result;
}
export async function sendSupportedComm(data) {
  const result = await api.mediaPost(`automation/supported-comm/send`, data);
  return result;
}
export async function getLeadMetadata(data) {
  const result = await api.mediaPost(`leads/metadata`, data);
  return result;
}
export async function getProductDetailsById(id) {
  const result = await api.get(`${LEAD_URL}/${id}/product`);
  return result;
}
export async function addSalesProduct(id, data) {
  const result = await api.mediaPost(`${LEAD_URL}/${id}/product`, data);
  return result;
}
export async function updateSalesProduct(id, data) {
  const result = await api.put(`${LEAD_URL}/${id}/product`, data);
  return result;
}
export async function deleteSalesProduct(leadId, productId) {
  const result = await api.coreDelete(
    `${LEAD_URL}/${leadId}/product/${productId}`
  );
  return result;
}
export async function getSocietyAddressList(data) {
  const result = await api.get(
    `location/autocomplete?hint=${data.landmark}&requestId=${
      data.requestId
    }&city=${data.city ? data.city : ""}`
  );
  return result;
}
export async function getLocationDetailsByPlaceId(placeId) {
  const result = await api.get(`location/details?placeId=${placeId}`);
  return result;
}

export async function autoDialAssignLead(data) {
  const result = await api.mediaPost(`auto-dial/assignLead`, data);
  return result;
}
export async function getNotesByKey(key, value, pageNum, fetchDocs = false) {
  const result = await api.get(
    `notes?entityType=${key}&entityId=${value}&pageNum=${pageNum}&fetchDocs=${fetchDocs}`
  );
  return result;
}
export async function getLoanAmount(leadId, createdBy, key, status) {
  const result = await api.get(
    `/loans/events?leadId=${leadId}${
      createdBy ? `&createdBy=${createdBy}` : ""
    }${key ? `&key=${key}` : ""}&status=${status}`
  );
  return result;
}
export async function getTicketHistory(ticketId, pageNum) {
  const result = await api.get(
    `activity/v2/entity?entityIds=${ticketId}&pageNum=${pageNum}`
  );
  return result;
}
export async function MergeLeadsSalesDashboard(data) {
  const result = await api.mediaPost(`leads/merge`, data);
  return result;
}
//store event on Cloud
export async function storeEventsOnCloud(eventURL, data, bussinessId) {
  console.log(data, "storeEvent");
  let jsonObj = {
    jsonData: {
      customerNumber:
        data?.callInfo?.variableCustomerNumber ||
        data?.callInfo?.customerNumber,
      agentId: data?.userInfo?.id,
      agentUserName:
        data?.userInfo?.userFullName ||
        data?.userInfo?.userName ||
        data?.userInfo?.userExtension,
      campaignId:
        data?.callInfo?.variableCampaignId || data?.callInfo?.campaignId,
      campaignName:
        data?.callInfo?.variableCampaignName || data?.callInfo?.campaignName,

      customerUuid:
        data?.callInfo?.variableCustomerUuid || data?.callInfo?.customerUuid,
      dateTime: data?.eventInfo?.dateTime,
      disconnectedBy: data?.callInfo?.variableDisconnectedBy,
      endEvent: "false",
      eventEpoch: data?.eventInfo?.epoch,
      eventTime: data?.eventInfo?.dateTime,
      eventType: data?.eventInfo?.type,
      legType: data?.callInfo?.variableLegType,
      modeOfCalling:
        data?.callInfo?.variableModeOfCalling || data?.callInfo?.modeOfCalling,
      processId: data?.callInfo?.variableProcessId || data?.callInfo?.processId,
      processName:
        data?.callInfo?.variableProcessName || data?.callInfo?.processName,
      referenceUuid:
        data?.callInfo?.variableReferenceUuid || data?.callInfo?.referenceUuid,
      talkTime:
        data?.callInfo?.variableTotalTalkTime || data?.callInfo?.totalTalkTime,
      type: data?.eventInfo?.type,
      callCount: data?.callInfo?.variableCallCount || data?.callInfo?.callCount,
    },
    bussinessId: bussinessId,
  };
  console.log(jsonObj);
  const result = await api.corePost(eventURL, jsonObj);
  console.log(result, "call event");
  return result;
}
export async function storeAgentEventsOnCloud(eventURL, data) {
  const result = await api.corePost(eventURL, data);
  console.log(result, "agent event");
  return result;
}
export async function MergeLoanLeadsSalesDashboard(data) {
  const result = await api.mediaPost(`loans/merge`, data);
  return result;
}
export async function getSalesPitchScript() {
  const result = await api.get(`sales-pitch`);
  return result;
}
export async function sendLoanDocumentUploadUrl(data) {
  const result = await api.mediaPost(
    `send-notification/loans-document-collection`,
    data
  );
  return result;
}
export async function sendSbiLoanDocumentUploadUrl(data) {
  const result = await api.mediaPost(
    `send-notification/loans-sbi-application-form`,
    data
  );
  return result;
}

export async function getSalesPitch(active = true) {
  const result = await api.get(`sales-pitch?active=${active}`);
  return result;
}
export async function addNewSalesPitch(data) {
  const result = await api.mediaPost(`sales-pitch`, data);
  return result;
}
export async function updateSalesPitch(data) {
  const result = await api.put(`sales-pitch`, data);
  return result;
}
export async function getLeadByLeadId(id) {
  const result = await api.get(`${LEAD_URL}/copy/${id}`);
  return result;
}
export async function createCopyLead(data) {
  const result = await api.mediaPost(`${LEAD_URL}/copy`, data);
  return result;
}
export async function getLeadAccountDetails(serchText, pageNumber) {
  const result = await api.get(
    `account?pageNum=${
      pageNumber ? pageNumber : 0
    }&pageSize=20&accountSearch=${serchText}&assigneeFilter=account_search`
  );
  return result;
}
export async function bridgeCall(phoneNumber) {
  const result = await api.post(`app/bridge-call/number/${phoneNumber}`);
  return result;
}
export async function brigdeCallById(leadId) {
  const result = await api.mediaPost(`app/bridge-call/lead/${leadId}`);
  return result;
}
export async function getLeadNotesByEntityType(
  key,
  value,
  pageNum,
  fetchDocs = false
) {
  const result = await api.get(
    `notes?leadId=${value}&entityType=${key}&pageNum=${pageNum}&fetchDocs=${fetchDocs}`
  );
  return result;
}
export async function fetchPendingItem(service) {
  const result = await api.get(
    `leads/pending-count${service ? "?service=true" : ""}`
  );
  return result;
}

export async function getCalenderMeetings(
  startDate,
  endDate,
  duration = 30,
  partyIds
) {
  const result = await api.get(
    `meetings/calendar-view?startDate=${startDate}&endDate=${endDate}&durationMin=${duration}&partyIds=${partyIds}`
  );
  return result;
}
export async function getMeeting(pageNumber = 0, ids) {
  const result = await api.get(
    `meetings/v2?pageNum=${pageNumber ? pageNumber : 0}&pageSize=10${
      ids ? `&ids=${ids} ` : ""
    }`
  );
  return result;
}
export async function getGoogleClosureDetails(id, type = "CLOSURE") {
  const result = await api.get(`google-sheet?id=${id}&type=${type}`);
  return result;
}
// export async function getWhatsappDevices(url) {
//   let result = [];
//   try {
//     const response = await fetch(`${url}/api/devices`);
//     const res = await response.text();
//     if (res) result = JSON.parse(res);
//     return result;
//   } catch (error) {
//     return [];
//   }
// }
export async function getWhatsappDevices() {
  const result = await api.get(`whatsapp-recon/devices`);
  return result;
}
export async function getWhatsappGroupName(id) {
  const result = await api.get(`whatsapp-recon/groups?jid=${id}`);
  return result;
  // https://crm.nobrokerhood.com/api/v1/whatsapp-recon/
  // let result = [];
  // try {
  //   let response = await fetch(`${url}/api/groups?jid=${id}`);
  //   const res = await response.text();
  //   if (res) result = JSON.parse(res);
  //   return result;
  // } catch {
  //   return [];
  // }
}
export async function getLeadContactSync(data) {
  const result = await api.mediaPost(`contacts/sync/lead`, data);
  return result;
}
export async function getIntegrationDetails(type = "CallAnalytics") {
  const result = await api.get(`integration?type=${type}&active=true`);
  return result;
}
export async function getAccountsBySearch(name = "", lat, lng) {
  const result = await api.get(
    `account/search?accountName=${name}&latitude=${lat ? lat : ""}&longitude=${
      lng ? lng : ""
    }`
  );
  return result;
}
export async function accountDeactivate(data) {
  const result = await api.put(`account/deactivate`, data);
  return result;
}
export async function updateLeadQualificationStatus(leadId, data) {
  const result = await api.put(
    `leads/${leadId}/qualification-status?isLoan=false`,
    data
  );
  return result;
}

export async function getValidLeads(leadId) {
  const result = await api.get(
    `leads?pageNum=0&pageSize=10&ids=${leadId}&requiredFields=id,leadName&leadFilter=search_all`
  );
  return result;
}
export async function kamDashboardDetailApi(id) {
  const result = await api.get(`user-dashboard?resourceId=${id}`);
  return result;
}
export async function kamDashboardMeetingApi(id) {
  const result = await api.get(`user-dashboard/meeting?resourceId=${id}`);
  return result;
}
export async function syncZoomApi(leadId) {
  const result = await api.post(`meetings/sync/zoom-logs?leadId=${leadId}`);
  return result;
}
export async function getContactsCallHistoryAggregate(data) {
  const result = await api.mediaPost(
    `${CONTACT_URL}/call-history/aggregate`,
    data
  );
  return result;
}
export async function getProLanceAuthData() {
  const result = await api.get(`prolance/partner-login`);
  return result;
}
export async function getAdvocateDataApi({
  leadId = null,
  name = null,
  email = null,
  city = null,
  activeFlag = null,
}) {
  const result = await api.get(
    `loan-partner?${leadId ? `leadId=${leadId}&` : ""}${
      name ? `name=${name}&` : ""
    }${email ? `email=${email}&` : ""}${city ? `city=${city}&` : ""}${
      activeFlag ? `active=${activeFlag}` : ""
    }`
  );
  return result;
}
export async function getAdvocateByIdApi(loanId) {
  const result = await api.get(`loan-partner/${loanId}`);
  return result;
}

export async function addAdvocateApi(jsonData) {
  const result = await api.mediaPost(
    `loan-partner/${jsonData.id}/lead/${jsonData.leadId}`
  );
  return result;
}

export async function removeAdvocateApi(jsonData) {
  const result = await api.coreDelete(
    `loan-partner/${jsonData.id}/lead/${jsonData.leadId}`
  );
  return result;
}

export async function getQuotationList(leadId) {
  const result = await api.get(`prolance/project/${leadId}/quotations`);
  return result;
}
export async function releaseProLanceSession() {
  const result = await api.put(`prolance/logout`);
  return result;
}
export async function reinitiatePanVerification(data) {
  const result = await api.mediaPost(
    `vendor-onboarding/verify-pan?leadId=${data.leadId}&panNumber=${data.panNumber}`
  );
  return result;
}
export async function reinitiateBankVerification(data) {
  const result = await api.mediaPost(
    `vendor-onboarding/verify-bank?accountNumber=${data.accountNumber}&ifsc=${data.ifsc}&name=${data.name}&email=${data.email}&leadId=${data.leadId}`
  );
  return result;
}

export async function reinitiateCCRVerification(data) {
  const result = await api.mediaPost(
    `vendor-onboarding/verify-ccr/${data.leadId}`,
    data
  );
  return result;
}

export async function createCometChatGroup(leadId) {
  const result = await api.mediaPost(`leads/${leadId}/create-whatsapp-group`);
  return result;
}

export async function reSendLinkApi(leadId) {
  const result = await api.mediaPost(
    `vendor-onboarding/trigger-profile-link?leadId=${leadId}`
  );
  return result;
}
export async function reSendLegalityLinkApi(leadId) {
  const result = await api.mediaPost(
    `vendor-onboarding/trigger-legality-link?leadId=${leadId}`
  );
  return result;
}
export async function reSendPANVerifyLinkApi(leadId) {
  const result = await api.mediaPost(
    `vendor-onboarding/verify-pan?leadId=${leadId}`
  );
  return result;
}
export async function reSendBankVerifyLinkApi(leadId) {
  const result = await api.mediaPost(
    `vendor-onboarding/verify-bank?leadId=${leadId}`
  );
  return result;
}
export async function reSendCCRVerifyLinkApi(leadId) {
  const result = await api.mediaPost(
    `vendor-onboarding/verify-ccr?leadId=${leadId}`
  );
  return result;
}
export async function reSendOnboardLinkApi(leadId) {
  const result = await api.mediaPost(
    `vendor-onboarding/onboard-vendor?leadId=${leadId}`
  );
  return result;
}
export async function reOpenClosure(leadId, closureStatus) {
  const result = await api.mediaPost(
    `leads/${leadId}/re-open/closure?closureStatus=${closureStatus}`
  );
  return result;
}

export async function fetchWhatsappHistory(
  pageNum,
  pageSize,
  notificationType,
  partyId,
  leadId,
  isSales,
  isUniview
) {
  const result = await api.get(
    `notification-report?pageNum=${pageNum}&pageSize=${pageSize}&notificationType=${notificationType}&messageCount=100&partyId=${partyId}${
      !partyId ? `&leadId=${leadId}` : ""
    }&isSales=${isSales}${isUniview ? `&isUniview=${isUniview}` : ""}`
  );
  return result;
}

export async function getCallLogParticipantsApi(leadId, leadType) {
  const result = await api.get(
    `contacts/call-history/participants?leadId=${leadId}&leadType=${leadType}`
  );
  return result;
}

export async function getQuoteInfoById(id) {
  const result = await api.get(`quotes/quote?quoteNumber=${id}`);
  return result;
}
export async function saveMinutesOfMeetingData(data) {
  const result = await api.formDataPost(`meetings/minutes-of-meeting`, data);
  return result;
}
export async function getMinutesOfMeetingData(id) {
  const result = await api.get(`meetings/minutes-of-meeting?meetingId=${id}`);
  return result;
}
export async function updateMinutesOfMeetingData(data) {
  const result = await api.formDataPut(`meetings/minutes-of-meeting`, data);
  return result;
}
export async function deleteMinutesOfMeeting(id) {
  const result = await api.coreDelete(`meetings/minutes-of-meeting?id=${id}`);
  return result;
}
export async function assignJob(data) {
  const result = await api.mediaPost(`products/assign`, data);
  return result;
}

export async function importWorkOrderApi(id) {
  const result = await api.mediaPost(`external/add/products/${id}`);
  return result;
}
export async function shareMyBookingsLink(data) {
  const result = await api.mediaPost(`whatsapp/my-booking-details`, data);
  return result;
}
export async function importProducts(data) {
  const result = await api.formDataPost(`products/import`, data);
  return result;
}

export async function getNBUserDetails(id) {
  const result = await api.get(`external/nbuser/info?nbUserId=${id}`);
  return result;
}
export async function getKycDetailsById(id) {
  const result = await api.get(`cpms/kyc-info?userId=${id}`);
  return result;
}
export async function sendKycForm(id) {
  const result = await api.mediaPost(`cpms/initiate-kyc?partyId=${id}`);
  return result;
}
export async function initiateCCV(data) {
  const result = await api.mediaPost(`user-ccr/initiate`, data);
  return result;
}
export async function verifyKycDocuments(id, kycidType, idNumber) {
  const result = await api.mediaPost(
    `cpms/kyc-verification?partyId=${id}&kycidType=${kycidType}&idNumber=${idNumber}`
  );
  return result;
}
export async function getEmails(id) {
  const result = await api.get(`tejas-email?entityId=${id}`);
  return result;
}

export async function getEmailBodyData(id) {
  const result = await api.get(`tejas-email/id?id=${id}`);
  return result;
}

export async function getWorkOrderSummaryApi(leadId, pageNum, pageSize = 5) {
  const result = await api.get(
    `work-order/v2?leadId=${leadId}&pageNum=${
      pageNum ? pageNum : 0
    }&pageSize=${pageSize}`
  );
  return result;
}

export async function revisionHistoryApi(
  leadId,
  vendorId,
  pageNum,
  pageSize = 10
) {
  const result = await api.get(
    `work-order/revision-history?leadId=${leadId}&vendorId=${vendorId}&pageNum=${
      pageNum ? pageNum : 0
    }&pageSize=${pageSize}`
  );
  return result;
}

export async function getSignedUrlForImage(data) {
  const result = await api.mediaPost(`files/signUrls`, data);
  return result;
}
export async function fetchFileData(data, pageNum) {
  const result = await api.get(
    `leads/docs?pageSize=25&pageNum=${pageNum}`,
    data
  );
  return result;
}

export async function fetchFolderDataApi(data) {
  const result = await api.mediaPost(`leads/doc-groups`, data);
  return result;
}

export async function createFolderApi(data) {
  const result = await api.mediaPost(`leads/document-group`, data);
  return result;
}
export async function fetchFolderContentApi(data) {
  const result = await api.mediaPost(`leads/docs`, data);
  return result;
}

export async function fetchLeadFilesApi(data, pageNum, pageSize = 10) {
  const result = await api.mediaPost(
    `leads/docs-paginated?pageNum=${pageNum}&pageSize=${pageSize}`,
    data
  );
  return result;
}

export async function uploadAssetsToFolderApi(data) {
  const result = await api.mediaPost(`leads/docs`, data);
  return result;
}

export async function uploadAssetToFolder(data) {
  const result = await api.mediaPost(`leads/upload-documents`, data);
  return result;
}
