import * as api from "./api";

export async function getUserProfile() {
  const result = await api.get("user/profile");
  if (result.status === 401) {
    localStorage.removeItem("accessToken");
    window.location = "/";
  } else {
    return result;
  }
}
export async function postPunchInOut(data = "punch-in", payload) {
  const result = await api.mediaPost(`user/${data}`, payload);
  return result;
}
export async function changePassword(data) {
  const result = await api.put(`user/updatePassword`, data);
  return result;
}
export async function resetPassword(data) {
  const result = await api.mediaPost(`auth/reset`, data);
  return result;
}
export async function postStatus(data) {
  const result = await api.mediaPost(`resourceTimeline`, data);
  return result;
}
export async function getStatus(resourceId, endDate) {
  const result = await api.get(
    `resourceTimeline/currentStatus/${resourceId}?currDate=${endDate}`
  );
  return result;
}
export async function endBreakApi(resourceTimelineId, body) {
  const result = await api.put(
    `resourceTimeline/update/${resourceTimelineId}?endBreak=true`,
    body
  );
  return result;
}
export async function WareTrack() {
  const result = await api.customGet(
    "https://waretrack.nobroker.in/api",
    "profileDetails"
  );
  return result;
}
