import React, { useState } from "react";
import "./FileUpload.scss";
import Button from "./Button";

const EXCEL_FORMATE = ".csv";
const IMAGE_FORMATE = ".png,.jpeg,.jpg";
// const ALL_FORMATE = ".*";

const FileUpload = (props) => {
  const { onChange, onlImage, onlyExcel } = props;
  const [imageURI, setUri] = useState(null);
  const readURI = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (ev) => {
        setUri(ev.target.result || imageURI);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleChange = (e) => {
    readURI(e);
    if (onChange !== undefined) onChange(e.target.files);
    e.target.value = null;
  };
  return (
    <div styleName="file-base">
      <div styleName="file-content">
        <label styleName="file-imageLabel" htmlFor="single">
          {props.onlyButton ? (
            <div styleName="fileChoose">{props.label}</div>
          ) : (
            <div styleName="file-container">
              <div styleName={"flex"}>
                <div>
                  <Button
                    label="Attach CSV File"
                    type="quaternary"
                    height="26px"
                    fontSize="12px"
                    width="108px"
                  />
                </div>
                {/* <div styleName="flex-with-ml50"> */}
                <a href={props.sampleCSV}>
                  <div styleName="download">Sample CSV</div>
                </a>
                {/* </div> */}
              </div>
            </div>
          )}
        </label>
        <input
          type="file"
          id="single"
          style={{ width: "108px" }}
          onChange={handleChange}
          accept={onlyExcel ? EXCEL_FORMATE : onlImage ? IMAGE_FORMATE : ""}
          multiple={props.multiple}
        />
      </div>
    </div>
  );
};

export default FileUpload;
