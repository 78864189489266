import * as api from "./api";
import { paramsToObject } from "../utils/validations";

export async function getResource(
  pageNumber = 1,
  pageSize = 25,
  searchParam,
  hierarchy = "",
  child = true
) {
  let searchQuery = searchParam ? searchParam.replace("?", "&") : "";
  const urlParams = new URLSearchParams(searchParam);
  let initialValues = paramsToObject(urlParams.entries());
  const result = await api.get(
    `resources/page-view?${
      hierarchy && !initialValues.hierarchy ? `hierarchy=${hierarchy}&` : ``
    }pageNum=${pageNumber}&pageSize=${pageSize}${
      child ? "&child=true" : ""
    } &includeSelf=true&${searchQuery ? searchQuery : ""}`
  );
  return result;
}
export async function getMeetingResources(payload) {
  const result = await api.mediaPost("meetings/available-frm", payload);
  return result;
}
export async function DDgetResource(pageNumber = 1, pageSize = 25) {
  const result = await api.get(
    `resources/page-view?pageNum=${pageNumber}&pageSize=${pageSize}`
  );
  return result;
}
export async function addResource(data) {
  const result = await api.mediaPost("resources", data);
  return result;
}
export async function editResource(data) {
  const result = await api.put(`resources/edit`, data);
  return result;
}

export async function getResourceHistory(resourceId, hierarchy = "") {
  const result = await api.get(
    `resources/history/${resourceId}${hierarchy && `?hierarchy=${hierarchy}`}`
  );
  return result;
}

export async function getManagerResource(roleId) {
  const result = await api.get(`resources/getEligibleManagersByRole/${roleId}`);
  return result;
}
export async function getManagerResourceInEdit(roleId) {
  const result = await api.get(`resources/getEligibleManagers/${roleId}`);
  return result;
}
export async function editManager(data) {
  const result = await api.mediaPost("resources/assign", data);
  return result;
}
export async function enableGeoTracker(resources, action) {
  const result = await api.put(
    `resources/bulkEnableGeoTracking?resourceIds=${resources}&isTrackingRequired=${action}`
  );
  return result;
}
export async function getResourceTree(id) {
  let result = await api.get(`resources/tree?resourceId=${id ? id : ""}`);
  return result;
}
export async function getResourceParentTree(id) {
  let result = await api.get(`resources/tree/${id}`);
  return result;
}
export async function computeHierarchy(id) {
  const result = await api.mediaPost(`resources/recompute/${id}`);
  return result;
}
export async function addslashrtcUserName(resourceId, slashrtcUserName) {
  const result = await api.mediaPost(
    `${resourceId}/slashrtc/${slashrtcUserName}`
  );
  return result;
}
export async function deleteslashrtcUserName(resourceId, slashrtcUserName) {
  const result = await api.coreDelete(
    `${resourceId}/slashrtc/${slashrtcUserName}`
  );
  return result;
}
export async function deactivateResource(data, status) {
  const result = await api.put(
    `resources/deactivate${status ? "?reAssign=true" : ""}`,
    data
  );
  return result;
}
export async function firebaseActivation(resourceId, status) {
  const result = await api.mediaPost(
    `app/firebase/${resourceId}/${status ? "activate" : "deactivate"}`
  );
  return result;
}
export async function getDesignation(hierarchy = "") {
  const result = await api.get(
    `lov/unv_resource_designation/${hierarchy ? hierarchy : ""}`
  );
  return result;
}
export async function getResourceDetails(resourceId) {
  const result = await api.get(`resources/deactivation/data/${resourceId}`);
  return result;
}
export async function de_activateResource(resourceId, managerId = "") {
  const result = await api.put(
    `resources/deactivate?resourceId=${resourceId}&managerId=${managerId}`
  );
  return result;
}
export async function getResourcePipelines(pageNum, pageSize) {
  const result = await api.get(
    `pipelines?pageNum=${pageNum}&pageSize=${
      pageSize || 200
    }&name=RESOURCE_DEACTIVATION`
  );
  return result;
}
export async function getResourcePipeline(entity, parent, pageSize = 200) {
  const result = await api.get(
    `pipelines?pageNum=0&pageSize=${
      pageSize || 200
    }&name=RESOURCE_DEACTIVATION&entityId=${entity}&parentId=${parent}`
  );
  return result;
}
export async function updateReadPipelineStatus(data) {
  const result = await api.patch(`pipelines/updateReadStatus`, data);
  return result;
}
export async function reStartPipeline({
  pipelineId,
  pipelineName = "RESOURCE_DEACTIVATION",
}) {
  const result = await api.put(
    `pipelines?pipelineId=${pipelineId}&pipelineName=${pipelineName}`
  );
  return result;
}
export async function getResourceRoleSet(resourceId) {
  const result = await api.get(
    `roles/role-set?resourceId=${resourceId}&includeCurrentRoles=true`
  );
  return result;
}
export async function addResourceAddtionalRole(resourceId, data) {
  const result = await api.mediaPost(
    `roles/role-set?partyId=${resourceId}`,
    data
  );
  return result;
}
export async function getResourceGroup() {
  const result = await api.get(`groups`);
  return result;
}
export async function createResourceGroup(data) {
  const result = await api.mediaPost(`groups`, data);
  return result;
}
export async function updateResourceGroup(data) {
  const result = await api.put(`groups`, data);
  return result;
}
export async function fetchResourceFromGroup(id) {
  const result = await api.get(`groups/${id}/resource`);
  return result;
}
export async function addResourceToGroup(id, data) {
  const result = await api.mediaPost(`groups/${id}/resource`, data);
  return result;
}
export async function removeResourceToGroup(id, data) {
  const result = await api.coreDelete(`groups/${id}/resource`, data);
  return result;
}
export async function getSourceByResoureGroupId(id, entityType = "source") {
  const result = await api.get(
    `entity-mapping?entityType=${entityType}&groupId=${id}`
  );
  return result;
}
export async function getfieldIdDataApi(id) {
  const result = await api.get(`nbsocial/access?groupId=${id}`);
  return result;
}
export async function addEntityMapping(data) {
  const result = await api.mediaPost(`entity-mapping`, data);
  return result;
}
export async function removeEntityMapping(data) {
  const result = await api.coreDelete(`entity-mapping`, data);
  return result;
}
export async function addChannelMapping(data) {
  const result = await api.mediaPost(`nbsocial/access/map`, data);
  return result;
}
export async function removeChannelMapping(data, groupId) {
  const result = await api.coreDelete(
    `nbsocial/access/unmap?groupId=${groupId}`,
    data
  );
  return result;
}
export async function getBusinessUnits() {
  const result = await api.get(`business-units/settings`);
  return result;
}
export async function getGroupEntities(entityType, resourceId) {
  const result = await api.get(
    `entity-mapping?entityType=${entityType}&resourceId=${resourceId}`
  );
  return result;
}

export async function getsubChannelLov() {
  const result = await api.get(`lov/SUB_CHANNEL`);
  return result;
}
