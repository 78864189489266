import React, { Component } from "react";
import "./CheckBox.scss";
import { lovCodeClean } from "../../utils/utils";

export default class CheckBox extends Component {
  handleSelection = (val) => {
    if (this.props.multiple) {
      if (this.props.onSelect) {
        const selected = this.props.selected ? this.props.selected : [];
        let key = [];
        if (this.props.selected) {
          key = key.concat(this.props.selected);
        }
        if (!selected.includes(val.value)) {
          key.push(val.value);
          this.props.onSelect(key);
        } else {
          const filteredOptions = key.filter((value) => {
            return value !== val.value;
          });
          this.props.onSelect(filteredOptions);
        }
      }
    } else {
      let isSelected =
        this.props.selected && this.props.selected.includes(val.value)
          ? true
          : false;
      if (this.props.onSelect) {
        this.props.onSelect(isSelected ? null : val.value);
      }
    }
  };
  render() {
    return (
      <div>
        <div
          styleName="single-base"
          style={{ display: this.props?.fromGenerate && "block" }}>
          {this.props.options &&
            this.props.options.map((val) => {
              return (
                <div
                  styleName="single-row"
                  key={val}
                  style={{
                    width: this.props.width,
                    margin: this.props?.fromGenerate && "20px",
                  }}>
                  <div
                    title={val.title}
                    styleName={
                      this.props.selected &&
                      this.props.selected.length > 0 &&
                      this.props.selected.includes(val.value)
                        ? val.disabled
                          ? "button-disable"
                          : "button-active"
                        : "button"
                    }
                    onClick={() =>
                      !val.disabled && this.handleSelection(val)
                    }></div>
                  <div styleName="label" style={{ color: this.props.color }}>
                    {val.label}
                  </div>
                  {val?.displayLabel && (
                    <>
                      <div styleName="display-label">{val.displayLabel}</div>
                      {val.designation && (
                        <div styleName="designation">
                          ({lovCodeClean(val.designation)})
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
        </div>
        {this.props.error && <div styleName="error">{this.props.error}</div>}
      </div>
    );
  }
}
