import React from "react";
import "./CircularProgressBar.scss";

function CircularProgressBar(props) {
  // Size of the enclosing square
  const sqSize = props.sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize - props.strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const percentage = (props.actualValue / props.totalValue) * 100;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  const dashOffsetFn = (value) => {
    const percentage = (value / props.pieTotalValue) * 100;
    return dashArray - (dashArray * percentage) / 100;
  };
  const arcPosition = (lastPoint) => {
    const rotateValue =
      props?.pieData?.reduce((acc, curr, index) => {
        if (lastPoint < index) return curr?.total + acc;
        return acc;
      }, 0) / props?.pieTotalValue;
    return `rotate(${rotateValue * 360} ${props?.sqSize / 2} ${
      props?.sqSize / 2
    })`;
  };
  return (
    <div styleName="base">
      <svg width="100%" viewBox={viewBox}>
        {props?.pieData?.length > 1 ? (
          props?.pieData?.map((item, index) => {
            return (
              <circle
                key={index}
                styleName="circle-progress"
                cx={props?.sqSize / 2}
                cy={props?.sqSize / 2}
                r={radius}
                strokeWidth={`${props?.strokeWidth}px`}
                transform={arcPosition(index)}
                style={{
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffsetFn(item?.total),
                  stroke: props?.pieColor[index],
                  color: props?.pieColor[index],
                }}
              />
            );
          })
        ) : (
          <>
            <circle
              styleName="circle-background"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              style={{ stroke: props.background }}
            />
            <circle
              styleName="circle-progress"
              cx={props?.sqSize / 2}
              cy={props?.sqSize / 2}
              r={radius}
              strokeWidth={`${props?.strokeWidth}px`}
              transform={`rotate(-90 ${props?.sqSize / 2} ${
                props?.sqSize / 2
              })`}
              style={{
                strokeDasharray:
                  !props?.kamdashboard ||
                  props?.actualValue ||
                  props?.actualValue === 0
                    ? dashArray
                    : 0,
                strokeDashoffset:
                  !props?.kamdashboard ||
                  props?.actualValue ||
                  props?.actualValue === 0
                    ? dashOffset
                    : 0,
                stroke:
                  props?.actualValue || props?.actualValue === 0
                    ? props.color
                    : props.totalColor,
              }}
            />
          </>
        )}

        {props?.innerText && (
          <text
            styleName="inner-text"
            x="50%"
            y="50%"
            textAnchor="middle"
            style={{
              fill: "#8C8C8C",
              ...props.innerTextStyle,
            }}>
            {props?.innerText}
          </text>
        )}
        {props?.kamdashboard &&
          !props?.actualValue &&
          !props?.innerText &&
          props?.actualValue !== 0 && (
            <text
              styleName="circle-text"
              x="50%"
              y="50%"
              dy=".3em"
              textAnchor="middle">
              {props?.totalValue}
            </text>
          )}
        {(props?.pieTotalValue || props?.pieTotalValue === 0) && (
          <text
            styleName="total-text"
            x="50%"
            y="60%"
            dy=".3em"
            style={{ fill: "#8C8C8C" }}
            textAnchor="middle">
            ({props?.pieTotalValue})
          </text>
        )}
      </svg>
      {props?.kamdashboard &&
        !props?.innerText &&
        (props?.totalValue || props?.totalValue === 0) &&
        (props?.actualValue || props?.actualValue === 0) && (
          <text
            styleName="circle-text-ratio"
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle">
            {`${props?.actualValue || 0}/${props?.totalValue}`}
          </text>
        )}
    </div>
  );
}

export default CircularProgressBar;
