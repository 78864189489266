import React, { Component } from "react";
import {
  getLocationDetailsByPlaceId,
  getSocietyAddressList,
} from "../api/dashboard";
import { debounce, objectToLocationArray } from "../utils/utils";
import DropDownWithSearch from "./common/DropDownWithSearch";
import { v4 as uuid } from "uuid";
export default class AutoLocationSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchAddress: [],
      value: "",
    };
    this.searchText = debounce(this.searchText.bind(this), 1000);
    this.unique_id = uuid();
  }
  async searchText(val) {
    const jsonData = {
      city: this.props.city?.toLowerCase(),
      landmark: val,
      requestId: this.unique_id,
    };
    let result = await getSocietyAddressList(jsonData);
    if (result) {
      const addressList = result?.predictions
        ? [...objectToLocationArray(result?.predictions)]
        : [];
      this.setState({ fetchAddress: addressList });
    }
  }
  getLocationDetailsByPlaceId = async ({value: placeId, label: selected}) => {
    let response = await getLocationDetailsByPlaceId(placeId);
    if (response.status === "OK") {
      this.setState({ value: placeId });
      this.props.onSelect({
        value: placeId,
        selected,
        latitude: response?.result?.geometry?.location?.lat,
        longitude: response?.result?.geometry?.location?.lng,
        locationName: response?.result?.name,
        locationAddress: response?.result?.formatted_address,
        types: response?.result?.types,
      });
    }
  };

  componentDidMount() {
    if (this.props.placeId) {
      // this.searchText(this.props.selected?.substring(0, 10));
      // this.getLocationDetailsByPlaceId(this.props.placeId);
      const {placeId, selected} = this.props;
      // set previously selected placeId and place label
      this.setState({value: placeId, fetchAddress: [{value: placeId, label: selected}]});
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected && !this.props.selected) {
      this.setState({ value: "" });
    }
  }
  render() {
    const fetchAddress = this.state.fetchAddress;
    return (
      <DropDownWithSearch
        options={fetchAddress && fetchAddress.length > 0 ? fetchAddress : []}
        tabIndex="0"
        disabled={this.props.disabled}
        showSearch={true}
        onSearch={(val) => this.searchText(val)}
        onClear={this.props.onClear}
        selected={this.state.value}
        single={true}
        filter={false}
        placeholder={this.props.placeholder || "Search Address"}
        onSelect={(val) => {
          this.getLocationDetailsByPlaceId(val);
        }}
        error={this.props.error}
        errorMessages={this.props.errorMessages || this.props.error}
        id={this.props.id}
      />
    );
  }
}
