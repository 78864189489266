// import { InView } from "react-intersection-observer";
// import React from "react";
// export default function Observer(props) {
//   return (
//     <InView as="div" onChange={(inView, entry) => props.inView(inView, entry)}>
//       {props.children}
//     </InView>
//   );
// }
import React, { Component } from "react";

export default class Observer extends Component {
  constructor(props) {
    super(props);
    this.Observer = React.createRef();
    this.lastBookRef = (node) => {
      if (this.Observer.current) this.Observer.current.disconnect();
      this.Observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.props.inView && this.props.inView();
        }
      });
      if (node) this.Observer.current.observe(node);
    };
  }
  render() {
    return <div ref={this.lastBookRef}>{this.props.children}</div>;
  }
}
