import * as api from "./api";

export async function listAllIntegrations() {
  const urlParams = new URLSearchParams(location.search);
  const type = urlParams.get("type");
  const active = urlParams.get("all");
  const result = await api.get(
    `integration${type || !active ? "?" : ""}${type ? `type=${type}` : ""}${
      type || !active ? "&" : ""
    }${!active ? `active=true` : ""}`
  );
  return result;
}
export async function updateIntegration(payload) {
  const result = await api.put(`integration`, payload);
  return result;
}
export async function getByIdIntegration(id) {
  const result = await api.get(`integration${id ? `?name=${id}` : ""}`);
  return result;
}

export async function getAgentDetailsApi(id) {
  const result = await api.get(`calling/agent?providerId=${id}`);
  return result;
}
export async function deleteAgentDetailsApi(id) {
  const result = await api.coreDelete(`calling/agent?agentId=${id}`);
  return result;
}
export async function updateAgentDetailsApi(data) {
  const result = await api.put(`calling/agent`, data);
  return result;
}
export async function addAgentDetailsApi(data) {
  const result = await api.mediaPost(`calling/agent`, data);
  return result;
}
export async function uploadBulkAgentDetailsApi(data, providerId) {
  const result = await api.imagePost(`calling/agent/bulk/${providerId}`, data);
  return result;
}
