import axios from "axios";
//import axios from "../../../utils/axios";
// import adminFirebase from "../../../utils/adminFirebase";

import { bridgeCall } from "../../api/dashboard";
import { getCookie } from "../../utils/cookie";

// import adminFirebase from "../../utils/adminFirebase";

// export class callIntegration {
//   static callData = {};

//   static clickToCallListener(
//     { email, phone, maskedPhone, name },
//     loggedInUserData
//   ) {
//     const currentPhoneNo =
//       process.env.NODE_ENV === "production" ? phone : "9038294623";
//     const currentHashedPhoneNo = maskedPhone;
// const iframe = document.getElementById("callingCrmIframe");
//     const currentUserName = name;
//     const currentUserEmail = email;
//     // const crmHref = undefined;
//     // if (crmHref) {
//     //   iframe.src = `https://www.nobroker.in${crmHref}`;
//     // }
//     const callObject = {
//       currentPhoneNo,
//       currentHashedPhoneNo,
//       currentUserName,
//       currentUserEmail,
//       type: "callingCheck",
//       isUserEditing: false,
//     };
//     callIntegration.isCallAllowed(callObject, loggedInUserData);
//   }

//   static async isCallAllowed(data, loggedInUserData = {}) {
//     if (data && typeof data === "object") {
//       if (data.type && data.type === "callingCheck") {
//         let isCallAllowed = true;
//         const callRuleData = await adminFirebase.isCallRulesApplied();
//         if (callRuleData) {
//           isCallAllowed = callIntegration.checkIfCallAllowRulePassed(
//             data.currentPhoneNo,
//             data.isUserEditing,
//             loggedInUserData
//           );
//         }
//         window.triggerCallingModal(true);
//         window.postMessage(
//           {
//             isCallAllowed,
//             customData: data.leadId,
//             currentPhoneNo: data.currentPhoneNo,
//             currentHashedPhoneNo: data.currentHashedPhoneNo,
//             currentUserName: data.currentUserName,
//             currentUserEmail: data.currentUserEmail,
//           },
//           "*"
//         );
//       }
//     }
//   }

//   /* Method to check if teh call rules is passed or not  */
//   static checkIfCallAllowRulePassed(
//     phone,
//     isUserEditing,
//     loggedInUserData = {}
//   ) {
//     const numberReg = new RegExp("^\\d+$");
//     if (phone.length === 13 && phone.startsWith("+91")) {
//       phone = phone.replace("+91", "");
//     }
//     if (phone.length === 12 && phone.startsWith("91")) {
//       phone = phone.replace("91", "");
//     }
//     if (!numberReg.test(phone)) {
//       phone = atob(phone);
//     }
//     if (callIntegration.callData[phone]) {
//       if (
//         callIntegration.callData[phone].eventType === "callOriginate" ||
//         callIntegration.callData[phone].eventType ===
//           "callRingingWithEarlyMedia" ||
//         callIntegration.callData[phone].eventType === "callAnswered"
//       ) {
//         const currentEpoch = new Date().getTime();
//         const timeDiff = parseInt(
//           (currentEpoch - callIntegration.callData[phone].eventEpoch) /
//             1000 /
//             3600
//         );
//         if (timeDiff < 1) {
//           //   $('#callingScreenSidebar').removeClass('active');
//           //   $('#callingCrmSlashrtc').removeClass('activeCall');
//           //   if (!$('#ongoingCallWarning').is(':visible')) {
//           //     $('#ongoingCallWarning').modal('show');
//           //   }
//           /* Method To register an event for those click which are made on click to call during the call in progress */
//           const eventUrl = "/createEvent";
//           const ruleData = {
//             loggedInUserEmail: loggedInUserData.email || "",
//             loggedInUserPhone: loggedInUserData.phone || "",
//             loggedInUserName: loggedInUserData.name || "",
//             userRoles: window.localStorage.getItem("userRoles"),
//             customerPhone: phone,
//             userAgentName: callIntegration.callData.userAgentName,
//             dashboardUrl: window.location.pathname,
//             rule: "ongoingCall",
//           };
//           const params = {
//             type: "callRules",
//             rule_data: JSON.stringify(ruleData),
//           };
//           axios.post(eventUrl, params);
//           return false;
//         }
//         return true;
//       }

//       if (isUserEditing) {
//         callIntegration.registerUserPostingEvent(undefined, loggedInUserData);
//         return false;
//       }
//       return true;
//     }
//     if (isUserEditing) {
//       callIntegration.registerUserPostingEvent(undefined, loggedInUserData);
//       return false;
//     }
//     return true;
//   }

//   /* Method to register event if user is posting his own property */
//   static registerUserPostingEvent(phone, loggedInUserData) {
//     // if (!$('#ongoingCallWarning').is(':visible')) {
//     //   $('#callStopResonText').text("User is currently posting his property, can't make a call now");
//     //   $('#ongoingCallWarning').modal('show');
//     // }
//     const eventUrl = "/createEvent";
//     const ruleData = {
//       loggedInUserEmail: loggedInUserData.email,
//       loggedInUserPhone: loggedInUserData.phone,
//       loggedInUserName: loggedInUserData.name,
//       userRoles: window.localStorage.getItem("userRoles"),
//       customerPhone: phone,
//       dashboardUrl: window.location.pathname,
//       rule: "New Property",
//     };
//     const params = {
//       type: "callRules",
//       rule_data: JSON.stringify(ruleData),
//     };
//     axios.post(eventUrl, params);
//   }
// }
export function maskNumber(number) {
  if (number) {
    const numString = number?.toString();
    const numArray = numString?.split("");
    const length = numArray.length;
    const maskedNumber = numArray.map((val, i) => {
      if (i > 1 && i < length - 2) {
        return "*";
      } else return val;
    });
    return maskedNumber.join("");
  }
}
export function maskEmail(myemailId) {
  let maskid = "";
  let prefix = myemailId.substring(0, myemailId.lastIndexOf("@"));
  let postfix = myemailId.substring(myemailId.lastIndexOf("@"));

  for (let i = 0; i < prefix.length; i++) {
    if (i == 0 || i == prefix.length - 1) {
      maskid = maskid + prefix[i].toString();
    } else {
      maskid = maskid + "*";
    }
  }
  maskid = maskid + postfix;
  return maskid;
}
export async function call(data) {
  let callType = getCookie("remotecall");
  if (callType) {
    bridgeCall(data?.currentPhoneNo);
  } else {
    try {
      const url = `https://www.nobroker.in/api/v1/admin/assignment/user/call-check?phone=${data.currentPhoneNo}`;
      const response = await axios.get(url);
      const responseText = response?.data;
      if (responseText.status === 200 && responseText.statusCode === 200) {
        if (responseText.data === false) {
          window.postMessage(
            {
              isCallAllowed: true,
              customData: data.customData ? data.customData : "",
              currentPhoneNo: data.currentPhoneNo,
              currentHashedPhoneNo: maskNumber(data.currentPhoneNo),
              currentUserName: data.currentUserName,
              currentUserEmail: data.currentUserEmail,
            },
            "*"
          );
        } else {
          window.alert("You are not allowed to call this Customer.");
        }
      }
    } catch (errorInfo) {
      console.log(errorInfo);
      window.postMessage(
        {
          isCallAllowed: true,
          customData: data.customData ? data.customData : "",
          currentPhoneNo: data.currentPhoneNo,
          currentHashedPhoneNo: maskNumber(data.currentPhoneNo),
          currentUserName: data.currentUserName,
          currentUserEmail: data.currentUserEmail,
        },
        "*"
      );
    }
  }
}
