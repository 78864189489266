import React from "react";
import { momentFormat } from "../utils/utils";
import "./NotificationCard.scss";
export default function NotificationCard({ data, onRead, readNotifications }) {
  const additionalProperties = data.additionalProperties;
  const loan = localStorage.getItem("isLoan");
  const isLoan = loan === "true" ? true : false;
  const leadUrl = isLoan
    ? additionalProperties?.eventValue === "SERVICE"
      ? "/service-loan"
      : "/sales-loan"
    : additionalProperties?.eventValue === "SERVICE"
    ? "/lead-service"
    : "/";

  const redirectTo = () => {
    switch (additionalProperties?.eventType) {
      case "APPROVAL": {
        window.open(
          `my-approval?filter=search_all&id=${additionalProperties?.entityId}&status=${additionalProperties?.eventValue}`
        );
        break;
      }
      case "LEAD": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "SERVICE_LEAD": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "CONTACT": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "TICKET": {
        window.open(
          `tickets?ticketFilter=tickets_assigned_to_me&type=${additionalProperties?.eventValue?.toUpperCase()}&ticketId=${
            additionalProperties?.entityId
          }`
        );
        break;
      }
      case "MEETING": {
        window.open(
          `${leadUrl}?leadFilter=all&meetingsFilter.id=${additionalProperties?.entityId}`
        );
        break;
      }
      case "BULK_IMPORT": {
        window.open(`/lead-import`);
        break;
      }
      case "TASK": {
        window.open(
          `/tasks?taskId=${additionalProperties?.entityId}#${additionalProperties?.eventValue}`
        );
        break;
      }
      case "JOB": {
        window.open(
          `/jobs?id=${additionalProperties?.entityId}#${additionalProperties?.eventValue}`
        );
        break;
      }
      case "DOCUMENT": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "NOTE": {
        notesDeepLinking();
        break;
      }
      case "AGREEMENT": {
        window.open(additionalProperties.webUrl);
        break;
      }
      case "SOCIAL_PLUGIN_TICKET": {
        window.open(additionalProperties.webUrl);
        break;
      }
      default:
        return null;
    }
  };
  const notesDeepLinking = () => {
    switch (additionalProperties?.noteEntityType) {
      case "TICKET": {
        window.open(
          `tickets?ticketFilter=tickets_assigned_to_me&type=${additionalProperties?.eventValue?.toUpperCase()}&ticketId=${
            additionalProperties?.entityId
          }`
        );
        break;
      }
      case "LEAD": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "SERVICE_LEAD": {
        window.open(`${leadUrl}?leadId=${additionalProperties?.entityId}`);
        break;
      }
      case "CONTACT": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "MEETING": {
        window.open(
          `${leadUrl}?leadFilter=all&leadId=${additionalProperties?.entityId}`
        );
        break;
      }
      case "SERVICE_JOB": {
        window.open(
          `/jobs?id=${additionalProperties?.entityId}#${additionalProperties?.eventValue}`
        );
        break;
      }
      case "APPROVALS": {
        window.open(
          `my-approval?filter=search_all&id=${additionalProperties?.entityId}&status=${additionalProperties?.eventValue}`
        );
        break;
      }
      case "INVENTORY": {
        window.open(`/inventory?id=${additionalProperties?.entityId}`);
        break;
      }
      default:
        return null;
    }
  };
  const iconMapping = () => {
    switch (additionalProperties?.eventType) {
      case "TICKET":
        return "ticket";

      case "NOTE":
        return "note";

      case "MEETING":
        return "meeting";

      case "LEAD":
        return "lead";

      case "JOB":
        return "job";

      case "APPROVAL":
        return "approval";

      case "TASK":
        return "task";

      case "CONTACT":
        return "contact";

      case "DOCUMENT":
        return "document";

      case "BULK_IMPORT":
        return "bulk-import";

      default:
        return "icon";
    }
  };
  const deepLinkSupportTag = [
    "APPROVAL",
    additionalProperties?.entityId && "LEAD",
    "TICKET",
    "CONTACT",
    "MEETING",
    "BULK_IMPORT",
    "TASK",
    "JOB",
    "NOTE",
    "DOCUMENT",
    "SERVICE_LEAD",
    "AGREEMENT",
  ];
  return (
    <div
      styleName="base"
      style={{
        background: !readNotifications.includes(data.messageId)
          ? !data.isRead
            ? "#fff"
            : ""
          : "",
      }}>
      <div styleName="flex">
        <div styleName={iconMapping()} />
        <div styleName="title">{additionalProperties?.title}</div>
      </div>
      <div styleName="description">{additionalProperties.message}</div>
      <div styleName="flex-btw">
        <div styleName="date-info">
          {momentFormat(data.createdOn, "hh:mm A, DD MMM YYYY")}
        </div>
        {/* <div styleName="warning">Due by 04:30 PM, 08 Aug 2022</div> */}
      </div>
      {!readNotifications.includes(data.messageId) && !data.isRead && (
        <div styleName="button-holder">
          <div
            styleName={
              deepLinkSupportTag?.includes(additionalProperties?.eventType)
                ? "button"
                : "button-active"
            }
            onClick={() => onRead(data.messageId)}>
            Mark as Read
          </div>
          {deepLinkSupportTag?.includes(additionalProperties?.eventType) && (
            <div styleName="button" onClick={redirectTo}>
              View Details
            </div>
          )}
        </div>
      )}
    </div>
  );
}
