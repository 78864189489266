import React, { memo, useRef, useState } from "react";
import "./SocialCommonHeader.scss";
import ModalRightPanel from "../common/modal/ModalRightPanel";
import NotificationContainer from "../../containers/NotificationContainer";
import Toast from "../common/Toasts/Toast";
import Button from "../common/Button";
import ProfileContainer from "../../containers/ProfileContainer";
import { getUserProfile, postPunchInOut } from "../../api/profile";
import moment from "moment";
function SocialCommonHeader({
  history,
  openModal,
  notificationCount,
  closeModal,
  universalLabels,
  profile,
  update,
}) {
  const [flag, setflag] = useState(false);
  const [loading, setloading] = useState(false);
  const label = universalLabels ? universalLabels : {};
  const toastRef = useRef();
  let attendance = profile?.attendance;
  const navList = [
    {
      label: "Overview",
      icon: "dashboard",
      path: "/social-dashboard",
      active: location?.pathname === "/social-dashboard",
    },
    {
      label: "Social Tickets",
      icon: "ticket",
      path: "/social-tickets",
      active: location?.pathname?.includes("/social-tickets"),
    },
    {
      label: "CRM",
      icon: "crm",
      path: "/social-customer",
      active: location?.pathname === "/social-customer",
    },
  ];
  const showToast = (toast) => {
    toastRef.current.addToast({
      type: toast.type,
      message: toast.message,
    });
  };
  const getProfile = async () => {
    const result = await getUserProfile();
    if (result.status === 1 && result.data && result.data.id) {
      update({
        profile: result.data,
      });
    }
  };
  const handlePunchInOut = async (text, cb) => {
    setloading(true);
    const result = await postPunchInOut(text);
    if (result.status === 1) {
      attendance = result.data;
      getProfile();
      setloading(false);
      cb && cb();
      showToast({
        type: "success",
        message: result.message
          ? result.message
          : `${
              text === "punch-out" ? `Punched-Out` : `Punched-In`
            } Successfully `,
      });
    } else {
      setloading(false);
      showToast({
        type: "error",
        message: result.errorDescription
          ? result.errorDescription
          : "Something Went Wrong ",
      });
    }
  };

  const notificationModal = () => (
    <ModalRightPanel width={"400px"}>
      <NotificationContainer closeModal={closeModal} showToast={showToast} />
    </ModalRightPanel>
  );
  return (
    <div styleName="base">
      <div styleName="logo-container">
        <div styleName="logo-company-name ">NoBroker</div>
        <div styleName="logo">Customer360</div>
      </div>
      <div styleName="flex-between">
        <div styleName="navbar-container">
          {navList?.map(
            (val) =>
              val && (
                <div
                  key={val.label}
                  styleName={`nav-list${val.active ? "-active" : ""}`}
                  onClick={() => {
                    !val.active && history.push(val.path);
                    setflag(!flag);
                  }}>
                  {val.icon && (
                    <div
                      styleName={`${val.icon}${val.active ? "-active" : ""}`}
                    />
                  )}
                  {val.label}
                </div>
              )
          )}
        </div>
        <div styleName="flex-gap-10 ">
          <div
            styleName="notification-icon"
            onClick={() => {
              openModal(notificationModal());
            }}>
            <div styleName="notification-count">{notificationCount}</div>
          </div>
          <div styleName="attendance-container">
            {attendance?.punchedIn ? (
              <React.Fragment>
                <div>
                  {attendance?.punchInTime && (
                    <div styleName="punch-time">
                      Punched-In Time
                      <span style={{ marginLeft: "16px" }}>
                        {moment(attendance.punchInTime).format("hh:mm A")}
                      </span>
                    </div>
                  )}
                  {attendance?.punchOutTime && (
                    <div styleName="punch-time">
                      Punched-Out Time
                      <span style={{ marginLeft: "8px" }}>
                        {moment(attendance.punchOutTime).format("hh:mm A")}
                      </span>
                    </div>
                  )}
                </div>

                <Button
                  label="Punch Out "
                  //   color="#ff435e"
                  loading={loading}
                  onClick={() => handlePunchInOut("punch-out")}
                  fontSize={"13px"}
                  height="32px"
                  width="93px"
                  padding="0px"
                  fontWeight="600"
                  color="#7956bc"
                />
              </React.Fragment>
            ) : (
              <Button
                label="Punch In"
                loading={loading}
                onClick={() => handlePunchInOut("punch-in")}
                fontSize={"12px"}
                width="93px"
                padding="0px"
                color="#7956bc"
              />
            )}

            <ProfileContainer
              history={history}
              handlePunchInOut={handlePunchInOut}
              showToast={showToast}
              label={label}
              textColor="#7956bc"
            />
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}

export default memo(SocialCommonHeader);
