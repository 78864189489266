import React from "react";
import { ModalControl } from "./ModalRoot";
import "./ModalRightPanel.scss";

export default class ModalRightPanel extends React.Component {
  render() {
    return (
      <div styleName="base">
        <ModalControl>
          {(modal) => (
            <div
              styleName="panel"
              onClick={() => {
                if (this.props.onClose) {
                  this.props.onClose();
                }
                modal.closeModal();
              }}></div>
          )}
        </ModalControl>
        <div
          styleName="window"
          style={{
            width: this.props.width,
            height: this.props.height,
            ...this.props.styles,
          }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
